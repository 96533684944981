<script setup lang="ts">
import { computed, ref, watch, onMounted } from 'vue'
import { useVideoStore } from '@/areas/editor/store/useVideoStore'
import Tile from '@/components/Tile.vue'
import { useFontsStore } from '@/store/fonts'
import { IconLoader2, IconCirclePlus, IconEdit, IconTrash, IconDots } from '@tabler/icons-vue'
import { type BrandKitStyle, useUserBrandkitStore } from '@/store/user/userBrandkit'
import { oldTextStylesArray } from '@/components/Stickers/textLibrary'
import TextSticker from '@/components/Stickers/TextSticker.vue'
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover'
import type { TypedSticker } from "@/areas/editor/@type/Project";
import UpdateBrandKitStyleDialog from "@/areas/editor/pages/text/brand-kit/UpdateBrandKitStyleDialog.vue";

const props = withDefaults(defineProps<{
  style: BrandKitStyle;
  moment?: 'default' | 'intro' | 'outro';
  selected?: boolean;
  textContent: string;
  popoverEnabled?: boolean;
  aspect?: number
}>(), {
  moment: 'default',
  selected: false,
  popoverEnabled: false,
  aspect: 0.6
})

const emit = defineEmits<{
  (event: 'select', payload: TypedSticker<'brand-kit'>): void;
}>()

const videoStore = useVideoStore()
const containerSize = ref<DOMRect | null>(null)
const containerWidth = computed(() => containerSize.value?.width ?? 0)

function onResize(rect: DOMRect) {
  containerSize.value = rect
}

const ready = ref(false)
const scale = ref(1)

const animateScale = ref(true)
function scaleSticker() {

  setTimeout(() => {

    if (!elementRef.value || !containerSize.value) {
      return
    }

    const targetWidth = containerWidth.value * (2 / 3)
    if (elementRef.value.offsetWidth > targetWidth) {
      scale.value = targetWidth / elementRef.value.offsetWidth
    } else {
      scale.value = 1
    }

    setTimeout(() => {
      ready.value = true
      setTimeout(() => {
        animateScale.value = false
      }, 100)
    }, 100)
  }, 0)
}

watch(() => props.textContent, scaleSticker)
watch(containerSize, scaleSticker)

onMounted(async () => {

  scaleSticker()
  const fontsStore = useFontsStore()
  await fontsStore.loadFontByLabel(props.style.fontFamily)

  scaleSticker()
})

const elementRef = ref<HTMLElement | null>(null)

const styleMetaData = computed(() => {
  return oldTextStylesArray.find(t => t.variant === props.style.variant)
    // Fix for brand kit styles created on 20-08-2024
    ?? oldTextStylesArray.find(t => t.key === props.style.variant)
})

function select() {

  const clientRect = elementRef.value?.getBoundingClientRect()
  if (!clientRect) {
    return
  }

  const outputHeight = 1920
  const outputWidth = 1080

  const centerX = 0.5
  const centerY = 0.25

  const drawWidth = (1 / 3) * outputWidth
  const drawHeight = clientRect.height * (drawWidth / clientRect.width)
  const drawX = (centerX * outputWidth) - (0.5 * drawWidth)
  const drawY = (centerY * outputHeight) - (0.5 * drawHeight)

  const naturalWidth = clientRect.width / scale.value
  const naturalHeight = clientRect.height / scale.value

  const videoDurationInMs = videoStore._duration * 1000
  const startMs = props.moment === 'outro' ? videoDurationInMs - 5000 : 0
  const endMs = props.moment === 'intro' ? 5000 : videoDurationInMs

  const area = {
    x: drawX / outputWidth,
    y: drawY / outputHeight,
    width: drawWidth / outputWidth,
    height: drawHeight / outputHeight,
  }
  
  emit('select', {
    id: props.style.id,
    type: 'brand-kit',
    key: styleMetaData.value!.key,
    variant: props.style.variant,
    textContent: props.textContent ?? 'Enter\n text here',
    fontFamily: props.style.fontFamily,
    primaryColor: props.style.primaryColor,
    secondaryColor: props.style.secondaryColor,
    naturalWidth: naturalWidth,
    naturalHeight: naturalHeight,
    area: area,
    startMs: startMs,
    endMs: endMs,
    editing: true
  })
}

const isEditDialogOpen = ref(false)
const isPopoverOpen = ref(false)
function edit() {
  isPopoverOpen.value = false
  isEditDialogOpen.value = true
}

const userBrandKitStore = useUserBrandkitStore()
const isDestroying = ref(false)
async function destroy() {
  isDestroying.value = true
  isPopoverOpen.value = false
  await new Promise(resolve => setTimeout(resolve, 0))
  await userBrandKitStore.destroy(props.style.id)
  isDestroying.value = false
}
</script>

<template>
  <Tile :aspect="aspect" v-if="isDestroying" class="pointer-events-none border-rose-50 bg-rose-50 text-rose-600">
    <IconLoader2 :size="48" class="recycling animate-spin" />
    Deleting...
  </Tile>
  <Tile
    @click="select"
    @resize="onResize"
    :aspect="aspect"
    :selected="selected"
    class="active:scale-90 transition-[transform,_border-color,_background-color] group"
    feature="brandkit"
  >
    <div ref="elementRef"
      class="light"
      :style="{ transform: `scale(${ready ? scale : scale * 0.75})`}"
      :class="{ 'opacity-0 invisible': !ready, 'transition-[opacity,_transform]': animateScale }"
    >
      <component
        :is="styleMetaData?.component ?? TextSticker"
        :html-content="textContent"
        :font-family="style.fontFamily"
        :primary-color="style.primaryColor"
        :secondary-color="style.secondaryColor"
        :variant="styleMetaData?.variant ?? style.variant"
        @stickerLoaded="scaleSticker"
        class="pointer-events-none"
        :class="{ 'scale-75 opacity-0 invisible': !ready }"
      />
    </div>

    <div class="absolute right-0 bottom-0">
      <Popover v-model:open="isPopoverOpen" v-if="popoverEnabled">
        <PopoverTrigger @click.stop class="opacity-0 group-hover:opacity-100 transition-opacity px-2 py-0" :class="isPopoverOpen ? 'opacity-100' : ''">
          <IconDots class="text-brand-state-active-border active:text-brand-state-active-border hover:text-brand-state-hover-border transition-colors" />
        </PopoverTrigger>
        <PopoverContent class="flex flex-col p-0 w-auto overflow-hidden" @click.stop>
          <div
            class="flex items-center gap-2 whitespace-nowrap px-3 pr-5 py-3 hover:bg-company-primary hover:bg-opacity-10 font-light cursor-pointer transition-all"
            @click="select"
          >
            <IconCirclePlus :size="16" />
            <span class="mt-0.5">Add to video</span>
          </div>
          <div
            class="flex items-center gap-2 whitespace-nowrap px-3 pr-5 py-3 hover:bg-company-primary hover:bg-opacity-10 font-light cursor-pointer transition-all"
            @click="edit"
          >
            <IconEdit :size="16" />
            <span class="mt-0.5">Edit style</span>
          </div>
          <div
            class="flex items-center gap-2 whitespace-nowrap px-3 pr-5 py-3 text-rose-600 hover:bg-rose-600 hover:text-white font-light cursor-pointer transition-all"
            @click.stop="destroy"
          >
            <IconTrash :size="16" />
            <span class="mt-0.5">Delete from library</span>
          </div>
        </PopoverContent>
      </Popover>
    </div>

    <UpdateBrandKitStyleDialog :style="style" v-model:open="isEditDialogOpen" />
  </Tile>
</template>

<style lang="scss" scoped>
</style>
