<script setup lang="ts">
import { loginProviders, type LoginProvider } from '@/enums/loginProviders'
import { useUserInfoStore } from '@/store/user/userInfo'
import { ref, computed, watch } from 'vue'
import TwitchIcon from '@/components/Icons/SocialMedia/TwitchIcon.vue'
import GoogleIcon from '@/components/Icons/GoogleIcon.vue'
import Spinner from '@/components/Icons/Spinner.vue'
import { signInWith } from '@/authentication/supabase'

defineProps<{ title?: string }>();
const emit = defineEmits<{ (event: 'loggedIn'): void }>()

const userInfoStore = useUserInfoStore();
const isAuthenticating = ref(false);

const isAuthenticated = computed(() => userInfoStore.isAuthenticated);

watch(isAuthenticated, (value) => {
  if (value) {
    emit('loggedIn');
  }
}, { immediate: true });

const loginWith = async (provider: LoginProvider) => {

  isAuthenticating.value = true;

  try {
    await signInWith(provider);
  } catch (error) {
    console.error(error);
  }

  isAuthenticating.value = false;
};

const lastUsedLoginProvider = localStorage.getItem('lastUsedLoginProvider');
</script>

<template>
  <div class="md:flex flex-col items-center justify-center hidden">
    <slot name="title">
      <h3 class="leading-none tracking-tight text-3xl lg:text-5xl 2xl:text-6xl font-bold text-center !text-white absolute top-1/4 -translate-y-full px-8">
        Convert your clips to viral<br> TikToks, Reels or Shorts!
      </h3>
    </slot>
    <img draggable="false" src="/images/rays.png" alt="rays" class="absolute w-1/2 h-full" />
    <img draggable="false" src="/images/sloppy.png" alt="sloppy" class="absolute max-w-[500px] 2xl:max-w-[800px] max-h-[65%] aspect-[358/300] bottom-0 -translate-x-4" />
  </div>
  <div class="flex flex-col items-center justify-center layer-0 bg-white dark:bg-surface-bg w-full h-full gap-4 p-4">
    <header class="flex flex-col gap-2">
      <h4 class="flex items-center gap-4 absolute top-8 left-0 md:left-1/2 ml-8">
        <img alt="StreamLadder branding" class="h-10 w-10" height="192" src="/images/logo.png" width="192" />
        <span class="text-xl font-semibold">StreamLadder</span>
      </h4>
      <p class="text-3xl font-bold text-center">{{ title ?? 'Welcome to StreamLadder!' }}</p>
      <slot name="description" v-if="!isAuthenticating">
        <p class="font-light text-center text-sm">
          Log in or sign up with your email or Twitch - <br><span class="text-green-600">it's free!</span>
        </p>
      </slot>
    </header>
    <div class="mx-auto flex max-w-sm flex-col gap-4 items-center justify-center">
      <div class="h-32 w-32 grid place-items-center" v-if="isAuthenticating">
        <Spinner class="animate-spin h-16 w-16 fill-primary text-surface-panel-border" />
      </div>
      <template v-else>
        <button
          class="relative flex flex-col items-center justify-center gap-0.5 text-black bg-white rounded px-6 py-4 shadow-[0_6px_20px_0_#0002] outline outline-2 outline-offset-2 outline-transparent transition-all hover:shadow-[0_6px_20px_0_#0004] focus:shadow-[0_6px_20px_0_#0003] focus:outline-blue-600 active:scale-90"
          @click="loginWith(loginProviders.GOOGLE)"
        >
          <span class="flex gap-2">
            <GoogleIcon class="h-6 w-6" />
            Login with Google
          </span>
          <span class="text-xs font-light absolute -right-2 -top-2 px-1.5 py-0.5 rounded text-white bg-green-500 dark:bg-green-700 shadow" v-if="lastUsedLoginProvider === loginProviders.GOOGLE">
            Last used
          </span>
        </button>
        <button
          class="relative flex flex-col items-center justify-center gap-0.5 rounded bg-[#9147FF] px-6 py-4 text-white shadow-[0_6px_20px_0_#9147FF5a] outline outline-2 outline-offset-2 outline-transparent transition-all hover:shadow-[0_6px_20px_0_#9147FFaf] focus:shadow-[0_6px_20px_0_#9147FFaf] focus:outline-blue-600 active:scale-90"
          @click="loginWith(loginProviders.TWITCH)"
        >
          <span class="flex gap-2">
            <TwitchIcon class="h-6 w-6 text-white" />
            Login with Twitch
          </span>
          <span class="text-xs font-light absolute -right-2 -top-2 px-1.5 py-0.5 rounded text-white bg-green-500 dark:bg-green-700 shadow" v-if="lastUsedLoginProvider === loginProviders.TWITCH">
            Last used
          </span>
        </button>
      </template>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>