export function dedupeAsync<T>(fn: () => Promise<T>): () => Promise<T> {

  let promise: Promise<T> | null = null;

  return async function() {

    if (promise) {
      return promise;
    }

    promise = new Promise<T>((resolve, reject) => {
      fn()
        .then((response: T) => {
          resolve(response);
        })
        .catch((error: Error) => {
          console.error(error);
          reject(error);
        })
        .finally(() => {
          promise = null;
        });
    });

    return promise;
  };
}
