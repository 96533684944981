<script setup lang="ts">
import { Button } from '@/components/ui/button'
import IconSaxDocumentUpload from '@/components/Icons/iconsax/IconSaxDocumentUpload.vue'
import { useUserInfoStore } from '@/store/user/userInfo'
import { Input } from '@/components/ui/input'
import DynamicPlanButtonWithTooltip from '@/components/Account/Upgrade/DynamicPlanButtonWithTooltip.vue'
import { useEffectsStore } from '@/areas/editor/store/useEffectsStore'
import type { Effect } from '@/areas/editor/@type/Project'
import { v4 as uuid } from 'uuid'
import { useVideoStore } from '@/areas/editor/store/useVideoStore'
import SoundEffect from '@/areas/editor/pages/sounds/SoundEffect.vue'
import { capitalize, computed, onMounted, ref, watch } from 'vue'
import { FocusTypes, useEditorFocusStore } from '@/store/editor/editorFocus'
import { useHistoryStore } from '@/areas/editor/store/useHistoryStore'
import { sounds as importedSounds } from '@/areas/editor/pages/sounds/sounds'
import IconSaxArrowLeft from '@/components/Icons/iconsax/IconSaxArrowLeft.vue'
import { useEditorStep } from '@/areas/editor/hooks/useEditorStep'
import RadioToggleButton from '@/components-v2/data-input/RadioToggleButton.vue'
import { orderBy, uniq } from 'lodash-es'
import IconSaxArrowRight2 from '@/components/Icons/iconsax/IconSaxArrowRight2.vue'
import { tiers } from '@/enums/tiers'
import GoldPlanButton from '@/components/Account/Upgrade/GoldPlanButton.vue'
import logging from '@/logging'

defineProps<{
  hideBackButton?: boolean
}>();

type SoundEffectElement = {
  name: string;
  url: string;
  tags: string[];
};

const sounds = ref<SoundEffectElement[]>([]);

const foundSounds = ref<Effect<'sound'>[]>([]);

onMounted(async () => {
  sounds.value = importedSounds.map((sound) => {
    return {
      name: sound.title,
      url: sound.url,
      tags: sound.tags
    };
  });
});

const userInfoStore = useUserInfoStore();
const effectsStore = useEffectsStore();
const videoStore = useVideoStore();

const editorFocusStore = useEditorFocusStore();
const historyStore = useHistoryStore();

const { currentSection } = useEditorStep();

const uploadSoundEffect = (e) => {
  console.log('upload sound effect', e);
};

const addSoundEffect = (sound: Effect<'sound'>, durationSeconds: number) => {

  const soundDurationMs = durationSeconds * 1000;

  const startMs = sound.tags.includes('Full songs') ? 0 : videoStore.currentTimeMs;
  const endMs = Math.min(startMs + soundDurationMs, videoStore.durationMs);

  const id = uuid();

  effectsStore.createById<Effect<'sound'>>(id, {
    type: 'sound',
    name: sound.name,
    url: sound.url,
    tags: sound.tags,
    startMs: startMs,
    endMs: endMs,
    maxDurationMs: soundDurationMs,
    volume: 0.4,
  });

  logging.trackEvent('Editor Sound Effect Added', {
    name: sound.name,
    url: sound.url,
    tags: sound.tags,
    soundDurationMs: soundDurationMs,
  });

  editorFocusStore.setFocus(FocusTypes.SOUND, id)

  historyStore.push()
};

const filteredSounds = computed(() => {
  if (selectedTag.value === 'all') {
    return sounds.value as Effect<'sound'>[];
  } else {
    return sounds.value.filter(sound => sound.tags.includes(selectedTag.value)) as Effect<'sound'>[];
  }
});

const audioElements = ref<HTMLAudioElement[]>([]);
const stopOtherSounds = (newElement: HTMLAudioElement) => {

  audioElements.value = [
    ...audioElements.value,
    newElement,
  ];

  newElement.addEventListener('playing', () => {
    for (const audio of audioElements.value) {
      if (audio !== newElement) {
        audio.currentTime = 0;
        audio.pause();
      }
    }
  });
};

const tags = computed(() => {
  const allTags = orderBy(uniq(sounds.value.flatMap((sound) => sound.tags)));
  return [
    'all',
    ...allTags,
  ];
});

const selectedTag = ref('all');

const soundName = ref('');

const searchSounds = () => {
  const filteredName = soundName.value.toLowerCase().trim();
  if (filteredName.length > 0) {
    selectedTag.value = 'all';
    foundSounds.value = importedSounds
      .map((sound) => ({
        name: sound.title,
        url: sound.url,
        tags: sound.tags
      }))
      .filter((sound) => sound.name.toLowerCase().trim().includes(filteredName) || sound.tags.some((tag) => tag.toLowerCase().includes(filteredName))) as Effect<'sound'>[];
  }
};

watch(() => soundName.value, () => {
  searchSounds();
});
</script>

<template>
  <div class="flex flex-col gap-4 p-4 pb-0 2xl:p-8 h-full sm:h-screen">

    <header>
      <div class="flex gap-1 items-center">
        <h2 class="text-xl font-semibold leading-snug">Audio</h2>
        <span class="rounded-full font-semibold text-xs uppercase px-2 py-1 bg-green-700 text-background -mt-0.5 cursor-default">
          BETA
        </span>
      </div>
      <p v-if="userInfoStore.tier === tiers.GOLD" class="font-light hidden md:block">Add multiple timed sounds or music to your clip</p>
      <p v-else class="font-light hidden md:flex gap-2">
        Add multiple timed sounds or music to your clip
        <GoldPlanButton v-if="userInfoStore.tier !== tiers.GOLD" class="pointer-events-none -mt-0.5" :can-click="false" :small="true" />
      </p>
    </header>

    <div class="flex flex-col gap-2">
      <div class="w-full min-w-0 overflow-x-auto overflow-y-hidden">
        <div class="flex flex-row gap-1 m-2">
          <RadioToggleButton
            v-for="tag in tags" :key="tag" :value="tag" v-model="selectedTag"
            size="sm" class="flex-col relative font-light data-[state=active]:font-semibold shrink-0"
          >
            <span class="absolute inset-auto">{{ capitalize(tag) }}</span>
            <span class="font-semibold invisible">{{ capitalize(tag) }}</span>
          </RadioToggleButton>
        </div>
      </div>
    </div>

    <form
      class="layer-1 flex items-stretch outline outline-2 outline-transparent transition-all rounded-lg focus-within:outline-offset-2 focus-within:outline-cyan-400 text-sm"
      @submit.prevent.stop="searchSounds"
    >
      <input
        ref="input"
        v-model="soundName"
        class="min-w-0 flex-auto rounded-l-lg border border-r-0 border-zinc-900/20 p-3 text-left font-light text-zinc-900 outline-none dark:border-zinc-200/20 bg-white dark:bg-surface-panel-50"
        placeholder="Search for sound effects"
        type="text"
      />
      <button
        :disabled="!soundName?.trim()"
        class="cursor-pointer group flex flex-nowrap items-center justify-center gap-1 whitespace-nowrap rounded-l-none rounded-r-lg bg-purple-700 px-2 py-2 text-white outline-none transition-colors hover:bg-purple-600 focus:bg-purple-600"
        type="submit"
      >
        <span class="hidden md:inline">Search</span>
        <IconSaxArrowRight2 class="h-4 w-4 transition-transform group-hover:translate-x-1 group-focus:translate-x-1" />
      </button>
    </form>

    <div class="relative overflow-y-auto">
      <Button v-if=false as="label" class="mb-4 relative gap-2 bg-transparent hover:bg-transparent w-full h-32 font-light text-current active:scale-90 transition-[transform,_border-color,_background-color] border border-dashed border-brand-state-hover-border rounded-lg flex flex-col justify-center items-center cursor-pointer hover:scale-[101%] group">
        <DynamicPlanButtonWithTooltip feature="stickers" class="absolute right-2 top-2" />
        <IconSaxDocumentUpload class="w-5 h-5 text-brand-state-hover-border dark:text-white group-hover:scale-110 group-hover:-translate-y-0.5 transition-transform" />
        <div class="flex flex-col items-center justify-center">
          <span>Upload a Sound Effect</span>
          <span class="text-xs text-gray-500">Max 5 MB files are allowed</span>
          <span class="text-xs text-gray-500">Supported formats: .mp3, .mp4, .wav</span>
        </div>
        <div class="absolute w-full h-full">
          <Input
            :disabled="true"
            class="hidden" ref="input" id="custom-sticker-input"
            type="file" name="custom-sound" accept="audio/mp3, audio/mp4, audio/wav"
            @input="uploadSoundEffect"
          />
        </div>
      </Button>

      <div class="flex flex-col gap-2">
        <SoundEffect
          v-for="sound in (soundName.length > 0 ? foundSounds : filteredSounds)"
          @add="(durationSeconds) => addSoundEffect(sound, durationSeconds)"
          @play="stopOtherSounds"
          :key="sound.id"
          :sound="sound"
        />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>
