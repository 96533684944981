/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * StreamLadder.Api
 * OpenAPI spec version: 1.0
 */
import { useMutation, useQuery } from '@tanstack/vue-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationReturnType,
  UseQueryOptions,
  UseQueryReturnType,
} from '@tanstack/vue-query'
import { computed, unref } from 'vue'
import type { MaybeRef } from 'vue'
import type {
  ApiError,
  CreateRenderDto,
  GetApiRendersParams,
  Int32ApiResponse,
  PutApiRendersIdPremiumParams,
  RenderDetailsDtoApiResponse,
  RenderDtoApiResponse,
  RenderDtoPageApiResponse,
  RenderWebhookPayload,
} from '.././model'
import { streamLadderAxiosInstance } from '../../../services/axios'

type AwaitedInput<T> = PromiseLike<T> | T

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1]

export const postApiRenders = (
  createRenderDto: MaybeRef<CreateRenderDto>,
  options?: SecondParameter<typeof streamLadderAxiosInstance>
) => {
  createRenderDto = unref(createRenderDto)

  return streamLadderAxiosInstance<RenderDtoApiResponse>(
    { url: `/api/renders`, method: 'POST', headers: { 'Content-Type': 'application/json' }, data: createRenderDto },
    options
  )
}

export const getPostApiRendersMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof postApiRenders>>, TError, { data: CreateRenderDto }, TContext>
  request?: SecondParameter<typeof streamLadderAxiosInstance>
}): UseMutationOptions<Awaited<ReturnType<typeof postApiRenders>>, TError, { data: CreateRenderDto }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiRenders>>, { data: CreateRenderDto }> = (
    props
  ) => {
    const { data } = props ?? {}

    return postApiRenders(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type PostApiRendersMutationResult = NonNullable<Awaited<ReturnType<typeof postApiRenders>>>
export type PostApiRendersMutationBody = CreateRenderDto
export type PostApiRendersMutationError = ApiError

export const usePostApiRenders = <TError = ApiError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof postApiRenders>>, TError, { data: CreateRenderDto }, TContext>
  request?: SecondParameter<typeof streamLadderAxiosInstance>
}): UseMutationReturnType<Awaited<ReturnType<typeof postApiRenders>>, TError, { data: CreateRenderDto }, TContext> => {
  const mutationOptions = getPostApiRendersMutationOptions(options)

  return useMutation(mutationOptions)
}
export const getApiRenders = (
  params?: MaybeRef<GetApiRendersParams>,
  options?: SecondParameter<typeof streamLadderAxiosInstance>,
  signal?: AbortSignal
) => {
  params = unref(params)

  return streamLadderAxiosInstance<RenderDtoPageApiResponse>(
    { url: `/api/renders`, method: 'GET', params: unref(params), signal },
    options
  )
}

export const getGetApiRendersQueryKey = (params?: MaybeRef<GetApiRendersParams>) => {
  return ['api', 'renders', ...(params ? [params] : [])] as const
}

export const getGetApiRendersQueryOptions = <TData = Awaited<ReturnType<typeof getApiRenders>>, TError = ApiError>(
  params?: MaybeRef<GetApiRendersParams>,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiRenders>>, TError, TData>>
    request?: SecondParameter<typeof streamLadderAxiosInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = getGetApiRendersQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiRenders>>> = ({ signal }) =>
    getApiRenders(params, requestOptions, signal)

  return { queryKey, queryFn, staleTime: 60000, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiRenders>>,
    TError,
    TData
  >
}

export type GetApiRendersQueryResult = NonNullable<Awaited<ReturnType<typeof getApiRenders>>>
export type GetApiRendersQueryError = ApiError

export const useGetApiRenders = <TData = Awaited<ReturnType<typeof getApiRenders>>, TError = ApiError>(
  params?: MaybeRef<GetApiRendersParams>,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiRenders>>, TError, TData>>
    request?: SecondParameter<typeof streamLadderAxiosInstance>
  }
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiRendersQueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey }

  query.queryKey = unref(queryOptions).queryKey as QueryKey

  return query
}

export const getApiRendersPremiumRenderCount = (
  options?: SecondParameter<typeof streamLadderAxiosInstance>,
  signal?: AbortSignal
) => {
  return streamLadderAxiosInstance<Int32ApiResponse>(
    { url: `/api/renders/premiumRenderCount`, method: 'GET', signal },
    options
  )
}

export const getGetApiRendersPremiumRenderCountQueryKey = () => {
  return ['api', 'renders', 'premiumRenderCount'] as const
}

export const getGetApiRendersPremiumRenderCountQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiRendersPremiumRenderCount>>,
  TError = ApiError
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiRendersPremiumRenderCount>>, TError, TData>>
  request?: SecondParameter<typeof streamLadderAxiosInstance>
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = getGetApiRendersPremiumRenderCountQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiRendersPremiumRenderCount>>> = ({ signal }) =>
    getApiRendersPremiumRenderCount(requestOptions, signal)

  return { queryKey, queryFn, staleTime: 60000, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getApiRendersPremiumRenderCount>>,
    TError,
    TData
  >
}

export type GetApiRendersPremiumRenderCountQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiRendersPremiumRenderCount>>
>
export type GetApiRendersPremiumRenderCountQueryError = ApiError

export const useGetApiRendersPremiumRenderCount = <
  TData = Awaited<ReturnType<typeof getApiRendersPremiumRenderCount>>,
  TError = ApiError
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiRendersPremiumRenderCount>>, TError, TData>>
  request?: SecondParameter<typeof streamLadderAxiosInstance>
}): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiRendersPremiumRenderCountQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey }

  query.queryKey = unref(queryOptions).queryKey as QueryKey

  return query
}

export const getApiRendersId = (
  id: MaybeRef<string>,
  options?: SecondParameter<typeof streamLadderAxiosInstance>,
  signal?: AbortSignal
) => {
  id = unref(id)

  return streamLadderAxiosInstance<RenderDtoApiResponse>({ url: `/api/renders/${id}`, method: 'GET', signal }, options)
}

export const getGetApiRendersIdQueryKey = (id: MaybeRef<string>) => {
  return ['api', 'renders', id] as const
}

export const getGetApiRendersIdQueryOptions = <TData = Awaited<ReturnType<typeof getApiRendersId>>, TError = ApiError>(
  id: MaybeRef<string>,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiRendersId>>, TError, TData>>
    request?: SecondParameter<typeof streamLadderAxiosInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = getGetApiRendersIdQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiRendersId>>> = ({ signal }) =>
    getApiRendersId(id, requestOptions, signal)

  return {
    queryKey,
    queryFn,
    enabled: computed(() => !!unref(id)),
    staleTime: 60000,
    ...queryOptions,
  } as UseQueryOptions<Awaited<ReturnType<typeof getApiRendersId>>, TError, TData>
}

export type GetApiRendersIdQueryResult = NonNullable<Awaited<ReturnType<typeof getApiRendersId>>>
export type GetApiRendersIdQueryError = ApiError

export const useGetApiRendersId = <TData = Awaited<ReturnType<typeof getApiRendersId>>, TError = ApiError>(
  id: MaybeRef<string>,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiRendersId>>, TError, TData>>
    request?: SecondParameter<typeof streamLadderAxiosInstance>
  }
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiRendersIdQueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey }

  query.queryKey = unref(queryOptions).queryKey as QueryKey

  return query
}

export const deleteApiRendersId = (
  id: MaybeRef<string>,
  options?: SecondParameter<typeof streamLadderAxiosInstance>
) => {
  id = unref(id)

  return streamLadderAxiosInstance<void>({ url: `/api/renders/${id}`, method: 'DELETE' }, options)
}

export const getDeleteApiRendersIdMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteApiRendersId>>, TError, { id: string }, TContext>
  request?: SecondParameter<typeof streamLadderAxiosInstance>
}): UseMutationOptions<Awaited<ReturnType<typeof deleteApiRendersId>>, TError, { id: string }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteApiRendersId>>, { id: string }> = (props) => {
    const { id } = props ?? {}

    return deleteApiRendersId(id, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type DeleteApiRendersIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteApiRendersId>>>

export type DeleteApiRendersIdMutationError = ApiError

export const useDeleteApiRendersId = <TError = ApiError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteApiRendersId>>, TError, { id: string }, TContext>
  request?: SecondParameter<typeof streamLadderAxiosInstance>
}): UseMutationReturnType<Awaited<ReturnType<typeof deleteApiRendersId>>, TError, { id: string }, TContext> => {
  const mutationOptions = getDeleteApiRendersIdMutationOptions(options)

  return useMutation(mutationOptions)
}
export const postApiRendersIdRetry = (
  id: MaybeRef<string>,
  options?: SecondParameter<typeof streamLadderAxiosInstance>
) => {
  id = unref(id)

  return streamLadderAxiosInstance<RenderDtoApiResponse>({ url: `/api/renders/${id}/retry`, method: 'POST' }, options)
}

export const getPostApiRendersIdRetryMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof postApiRendersIdRetry>>, TError, { id: string }, TContext>
  request?: SecondParameter<typeof streamLadderAxiosInstance>
}): UseMutationOptions<Awaited<ReturnType<typeof postApiRendersIdRetry>>, TError, { id: string }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiRendersIdRetry>>, { id: string }> = (props) => {
    const { id } = props ?? {}

    return postApiRendersIdRetry(id, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type PostApiRendersIdRetryMutationResult = NonNullable<Awaited<ReturnType<typeof postApiRendersIdRetry>>>

export type PostApiRendersIdRetryMutationError = ApiError

export const usePostApiRendersIdRetry = <TError = ApiError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof postApiRendersIdRetry>>, TError, { id: string }, TContext>
  request?: SecondParameter<typeof streamLadderAxiosInstance>
}): UseMutationReturnType<Awaited<ReturnType<typeof postApiRendersIdRetry>>, TError, { id: string }, TContext> => {
  const mutationOptions = getPostApiRendersIdRetryMutationOptions(options)

  return useMutation(mutationOptions)
}
export const getApiRendersIdDetails = (
  id: MaybeRef<string>,
  options?: SecondParameter<typeof streamLadderAxiosInstance>,
  signal?: AbortSignal
) => {
  id = unref(id)

  return streamLadderAxiosInstance<RenderDetailsDtoApiResponse>(
    { url: `/api/renders/${id}/details`, method: 'GET', signal },
    options
  )
}

export const getGetApiRendersIdDetailsQueryKey = (id: MaybeRef<string>) => {
  return ['api', 'renders', id, 'details'] as const
}

export const getGetApiRendersIdDetailsQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiRendersIdDetails>>,
  TError = ApiError
>(
  id: MaybeRef<string>,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiRendersIdDetails>>, TError, TData>>
    request?: SecondParameter<typeof streamLadderAxiosInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = getGetApiRendersIdDetailsQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiRendersIdDetails>>> = ({ signal }) =>
    getApiRendersIdDetails(id, requestOptions, signal)

  return {
    queryKey,
    queryFn,
    enabled: computed(() => !!unref(id)),
    staleTime: 60000,
    ...queryOptions,
  } as UseQueryOptions<Awaited<ReturnType<typeof getApiRendersIdDetails>>, TError, TData>
}

export type GetApiRendersIdDetailsQueryResult = NonNullable<Awaited<ReturnType<typeof getApiRendersIdDetails>>>
export type GetApiRendersIdDetailsQueryError = ApiError

export const useGetApiRendersIdDetails = <
  TData = Awaited<ReturnType<typeof getApiRendersIdDetails>>,
  TError = ApiError
>(
  id: MaybeRef<string>,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiRendersIdDetails>>, TError, TData>>
    request?: SecondParameter<typeof streamLadderAxiosInstance>
  }
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiRendersIdDetailsQueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey }

  query.queryKey = unref(queryOptions).queryKey as QueryKey

  return query
}

export const putApiRendersReadall = (options?: SecondParameter<typeof streamLadderAxiosInstance>) => {
  return streamLadderAxiosInstance<void>({ url: `/api/renders/readall`, method: 'PUT' }, options)
}

export const getPutApiRendersReadallMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof putApiRendersReadall>>, TError, void, TContext>
  request?: SecondParameter<typeof streamLadderAxiosInstance>
}): UseMutationOptions<Awaited<ReturnType<typeof putApiRendersReadall>>, TError, void, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiRendersReadall>>, void> = () => {
    return putApiRendersReadall(requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type PutApiRendersReadallMutationResult = NonNullable<Awaited<ReturnType<typeof putApiRendersReadall>>>

export type PutApiRendersReadallMutationError = ApiError

export const usePutApiRendersReadall = <TError = ApiError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof putApiRendersReadall>>, TError, void, TContext>
  request?: SecondParameter<typeof streamLadderAxiosInstance>
}): UseMutationReturnType<Awaited<ReturnType<typeof putApiRendersReadall>>, TError, void, TContext> => {
  const mutationOptions = getPutApiRendersReadallMutationOptions(options)

  return useMutation(mutationOptions)
}
export const putApiRendersIdRead = (
  id: MaybeRef<string>,
  options?: SecondParameter<typeof streamLadderAxiosInstance>
) => {
  id = unref(id)

  return streamLadderAxiosInstance<void>({ url: `/api/renders/${id}/read`, method: 'PUT' }, options)
}

export const getPutApiRendersIdReadMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof putApiRendersIdRead>>, TError, { id: string }, TContext>
  request?: SecondParameter<typeof streamLadderAxiosInstance>
}): UseMutationOptions<Awaited<ReturnType<typeof putApiRendersIdRead>>, TError, { id: string }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiRendersIdRead>>, { id: string }> = (props) => {
    const { id } = props ?? {}

    return putApiRendersIdRead(id, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type PutApiRendersIdReadMutationResult = NonNullable<Awaited<ReturnType<typeof putApiRendersIdRead>>>

export type PutApiRendersIdReadMutationError = ApiError

export const usePutApiRendersIdRead = <TError = ApiError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof putApiRendersIdRead>>, TError, { id: string }, TContext>
  request?: SecondParameter<typeof streamLadderAxiosInstance>
}): UseMutationReturnType<Awaited<ReturnType<typeof putApiRendersIdRead>>, TError, { id: string }, TContext> => {
  const mutationOptions = getPutApiRendersIdReadMutationOptions(options)

  return useMutation(mutationOptions)
}
export const putApiRendersIdPremium = (
  id: MaybeRef<string>,
  params?: MaybeRef<PutApiRendersIdPremiumParams>,
  options?: SecondParameter<typeof streamLadderAxiosInstance>
) => {
  id = unref(id)
  params = unref(params)

  return streamLadderAxiosInstance<void>(
    { url: `/api/renders/${id}/premium`, method: 'PUT', params: unref(params) },
    options
  )
}

export const getPutApiRendersIdPremiumMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putApiRendersIdPremium>>,
    TError,
    { id: string; params?: PutApiRendersIdPremiumParams },
    TContext
  >
  request?: SecondParameter<typeof streamLadderAxiosInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof putApiRendersIdPremium>>,
  TError,
  { id: string; params?: PutApiRendersIdPremiumParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putApiRendersIdPremium>>,
    { id: string; params?: PutApiRendersIdPremiumParams }
  > = (props) => {
    const { id, params } = props ?? {}

    return putApiRendersIdPremium(id, params, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type PutApiRendersIdPremiumMutationResult = NonNullable<Awaited<ReturnType<typeof putApiRendersIdPremium>>>

export type PutApiRendersIdPremiumMutationError = ApiError

export const usePutApiRendersIdPremium = <TError = ApiError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putApiRendersIdPremium>>,
    TError,
    { id: string; params?: PutApiRendersIdPremiumParams },
    TContext
  >
  request?: SecondParameter<typeof streamLadderAxiosInstance>
}): UseMutationReturnType<
  Awaited<ReturnType<typeof putApiRendersIdPremium>>,
  TError,
  { id: string; params?: PutApiRendersIdPremiumParams },
  TContext
> => {
  const mutationOptions = getPutApiRendersIdPremiumMutationOptions(options)

  return useMutation(mutationOptions)
}
export const postWebhooksRenders = (
  renderWebhookPayload: MaybeRef<RenderWebhookPayload>,
  options?: SecondParameter<typeof streamLadderAxiosInstance>
) => {
  renderWebhookPayload = unref(renderWebhookPayload)

  return streamLadderAxiosInstance<void>(
    {
      url: `/webhooks/renders`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: renderWebhookPayload,
    },
    options
  )
}

export const getPostWebhooksRendersMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postWebhooksRenders>>,
    TError,
    { data: RenderWebhookPayload },
    TContext
  >
  request?: SecondParameter<typeof streamLadderAxiosInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof postWebhooksRenders>>,
  TError,
  { data: RenderWebhookPayload },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postWebhooksRenders>>,
    { data: RenderWebhookPayload }
  > = (props) => {
    const { data } = props ?? {}

    return postWebhooksRenders(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type PostWebhooksRendersMutationResult = NonNullable<Awaited<ReturnType<typeof postWebhooksRenders>>>
export type PostWebhooksRendersMutationBody = RenderWebhookPayload
export type PostWebhooksRendersMutationError = ApiError

export const usePostWebhooksRenders = <TError = ApiError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postWebhooksRenders>>,
    TError,
    { data: RenderWebhookPayload },
    TContext
  >
  request?: SecondParameter<typeof streamLadderAxiosInstance>
}): UseMutationReturnType<
  Awaited<ReturnType<typeof postWebhooksRenders>>,
  TError,
  { data: RenderWebhookPayload },
  TContext
> => {
  const mutationOptions = getPostWebhooksRendersMutationOptions(options)

  return useMutation(mutationOptions)
}
