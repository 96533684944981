import type { Project, Captions, TypedSticker } from '@/areas/editor/@type/Project'
import { useEditorClipInfoStore } from '@/store/editor/editorClipInfo'
import { useSegmentsStore } from '@/areas/editor/store/useSegmentsStore'
import { useLayoutsStore } from '@/areas/editor/store/useLayoutsStore'
import { uniqBy } from 'lodash-es'
import { useCropsStore } from '@/areas/editor/store/useCropsStore'
import { useEditorFocusStore, FocusTypes } from '@/store/editor/editorFocus'
import { useStickersStore } from '@/areas/editor/store/useStickersStore'
import { useEditorCaptionsStore } from '@/store/editor/editorCaptions'
import { useGenerateCaptions } from '@/areas/editor/pages/captions/useGenerateCaptions'
import { useHistoryStore } from '@/areas/editor/store/useHistoryStore'
import { useEffectsStore } from "@/areas/editor/store/useEffectsStore";
import { startupFromLocalFileByClipId } from '@/areas/editor/startup/startupFromLocalFile'
import { startupFromTwitchClipByClipId } from '@/areas/editor/startup/startupFromTwitchClip'
import { startupFromYoutubeClipByClipId } from '@/areas/editor/startup/startupFromYoutubeClip'
import { startupFromKickClipByClipId } from '@/areas/editor/startup/startUpFromKickClip'
import type { StartupConfig } from '@/areas/editor/startup/StartupConfig'
import { computed } from 'vue'
import { startupFromTwitchVodByClipId } from '@/areas/editor/startup/startupFromTwitchVod'
import type { StoredCaption, CaptionsDocument } from '@/components/Captions/captionTypes'
import { v4 as uuid } from 'uuid'
import { handleStartupError } from '@/areas/editor/startup/handleStartupError'
import { startupFromKickCxClipByClipId } from '@/areas/editor/startup/startUpFromKickCxClip'

export async function startupFromSnapshot(snapshot: Project) {

  const editorClipInfoStore = useEditorClipInfoStore()
  const abortSignal = computed(() => new AbortController().signal)

  editorClipInfoStore.isLoadingClip = true

  if (!snapshot.id) {
    editorClipInfoStore.isLoadingClip = false
    return
  }

  editorClipInfoStore.loadingState = {
    state: 'loading',
    description: 'Resuming project...',
  }

  editorClipInfoStore.title = snapshot.title ?? 'New Clip'
  editorClipInfoStore.source = snapshot.source ?? 'unknown'
  editorClipInfoStore.languageCode = snapshot.language ?? 'en_us'

  const errors = [];

  try {
    const isOverrideUrl = snapshot.mp4Url?.startsWith('https://imports-sl-wnam.') || snapshot.mp4Url?.includes('skipRepair=true')
    const mp4UrlOverride = isOverrideUrl ? snapshot.mp4Url : undefined

    const result =  await startupBySource(snapshot.source, snapshot.id, mp4UrlOverride, { signal: abortSignal })
    if (result.error) {
      console.error(result.error)
      return;
    }

    if (snapshot.segments.length > 0) {

      const segmentsStore = useSegmentsStore()
      segmentsStore.$reset()
      for (const segment of snapshot.segments) {
        segmentsStore.createById(segment.id, segment)
      }

      const layoutsStore = useLayoutsStore()
      layoutsStore.$reset()
      for (const layout of uniqBy(snapshot.layouts, 'id')) {
        layoutsStore.createById(layout.id, layout)
      }

      const cropsStore = useCropsStore()
      cropsStore.$reset()
      for (const crop of snapshot.crops) {
        cropsStore.createById(crop.id, crop)
      }
    }

    const editorFocusStore = useEditorFocusStore()
    if (snapshot.crops[0]) {
      editorFocusStore.setFocus(FocusTypes.CROP, snapshot.crops[0].id)
    }

    const stickersStore = useStickersStore();
    stickersStore.$reset();
    for (const sticker of snapshot.stickers) {
      const upgradedSticker = purgeBlobUrlFrom(sticker);
        stickersStore.createById(upgradedSticker.id, upgradedSticker);
    }
  } catch (e) {
    errors.push(e)
  }

  const effectsStore = useEffectsStore()
  try {
    effectsStore.$reset()
    for (const effect of snapshot.effects) {
      try {
        effectsStore.createById(effect.id, effect)
      } catch (e) {
        errors.push(e)
      }
    }
  } catch (e) {
    errors.push(e)
  }

  try {
    const editorCaptionsStore = useEditorCaptionsStore()
    applyCaptionsSettings(snapshot.captions)

    if (snapshot.captions.generated && !editorCaptionsStore.captionsGenerated) {
      await applyCaptionsDocument(snapshot.id)
      abortSignal.value.throwIfAborted()
    }

    editorClipInfoStore.isLoadingClip = false
    const historyStore = useHistoryStore()
    await historyStore.push()

    editorClipInfoStore.loadingState = null
  } catch (e) {
    console.error(e)
    handleStartupError("Your project can't be resumed 🙊")
    return;
  }
}

async function applyCaptionsDocument(captionsId: string) {

  const editorCaptionsStore = useEditorCaptionsStore()
  const editorClipInfoStore = useEditorClipInfoStore()

  editorClipInfoStore.loadingState = {
    state: 'loading',
    description: 'Importing captions...',
  }

  const storedCaptions = localStorage.getItem('captions-' + captionsId)
  if (storedCaptions) {
    const json = JSON.parse(storedCaptions) as { date: string, captions: StoredCaption[], document?: CaptionsDocument }
    const captions = upgradeStoredCaptions(captionsId, json.captions, json.document)
    editorCaptionsStore.captionsDocument = json.document ?? null
    setTimeout(() => {
      // A watcher fires when the captionsDocument is set (because of course it does), so we need to wait for that to
      // finish before we can set the captions to the last saved data.
      editorCaptionsStore.groupedCaptions.splice(0, editorCaptionsStore.groupedCaptions.length, ...captions)
    }, 0)
  } else {
    const { generateCaptionsAsync } = useGenerateCaptions()
    await generateCaptionsAsync()
  }
}

function upgradeStoredCaptions(projectId: string, captions: StoredCaption[], document?: CaptionsDocument) {

  const upgradedCaptions = captions.map(ensureEachWordHasAnId)
  localStorage.setItem('captions-' + projectId, JSON.stringify({
    date: new Date().toISOString(),
    captions: upgradedCaptions,
    captionsDocument: document,
  }))

  return upgradedCaptions
}

const ensureEachWordHasAnId = (caption: StoredCaption) => ({
  ...caption,
  words: caption.words.map(w => ({ ...w, id: w.id ?? uuid() }))
})

function applyCaptionsSettings({ wrapper, settings }: Captions) {

  const editorCaptionsStore = useEditorCaptionsStore()

  editorCaptionsStore.captionsWrapper = wrapper
  editorCaptionsStore.captionStyle = settings.style
  
  editorCaptionsStore.selectedLanguage = settings.languageCode

  editorCaptionsStore.baseOptions.size = settings.fontSize

  editorCaptionsStore.baseOptions.animation = settings.animation.style
  editorCaptionsStore.baseOptions.animationTarget = settings.animation.target

  editorCaptionsStore.baseOptions.highlight = settings.highlights.enabled

  editorCaptionsStore.baseOptions.emojis = settings.emojis.enabled
  editorCaptionsStore.baseOptions.emojiLocation = settings.emojis.position

  editorCaptionsStore.baseOptions.grouping = settings.grouping
  editorCaptionsStore.baseOptions.stripPunctuation = settings.stripPunctuation
  editorCaptionsStore.baseOptions.rotate = settings.rotate

  editorCaptionsStore.styleOptions.data = {
    baseColor: settings.color,
    highlightColor: settings.highlights.color,
  }
}

function startupBySource(source: Project['source'], clipId: string, mp4UrlOverride: string | undefined, config: StartupConfig) {
  switch (source) {
    case 'local-file':
      return startupFromLocalFileByClipId(clipId, config)
    case 'twitch-clip':
      return startupFromTwitchClipByClipId(clipId, config, mp4UrlOverride)
    case 'twitch-vod':
      return startupFromTwitchVodByClipId(clipId, config)
    case 'youtube-clip':
      return startupFromYoutubeClipByClipId(clipId, config)
    case 'kick-clip':
        return startupFromKickClipByClipId(clipId, config)
    case 'kick-cx-clip':
      return startupFromKickCxClipByClipId(clipId, config)
    default:
      throw new Error('Not implemented')
  }
}

// In some cases a Blob URL is stored in the sticker object. This function removes it, because it can cause the application
// to throw errors when the Blob URL is no longer valid (for example after reloading).
function purgeBlobUrlFrom(sticker: TypedSticker) {
  const upgradedSticker = { ...sticker }
  if (upgradedSticker.imageUrl?.startsWith('blob:')) {
    upgradedSticker.imageUrl = ''
  }
  return upgradedSticker
}
