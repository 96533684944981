<script setup lang="ts">
import { isNewSticker, lastUsedStickerFirst, newestStickerFirst } from '@/helpers/isNewOverlayElement'
import { ref, watch, computed, onMounted } from 'vue'
import StickerWrapper from '@/areas/editor/pages/stickers/StickerWrapper.vue'
import stickerLibrary from '@/components/Stickers/stickerLibrary/stickerLibrary'
import { useLocalStorage } from '@vueuse/core'
import { useUserInfoStore, onUserInfoReady } from '@/store/user/userInfo'
import { useEditorClipInfoStore } from '@/store/editor/editorClipInfo'
import { Button } from '@/components/ui/button'
import { ScrollArea } from '@/components/ui/scroll-area'
import type { TypedSticker } from "@/areas/editor/@type/Project";
import { useStickersStore } from "@/areas/editor/store/useStickersStore";
import { useHistoryStore } from "@/areas/editor/store/useHistoryStore";
import logging from "@/logging";
import { FocusTypes, useEditorFocusStore } from '@/store/editor/editorFocus'
import { useEditorStep } from '@/areas/editor/hooks/useEditorStep'
import { editorRouteNames } from '@/areas/editor/routeNames'
import { useScreen } from '@/Hooks/useScreen'
import { getLastUsedSocialStickerKey, setLastUsedSocialStickerKey } from '@/areas/editor/pages/elements/useElementsSettings'
import type { Sticker } from '@/store/entity-system/useStickersStore'

const selectedFilter = ref()

const userInfoStore = useUserInfoStore()
const stickerText = ref()

const clipInfoStore = useEditorClipInfoStore()
watch(() => clipInfoStore.source, (src) => {
  selectedFilter.value = selectFilterByClipSource(src as 'twitch-clip' | 'twitch-vod' | 'youtube-clip' | 'kick-clip' | 'local-file')
}, { immediate: true })

function selectFilterByClipSource(source: 'twitch-clip' | 'twitch-vod' | 'youtube-clip' | 'kick-clip' | 'local-file') {
  switch (source) {
    case 'youtube-clip':
      return 'youtube'
    case 'kick-clip':
      return 'kick'
    case 'twitch-clip':
    case 'twitch-vod':
    default:
      return 'twitch'
  }
}

onUserInfoReady(() => {
  if (!stickerText.value) {
    stickerText.value = userInfoStore.userName || 'StreamLadder'
  }
})

const is2Xl = useScreen('2xl')
const maxItems = computed(() => {
  return is2Xl.value ? 6 : 4;
});

const stickers = ref<Sticker[] | null>(null);

const getFilteredStickers = () => {
  const lastUsedStickerKey = getLastUsedSocialStickerKey();
  stickers.value = stickerLibrary
    .sort(newestStickerFirst)
    .sort(lastUsedStickerFirst)
    .filter((sticker) => sticker.tags.includes(selectedFilter.value as never) || lastUsedStickerKey === sticker.key)
    .slice(0, maxItems.value) as Sticker[];
};

const socials = useLocalStorage('multi-sticker-socials', [
  { key: 'twitch', label: 'Twitch', selected: true },
  { key: 'kick', label: 'Kick', selected: false },
  { key: 'tiktok', label: 'TikTok', selected: false },
  { key: 'instagram', label: 'Instagram', selected: true },
  { key: 'youtube', label: 'YouTube', selected: true },
  { key: 'facebook', label: 'Facebook', selected: false },
])

const activeSocials = computed(() => {
  return socials.value.filter((s) => s.selected).map((s) => s.key)
})

const stickersStore = useStickersStore()
const historyStore = useHistoryStore()
const editorFocusStore = useEditorFocusStore()

async function select(sticker: TypedSticker<'social'>) {

  const id = stickersStore.createSocialSticker(sticker)
  await historyStore.push()

  editorFocusStore.setFocus(FocusTypes.STICKER, id)

  const meta = stickerLibrary.find((s) => s.key === sticker.key)!
  const isMultiSticker = 'tags' in meta && meta.tags && (meta.tags as string[]).includes('multiple')
  if (isMultiSticker) {
    logging.trackEvent('Editor MultiSticker Added', {
      Color: sticker.color,
      Component: meta.component,
      socials: activeSocials.value,
      Tags: meta.tags,
    })
  }

  setLastUsedSocialStickerKey(sticker.key);

  logging.trackEvent('Editor Sticker Added', {
    Color: sticker.color,
    Component: meta.component,
    Icon: 'icon' in meta ? meta.icon : undefined,
    Tags: meta.tags,
  })
}

const { currentSection } = useEditorStep()
const viewAll = () => {
  currentSection.value = editorRouteNames.stickers
};

onMounted(() => {
  getFilteredStickers();
});

watch(currentSection,() => {
  getFilteredStickers();
}, { immediate: true });
</script>

<template>
  <div class="flex flex-col gap-4 w-full h-full">

    <div class="flex flex-row gap-2 justify-between items-center">
      <h2 class="text-base font-semibold leading-snug">Social stickers</h2>
      <Button variant="link" class="font-semibold lg:text-sm p-0 h-full" @click="viewAll">
        View all
      </Button>
    </div>

    <ScrollArea class="min-h-0 overflow-y-auto -mx-4 px-4 2xl:-mx-8 2xl:px-8">
      <div class="grid w-full gap-2 notranslate grid-cols-2 lg:grid-cols-[repeat(auto-fill,_minmax(120px,_1fr))]">
        <StickerWrapper
          ref="stickerWrappers"
          v-for="sticker in stickers"
          :key="sticker.key"
          :stickerMeta="sticker"
          :is-new="isNewSticker(sticker)"
          :editable="false"
          :text-content="stickerText || '&nbsp;'"
          :active-socials="activeSocials"
          @select="select"
        />
      </div>
    </ScrollArea>
  </div>
</template>

<style scoped lang="scss">

</style>