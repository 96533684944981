import { useEditorClipInfoStore } from '@/store/editor/editorClipInfo'
import { prepareStores } from '@/areas/editor/startup/generalStartupMethods'
import type { StartupConfig } from '@/areas/editor/startup/StartupConfig'
import { handleStartupError } from '@/areas/editor/startup/handleStartupError'
import { getApiClipGptClipsId } from '@/apis/streamladder-api/clip-gpt-clips/clip-gpt-clips'
import type { ClipGptClipDto } from '@/apis/streamladder-api/model'

export async function startupFromTwitchVod(clip: ClipGptClipDto, config: StartupConfig) {

  try {
    const editorClipInfoStore = useEditorClipInfoStore()
    editorClipInfoStore.id = clip.id!
    editorClipInfoStore.title = clip.title ?? 'StreamLadder AI Clip'
    editorClipInfoStore.viewCount = 0
    editorClipInfoStore.mp4Url = clip.videoUrl!
    editorClipInfoStore.source = 'twitch-vod'
    editorClipInfoStore.languageCode = 'en_us'
    editorClipInfoStore.isLocalFile = false
    
    await prepareStores(config)
    return { error: null }
  } catch (e) {
    return handleStartupError(e)
  }
}

export async function startupFromTwitchVodByClipId(clipId: string, config: StartupConfig) {
  try {
    const clip = await getApiClipGptClipsId(clipId)
    return await startupFromTwitchVod(clip, config)
  } catch (e) {
    const editorClipInfoStore = useEditorClipInfoStore()
    editorClipInfoStore.loadingState = {
      state: 'error',
      title: 'Clip Not Found! 🚫',
      description: 'Looks like we couldn\'t find that clip. Please try again later or open a ticket for help! 💬'
    }
    return { error: 'Looks like we couldn\'t find that clip. Please try again later or open a ticket for help! 💬' }
  }
}
