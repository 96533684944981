type RetryOptions = {
  maxRetries: number
  baseDelayMs: number
}

const DEFAULT_RETRY_OPTIONS: RetryOptions = {
  maxRetries: 3,
  baseDelayMs: 100,
}

export async function retryAsync<T>(
  fn: () => Promise<T>,
  options: Partial<RetryOptions> = DEFAULT_RETRY_OPTIONS // Use default options if not provided
): Promise<T> {
  const { maxRetries, baseDelayMs } = { ...DEFAULT_RETRY_OPTIONS, ...options }
  let retries = 0
  let lastError: Error | null = null

  while (retries < maxRetries) {
    try {
      return await fn()
    } catch (error) {
      retries++
      lastError = error as Error
      if (retries < maxRetries) {
        const delay = baseDelayMs! * 2 ** retries
        await new Promise((resolve) => setTimeout(resolve, delay))
      }
    }
  }

  if (lastError) {
    throw new Error(`Function failed after ${maxRetries} retries. Last error: ${lastError.message}`)
  } else {
    throw new Error(`Function failed after ${maxRetries} retries.`)
  }
}
