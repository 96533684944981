import { v4 as uuid } from "uuid";
import type { Effect } from '@/areas/editor/@type/Project'
import { useSegmentsStore } from "@/areas/editor/store/useSegmentsStore";
import { useEffectsStore } from "@/areas/editor/store/useEffectsStore";
import { useStorage } from "@vueuse/core";
import { FocusTypes, useEditorFocusStore } from "@/store/editor/editorFocus";
import { useHistoryStore } from "@/areas/editor/store/useHistoryStore";

const defaultZoomLevel = useStorage('default-zoom-level', 0.5)

export function createZoomAt(ms: number, durationMs = 5000) {

  const segmentsStore = useSegmentsStore()
  const effectsStore = useEffectsStore()
  const editorFocusStore = useEditorFocusStore()
  const historyStore = useHistoryStore()

  const id = uuid()

  const segmentId = segmentsStore.createZoom(ms, durationMs, 1 - defaultZoomLevel.value)
  effectsStore.createById<Effect<'zoom'>>(id, {
    type: 'zoom',
    x: 0.5,
    y: 0.5,
    segmentId: segmentId,
  })

  editorFocusStore.setFocus(FocusTypes.ZOOM, id)
  historyStore.push()
}