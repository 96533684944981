<script setup lang="ts">
import { Button } from '@/components/ui/button'
import type { Effect } from '@/areas/editor/@type/Project'
import IconSaxPlay from '@/components/Icons/iconsax/IconSaxPlay.vue'
import { computed, onMounted, onUnmounted, ref, watch } from 'vue'
import IconSaxPause from '@/components/Icons/iconsax/IconSaxPause.vue'
import { useEditorStep } from '@/areas/editor/hooks/useEditorStep'
import { tiers } from '@/enums/tiers'
import GoldPlanButton from '@/components/Account/Upgrade/GoldPlanButton.vue'
import { useUserInfoStore } from '@/store/user/userInfo'

const props = defineProps<{
  sound: Effect<'sound'>
}>();

const emit = defineEmits<{
  (name: 'add', duration: number): void
  (name: 'play', audio: HTMLAudioElement): void
}>();

watch(() => props.sound.url, () => {
  resetAudio();
});

const { currentStep, currentSection } = useEditorStep();

watch(() => currentStep.value, () => {
  resetAudio();
});

watch(() => currentSection.value, () => {
  resetAudio();
});

const durationSeconds = ref(0);

const audio = new Audio();
const isPlaying = ref(false);
const currentTrack = ref<string | null>(null);

const togglePlay = (url: string) => {

  // If the same track is selected, toggle play/pause
  if (currentTrack.value === url) {
    if (isPlaying.value) {
      audio.pause();
    } else {
      audio.src = url;
      audio.load();
      audio.currentTime = 0;
      audio.play();
      emit('play', audio);
    }
    isPlaying.value = !isPlaying.value;
  } else {
    audio.src = url;
    audio.load();
    audio.currentTime = 0;
    audio.play();
    emit('play', audio);
    isPlaying.value = true;
    currentTrack.value = url;
  }
};

onMounted(() => {

  audio.src = props.sound.url;
  audio.load();

  audio.addEventListener('loadedmetadata', () => {
    durationSeconds.value = audio.duration;
  });

  audio.addEventListener('pause', () => {
    isPlaying.value = false;
    currentTrack.value = null;
  });

  audio.addEventListener('ended', () => {
    isPlaying.value = false;
    currentTrack.value = null;
  });
});

const resetAudio = () => {

  isPlaying.value = false;
  currentTrack.value = null;

  audio.currentTime = 0;
  audio.pause();
  audio.src = '';

  audio.removeEventListener('loadedmetadata', () => {
    durationSeconds.value = audio.duration;
  });

  audio.removeEventListener('pause', () => {
    isPlaying.value = false;
    currentTrack.value = null;
  });

  audio.removeEventListener('ended', () => {
    isPlaying.value = false;
    currentTrack.value = null;
  });
};

onUnmounted(() => {
  resetAudio();
});

// Computed property to format duration as "MM:SS"
const formattedDuration = computed(() => {
  const minutes = Math.floor(durationSeconds.value / 60);
  const seconds = Math.round(durationSeconds.value % 60);
  return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
});

const onAdd = (durationSeconds: number) => {
  emit('add', durationSeconds);
  audio.currentTime = 0;
  audio.pause();
  isPlaying.value = false;
  currentTrack.value = null;
};

const subtitleFromTags = (tags: string[]) => {
  return tags.toString().split(',').join(' • ');
};

const userInfoStore = useUserInfoStore();
</script>

<template>
  <div class="relative flex gap-2 justify-between border dark:border-surface-panel-border dark:hover:border-brand-state-hover-border hover:border-surface-panel-border transition-[border-color] rounded-lg p-3 w-full items-center group">
    <div class="flex gap-3 justify-center items-center h-full">
      <div
        class="flex items-center justify-center p-2.5 rounded-full cursor-pointer bg-gradient hover:scale-105 transition-[background-color,transform]"
        @click="togglePlay(sound.url)"
      >
        <IconSaxPause v-if="isPlaying" class="w-5 h-5 text-white fill-white" />
        <IconSaxPlay v-else class="w-5 h-5 text-white fill-white" />
      </div>
      <div class="flex flex-col gap-0 text-sm select-none">
        <span class="font-bold truncate overflow-hidden max-w-full">{{ sound.name }}</span>
        <span class="text-sm font-light leading-snug truncate overflow-hidden max-w-full">{{ subtitleFromTags(sound.tags) }}</span>
      </div>
    </div>
    <div class="text-sm flex p-4 justify-center items-center pointer-events-none font-light absolute right-0 h-full opacity-0 md:opacity-100 group-hover:opacity-0 transition-opacity">
      {{ formattedDuration }}
    </div>
    <Button
      :disabled="durationSeconds === 0"
      @click="() => onAdd(durationSeconds)"
      variant="primary"
      class="opacity-100 md:opacity-0 group-hover:opacity-100 transition-opacity"
    >
      Add
      <GoldPlanButton v-if="userInfoStore.tier !== tiers.GOLD" class="pointer-events-none -mt-0.5" :can-click="false" :small="true" />
    </Button>
  </div>
</template>

<style scoped lang="scss">

</style>