import { createClient, type Session } from '@supabase/supabase-js'
import { getPopupFeatures } from '@/helpers/PopupWindowHelper'
import { accountsAxios } from '@/services/axios'
import logging from '@/logging'
import { useUserInfoStore } from '@/store/user/userInfo'
import EventBus from '@/eventBus'
import authEvents from '@/events/authEvents'
import type { RouteLocation } from 'vue-router'
import { queryClient } from '@/services/QueryClient'
import { useToast } from '@/Hooks/useToast'
import settings from '@/data/settings'
import * as Sentry from '@sentry/vue'
import { dedupeAsync } from '@/helpers/dedupeAsync'

const url = import.meta.env.VITE_SUPABASE_URL
const key = import.meta.env.VITE_SUPABASE_KEY

const supabase = createClient(url, key)

export const getUser = dedupeAsync(async () => {
  return await supabase.auth.getUser();
});

export const getSession = dedupeAsync(async () => {
  return await supabase.auth.getSession();
});

export const refreshSession = dedupeAsync(async () => {
  return await supabase.auth.refreshSession();
});

export async function requestUserSignInAsync(title?: string) {
  const userInfoStore = useUserInfoStore()
  return await new Promise<boolean>((resolve) => {
    if (userInfoStore.isAuthenticated) {
      return resolve(true);
    } else {
      EventBus.$emit(authEvents.OPEN_LOGIN_DIALOG, {
        title: title,
        callback() {
          resolve(userInfoStore.isAuthenticated)
        }
      })
    }
  })
}

export async function signInWith(provider: 'google' | 'twitch') {
  return await signInWithOAuth(provider).catch((e) => {

    const { showToast } = useToast()
    const { title, subtitle } = formatSupabaseError(e?.code as string | undefined)
    showToast({
      title: title,
      subtitle: subtitle,
      type: 'toast_error',
      view: () => window.open(settings.discordInviteUrl, '_blank'),
      viewTitle: 'Contact support'
    })

    console.error(JSON.stringify(e))
    Sentry.captureException(e)

    return null
  })
}

export async function signOut(route: RouteLocation) {

  await supabase.auth.signOut()

  const userInfoStore = useUserInfoStore()
  await userInfoStore.updateUserInfo()
  await queryClient.invalidateQueries()
  if (route.meta.requiresAuth) {
    window.location.href = '/'
  } else {
    window.location.reload()
  }
}

function selectQueryParamsByProvider(provider: 'google' | 'twitch'): Record<string, string> | undefined {
  switch (provider) {
    case 'google':
      return { prompt: 'select_account' }
    case 'twitch':
      return { force_verify: 'true' }
    default:
      return undefined
  }
}

async function signInWithOAuth(provider: 'google' | 'twitch'): Promise<Session | null> {

  const { data, error } = await supabase.auth.signInWithOAuth({
    provider: provider,
    options: {
      redirectTo: window.location.origin + '/signin-callback',
      skipBrowserRedirect: true,
      queryParams: selectQueryParamsByProvider(provider)
    },
  })

  if (error) {
    throw error
  }

  if (!data?.url) {
    throw new Error('Invalid login URL')
  }

  try {
    const session = await openAuthenticationWindow(data.url, provider)
    if (session) {
      localStorage.setItem('lastUsedLoginProvider', provider)
      await updateUserDataSimultaneously(session)
      return session
    } else {
      return null
    }
  } catch (e) {
    throw e ?? new Error('Failed to get session')
  }
}

async function updateUserDataSimultaneously(session: Session) {
  await Promise.all([
    updateUserDataInStore(),
    updateUserDataInBackend(session)
  ])
}

async function updateUserDataInStore() {
  const userInfo = useUserInfoStore()
  await userInfo.updateUserInfo()
  logging.trackEvent('Logged In', {
    $set_once: {
      created_at: userInfo.createdAt
    }
  })
}

async function updateUserDataInBackend(session: Session) {

  const referrer = localStorage.getItem('refCode');
  const url = referrer ? `/loggedin?referrer=${referrer}` : '/loggedin'
  const headers = { Authorization: `Bearer ${session.access_token}` }
  const response = await accountsAxios.post(url, null, { headers })

  if (response.status === 200) {
    if (response.data.shouldRefreshToken as boolean) {
      await refreshSession()
    }
  } else {
    throw new Error('Failed to update user data in backend')
  }
}

async function openAuthenticationWindow(url: string, provider: 'google' | 'twitch') {
  return new Promise<Session | null>((resolve, reject) => {

    const authenticationWindow = window.open(url, provider.toUpperCase(), getPopupFeatures())
    if (!authenticationWindow) {
      return reject('Failed to open popup window')
    }

    const checkWindowClosed = setInterval(async () => {
      if (authenticationWindow.closed) {

        clearInterval(checkWindowClosed);

        const { data: { session } } = await refreshSession()
        if (!session) {
          resolve(null)
        }

        resolve(session);
      }
    }, 500);
  })
}

function formatSupabaseError(code: string | undefined) {
  switch (code) {
    case 'anonymous_provider_disabled':
      return { title: 'Anonymous Sign-In Disabled', subtitle: 'Anonymous sign-ins are currently disabled. Please contact support.' };
    case 'bad_json':
      return { title: 'Invalid Request', subtitle: 'There was an issue with your request. Please try again.' };
    case 'bad_jwt':
      return { title: 'Invalid Session', subtitle: 'Your session is invalid or expired. Please log in again.' };
    case 'conflict':
      return { title: 'Request Conflict', subtitle: 'There was a conflict. Please try again.' };
    case 'email_address_not_authorized':
      return { title: 'Email Not Authorized', subtitle: 'Sending emails to this address is not allowed.' };
    case 'email_exists':
      return { title: 'Email Already Registered', subtitle: 'This email is already in use. Try logging in instead.' };
    case 'email_not_confirmed':
      return { title: 'Email Not Confirmed', subtitle: 'Please confirm your email to proceed.' };
    case 'invalid_credentials':
      return { title: 'Invalid Credentials', subtitle: 'Your login details are incorrect. Please try again.' };
    case 'provider_disabled':
      return { title: 'Provider Disabled', subtitle: 'This authentication provider is disabled. Please try another method.' };
    case 'reauthentication_needed':
      return { title: 'Re-authentication Required', subtitle: 'Please reauthenticate to complete this action.' };
    case 'request_timeout':
      return { title: 'Request Timeout', subtitle: 'Your request took too long. Please try again.' };
    case 'signup_disabled':
      return { title: 'Signups Disabled', subtitle: 'New account signups are currently disabled. Please contact support.' };
    case 'user_banned':
      return { title: 'User Banned', subtitle: 'Your account has been banned. Please contact support for assistance.' };
    case 'user_not_found':
      return { title: 'User Not Found', subtitle: 'No account was found with this information. Please check your details.' };
    default:
      return { title: 'Signin Failed', subtitle: 'An unexpected error occurred. Please try again later.' };
  }
}