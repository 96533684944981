import { editorRouteNames } from '@/areas/editor/routeNames'
import LayoutsPage from '@/areas/editor/pages/layouts/LayoutsPage.vue'
import CaptionsPage from '@/areas/editor/pages/captions/CaptionsPage.vue'
import ExportPage from '@/areas/editor/pages/export/ExportPage.vue'
import { ref, computed } from 'vue'
import { usePrevious, createGlobalState } from '@vueuse/core'
import ElementsPage from '@/areas/editor/pages/elements/ElementsPage.vue'
import GifsPage from '@/areas/editor/pages/gifs/GifsPage.vue'
import TextPage from '@/areas/editor/pages/text/TextPage.vue'
import StickerUploadTab from '@/areas/editor/pages/stickers/StickerUploadTab.vue'
import StickerLibraryTab from '@/areas/editor/pages/stickers/StickerLibraryTab.vue'
import TwitchEmotesPage from '@/areas/editor/pages/emotes/TwitchEmotesPage.vue'
import EffectsPage from '@/areas/editor/pages/effects/EffectsPage.vue'
import ZoomsPage from '@/areas/editor/pages/zooms/ZoomsPage.vue'
import SoundsPage from '@/areas/editor/pages/sounds/SoundsPage.vue'
import SoundsPageEditSound from '@/areas/editor/pages/sounds/SoundsPageEditSound.vue'

export const useEditorStep = createGlobalState(() => {

  const steps = [
    {
      name: editorRouteNames.layouts,
      component: LayoutsPage,
      sections: null,
    },
    {
      name: editorRouteNames.elements,
      component: ElementsPage,
      sections: [
        {
          name: editorRouteNames.stickers,
          component: StickerLibraryTab,
        },
        {
          name: editorRouteNames.uploads,
          component: StickerUploadTab,
        },
        {
          name: editorRouteNames.gifs,
          component: GifsPage,
        },
        {
          name: editorRouteNames.text,
          component: TextPage,
        },
        {
          name: editorRouteNames.emotes,
          component: TwitchEmotesPage,
        }
      ]
    },
    {
      name: editorRouteNames.effects,
      component: EffectsPage,
      sections: [
        {
          name: editorRouteNames.zooms,
          component: ZoomsPage,
        },
      ],
    },
    {
      name: editorRouteNames.sounds,
      component: SoundsPage,
      sections: [
        {
          name: editorRouteNames.editSounds,
          component: SoundsPageEditSound,
        },
      ],
    },
    {
      name: editorRouteNames.captions,
      component: CaptionsPage,
      sections: null,
    },
    {
      name: editorRouteNames.export,
      component: ExportPage,
      sections: null,
    },
  ] as const

  function findStepIndex(step: (typeof steps)[number]['name'] | undefined) {
    return step ? steps.map(s => s.name).indexOf(step) : -1
  }

  const currentSection = ref<string|null>(null);

  const currentStep = ref<typeof steps[number]['name']>('editor-layouts');
  const previousStep = usePrevious(currentStep);

  const component = computed(() => {

    const step = steps[findStepIndex(currentStep.value)];

    if (!step?.component) {
      currentStep.value = editorRouteNames.layouts;
      return steps[0].component;
    }

    if (!step?.sections) {
      return step.component;
    }

    const section = step.sections?.find(s => s.name === currentSection.value);
    if (section) {
      return section.component;
    } else {
      return step.component;
    }
  });

  const sections = computed(() => steps[findStepIndex(currentStep.value)]?.sections);

  const isLayoutsStep = computed(() => currentStep.value === editorRouteNames.layouts)
  const isCaptionsStep = computed(() => currentStep.value === editorRouteNames.captions)
  const isEffectsStep = computed(() => currentStep.value === editorRouteNames.effects)
  const isZoomStep = computed(() => currentStep.value === editorRouteNames.effects && currentSection.value === editorRouteNames.zooms)

  const stepIndex = computed(() => findStepIndex(currentStep.value))
  const direction = computed(() => {
    if (findStepIndex(previousStep.value) < findStepIndex(currentStep.value)) {
      return 'forward'
    } else {
      return 'backward'
    }
  })

  function selectLabelByStepIndex(index: number) {
    switch (index) {
      case 0:
        return 'Crop'
      case 1:
        return 'Elements'
      case 2:
        return 'Effects'
      case 3:
        return 'Audio'
      case 4:
        return 'Captions'
      case 5:
        return 'Export'
      default:
        return 'Render'
    }
  }

  return {
    steps,
    currentStep,
    previousStep,
    component,
    currentSection,
    sections,
    stepIndex,
    direction,
    isLayoutsStep,
    isCaptionsStep,
    isEffectsStep,
    isZoomStep,
    selectLabelByStepIndex,
    resetEditorStep() {
      currentStep.value = editorRouteNames.layouts
      currentSection.value = null
    }
  }
})