import { useStickersStore } from '@/areas/editor/store/useStickersStore'
import { useCropsStore } from '@/areas/editor/store/useCropsStore'
import { useLayoutsStore } from '@/areas/editor/store/useLayoutsStore'
import { useSegmentsStore } from '@/areas/editor/store/useSegmentsStore'
import type { Segment, Layout, Crop, Sticker, Project, Captions, Effect, TypedSticker } from "@/areas/editor/@type/Project";
import { ref, computed, type Reactive } from 'vue'
import type { Nullable } from 'vitest'
import { useRouter, useRoute } from 'vue-router'
import { useEditorClipInfoStore } from '@/store/editor/editorClipInfo'
import { defineStore } from 'pinia'
import { useVideoStore } from '@/areas/editor/store/useVideoStore'
import type { EntityStore } from '@/areas/editor/store/useEntityStore'
import { clamp, omit, isEqual } from 'lodash-es'
import { useEditorCaptionsStore } from '@/store/editor/editorCaptions'
import { startupFromSnapshot } from '@/areas/editor/startup/startupFromSnapshot'
import { editorRouteNames } from '@/areas/editor/routeNames'
import { useEffectsStore } from "@/areas/editor/store/useEffectsStore";
import { logFlatTable } from '@/lib/console';

export const useHistoryStore = defineStore('history', () => {

  const cropsStore = useCropsStore()
  const layoutsStore = useLayoutsStore()
  const segmentsStore = useSegmentsStore()
  const stickersStore = useStickersStore()
  const effectsStore = useEffectsStore()
  const editorClipInfoStore = useEditorClipInfoStore()
  const videoStore = useVideoStore()

  function takeSnapshot() {

    const segments: Segment[] =  []
    const layouts: Layout[] = []
    const crops: Crop[] = []
    const stickers: Sticker[] = []
    const effects: Effect[] = []

    for (const segment of segmentsStore.entities) {
      segments.push(segment)
      if (layouts.some(l => l.id === segment.layoutId)) {
        continue
      }

      const layout = layoutsStore.selectById(segment.layoutId)
      if (layout) {
        layouts.push({ id: layout.id, name: layout.name, presetId: layout.presetId || null })
        crops.push(...cropsStore.whereLayoutIdIs(layout.id).value)
      }
    }

    for (const sticker of stickersStore.entities) {
      const upgradedSticker = purgeBlobUrlFrom(sticker)
      stickers.push(upgradedSticker)
    }

    for (const effect of effectsStore.entities) {
      if ('segmentId' in effect && segments.find(s => s.id === effect.segmentId)) {
        effects.push(effect)
      } else if (effect.type === 'sound') {
        effects.push(effect)
      }
    }

    return JSON.stringify(ensureValidSnapshot({
      id: editorClipInfoStore.id,
      title: editorClipInfoStore.title,
      mp4Url: editorClipInfoStore.mp4Url,
      source: editorClipInfoStore.source,
      segments: segments,
      layouts: layouts,
      crops: crops,
      stickers: stickers,
      effects: effects,
      captions: takeCaptionsSnapshot()
    }))
  }

  const history = ref<Project[]>([])
  const index = ref(history.value.length - 1)
  const route = useRoute()
  const router = useRouter()

  async function insertHistoryState() {
    const snapshot = JSON.parse(takeSnapshot())
    storeCaptionsDocument(snapshot.id)
    history.value.push(snapshot)
    index.value += 1
    if (route.query.s) {
      try {
        await router.replace({ name: editorRouteNames.root, query: { s: jsonToRouteQuery(snapshot) } })
      } catch (e) {
        console.log(snapshot)
      }
    } else {
      await router.push({ name: editorRouteNames.root, query: { s: jsonToRouteQuery(snapshot) } })
    }
  }

  async function push() {
    history.value = history.value.slice(0, index.value + 1)
    await insertHistoryState()
  }

  async function replace() {
    history.value = history.value.slice(0, index.value)
    await insertHistoryState()
  }

  function applySnapshot(snapshot: Project) {

    applyDifferences(segmentsStore, snapshot.segments)
    applyDifferences(layoutsStore, snapshot.layouts)
    applyDifferences(cropsStore, snapshot.crops)
    applyDifferences(stickersStore, snapshot.stickers)
    applyDifferences(effectsStore, snapshot.effects)

    applyCaptionsSettings(snapshot.captions)

    editorClipInfoStore.id = snapshot.id ?? ''
    editorClipInfoStore.title = snapshot.title ?? 'New Clip'
    editorClipInfoStore.mp4Url = snapshot.mp4Url ?? ''

    if (!snapshot.id) {
      editorClipInfoStore.$reset()
    }

    if (!snapshot.mp4Url) {
      videoStore.unmountElement()
    }
  }

  async function go(delta: number) {

    index.value = clamp(index.value + delta, 0, history.value.length - 1)
    const state = history.value[index.value]
    applySnapshot(state)

    if (!snapshot || !snapshot.mp4Url || !snapshot.id) {
      await router.push({ query: undefined })
    } else {
      await router.push({ query: { s: jsonToRouteQuery(snapshot) } })
    }
  }

  async function reset() {

    index.value = 0
    history.value = []
    const state = ensureValidSnapshot({})
    applySnapshot(state)
    editorClipInfoStore.loadingState = null

    console.log('reset')
    await router.push({ name: editorRouteNames.root, query: undefined })
  }

  async function forward() {
    await go(+1)
  }

  async function back() {
    await go(-1)
  }

  const canGoForward = computed(() => {
    return index.value < history.value.length - 1
  })

  const canGoBack = computed(() => {
    return index.value > 0
  })

  window.addEventListener('keypress', async (event) => {

    const ctrlOrCmd = event.ctrlKey || event.metaKey
    const shift = event.shiftKey
    const z = event.key === 'z'
    const y = event.key === 'y'

    if ((ctrlOrCmd && shift && z) || (ctrlOrCmd && !shift && y)) {
      event.preventDefault()
      if (canGoForward.value) {
        await forward()
        return false
      }
    } else if (ctrlOrCmd && !shift && z) {
      event.preventDefault()
      if (canGoBack.value) {
        await back()
        return false
      }
    }
  })

  const snapshotQuery = route.query.s
  const snapshot = typeof snapshotQuery === 'string' ? routeQueryToJson(snapshotQuery) : null
  if (snapshot !== null) {
    startupFromSnapshot(ensureValidSnapshot(snapshot))
      .then(() => logFlatTable(snapshot))
      .catch((error) => console.error('Failed to load snapshot', error))
  }

  router.beforeEach((to, from, next) => {
    if (to.path.startsWith('/editor')) {
      if (to.query.s) {
        const snapshot = JSON.parse(takeSnapshot())
        const routeSnapshot = routeQueryToJson(to.query.s as string)
        if (snapshot.id !== routeSnapshot.id || snapshot.mp4Url !== routeSnapshot.mp4Url) {
          if (!snapshot.mp4Url) {
            startupFromSnapshot(ensureValidSnapshot(routeSnapshot))
              .then(() => logFlatTable(snapshot))
              .catch((error) => console.error('Failed to load snapshot', error))
          } else if (routeSnapshot.id) {
            applySnapshot(routeSnapshot)
          } else {
            delete to.query.s
            return next(to)
          }
        }
        return next()
      }
    }

    index.value = 0
    history.value = []
    const state = ensureValidSnapshot({})
    applySnapshot(state)

    next()
  })

  return {
    history,
    reset,
    index,
    push,
    replace,
    go,
    forward,
    back,
    canGoForward,
    canGoBack,
    takeSnapshot,
  }
})

export async function transaction(transaction: () => void) {
  transaction()
  const historyStore = useHistoryStore()
  await historyStore.push()
}

export function ensureValidSnapshot(snapshot: Nullable<Partial<Omit<Project, 'source'> & { source: string }>>): Project {
  return {
    id: snapshot?.id ?? undefined,
    title: snapshot?.title ?? undefined,
    mp4Url: snapshot?.mp4Url ?? undefined,
    source: ensureValidSource(snapshot?.source),
    segments: snapshot?.segments ?? [],
    layouts: snapshot?.layouts ?? [],
    crops: snapshot?.crops ?? [],
    stickers: snapshot?.stickers ?? [],
    effects: snapshot?.effects ?? [],
    captions: snapshot?.captions ?? takeCaptionsSnapshot(),
  }
}

function ensureValidSource(source: string | undefined): Project['source'] {
  switch (source) {
    case 'twitch-clip':
    case 'twitch-vod':
    case 'youtube-clip':
    case 'kick-clip':
    case 'local-file':
      return source
    default:
      return undefined
  }
}

type UnpackedStore<T> = Omit<Reactive<EntityStore<T>>, 'operations'> & EntityStore<T>['operations']

function applyDifferences<T extends { id: string }>(store: UnpackedStore<T>, items: T[]) {

  const itemsToRemove = store.ids.filter(id => !items.some(item => item.id === id)) as string[]
  for (const item of itemsToRemove) {
    store.removeById(item)
  }

  for (const item of items) {
    mergeById(store, item)
  }
}

function mergeById<T extends { id: string }>(store: UnpackedStore<T>, item: T) {
  if (!store.state[item.id]) {
    store.createById(item.id, item)
  } else {
    for (const key of Object.keys(omit(item, 'id')) as (keyof T)[]) {
      if (!isEqual((store.state[item.id] as T)[key], item[key])) {
        (store.state[item.id] as T)[key] = item[key]
      }
    }
  }
}

function takeCaptionsSnapshot(): Captions {
  const editorCaptionsStore = useEditorCaptionsStore()
  return {
    wrapper: editorCaptionsStore.captionsWrapper,
    settings: {

      style: editorCaptionsStore.captionStyle,
      fontSize: editorCaptionsStore.baseOptions.size,
      languageCode: editorCaptionsStore.selectedLanguage,

      animation: {
        style: editorCaptionsStore.baseOptions.animation,
        target: editorCaptionsStore.baseOptions.animationTarget,
      },

      color: editorCaptionsStore.styleOptions.data?.baseColor,
      highlights: {
        enabled: editorCaptionsStore.baseOptions.highlight,
        color: editorCaptionsStore.styleOptions.data?.highlightColor,
      },

      emojis: {
        enabled: editorCaptionsStore.baseOptions.emojis,
        position: editorCaptionsStore.baseOptions.emojiLocation,
      },

      grouping: editorCaptionsStore.baseOptions.grouping,
      stripPunctuation: editorCaptionsStore.baseOptions.stripPunctuation,
      rotate: editorCaptionsStore.baseOptions.rotate,
    },
    generated: editorCaptionsStore.captionsGenerated
  }
}

function applyCaptionsSettings({ wrapper, settings }: Captions) {

  const editorCaptionsStore = useEditorCaptionsStore()

  editorCaptionsStore.captionsWrapper = wrapper
  editorCaptionsStore.captionStyle = settings.style

  editorCaptionsStore.baseOptions.size = settings.fontSize

  editorCaptionsStore.baseOptions.animation = settings.animation.style
  editorCaptionsStore.baseOptions.animationTarget = settings.animation.target

  editorCaptionsStore.baseOptions.highlight = settings.highlights.enabled

  editorCaptionsStore.baseOptions.emojis = settings.emojis.enabled
  editorCaptionsStore.baseOptions.emojiLocation = settings.emojis.position

  editorCaptionsStore.baseOptions.grouping = settings.grouping
  editorCaptionsStore.baseOptions.stripPunctuation = settings.stripPunctuation
  editorCaptionsStore.baseOptions.rotate = settings.rotate

  editorCaptionsStore.styleOptions.data = {
    baseColor: settings.color,
    highlightColor: settings.highlights.color,
  }
}

function encodeToBase64(str: string) {
  const encoder = new TextEncoder();
  const encodedData = encoder.encode(str);
  return btoa(String.fromCharCode(...encodedData));
}

function decodeFromBase64(base64Str: string) {
  const binaryString = atob(base64Str);
  const binaryData = new Uint8Array([...binaryString].map(char => char.charCodeAt(0)));
  const decoder = new TextDecoder();
  return decoder.decode(binaryData);
}

export function jsonToRouteQuery(json: Record<string, unknown>) {
  return encodeToBase64(encodeURIComponent(JSON.stringify(json)))
}

export function routeQueryToJson(query: string) {
  return JSON.parse(decodeURIComponent(decodeFromBase64(query)))
}

// In some cases a Blob URL is stored in the sticker object. This function removes it, because it can cause the application
// to throw errors when the Blob URL is no longer valid (for example after reloading).
function purgeBlobUrlFrom(sticker: TypedSticker) {
  const upgradedSticker = { ...sticker }
  if (upgradedSticker.imageUrl?.startsWith('blob:')) {
    upgradedSticker.imageUrl = ''
  }
  return upgradedSticker
}

function storeCaptionsDocument(clipId: string) {
  const editorCaptionsStore = useEditorCaptionsStore()
  if (editorCaptionsStore.captionsDocument !== null) {
    localStorage.setItem(`captions-${clipId}`, JSON.stringify({
      date: new Date().toISOString(),
      captions: editorCaptionsStore.groupedCaptions,
      document: editorCaptionsStore.captionsDocument
    }))
  }
}
