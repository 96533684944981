import { createGlobalState, toValue, useEventListener, useMediaControls, useRafFn, watchImmediate } from '@vueuse/core'
import type { MaybeRef } from '@vueuse/shared'
import { computed, nextTick, onUnmounted, ref, watch, watchEffect } from 'vue'
import { gsap } from 'gsap'

type Segment = {
  startMs: number
  endMs: number
}

type UseMediaControllerOptions = {
  src: MaybeRef<string>
  segments: MaybeRef<Segment[]>
  headStart?: number
  globalTimeline?: gsap.core.Timeline
}
const getId = (function () {
  let incrementingId = 0
  return function (element: HTMLElement) {
    if (!element.id) {
      element.id = 'id_' + incrementingId++
      // Possibly add a check if this ID really is unique
    }
    return element.id
  }
})()
const useCurrentPlaying = createGlobalState(() => {
  return ref<string>()
})
export let globaltimeline: gsap.core.Timeline

export const useSLVideoController = (target: MaybeRef<HTMLVideoElement | undefined>, options: UseMediaControllerOptions) => {
  const video = computed(() => toValue(target))
  const segments = computed(() => toValue(options?.segments) ?? [])
  const { duration: _duration, ...mediaControls } = useMediaControls(target, {
    src: options.src,
  })

  // Auto stop playing if a different video is playing
  const currentPlaying = useCurrentPlaying()
  const targetId = computed(() => {
    return video.value ? getId(video.value) : undefined
  })
  watch(mediaControls.playing, (playing) => {
    if (playing) {
      currentPlaying.value = targetId.value
    }
  })
  watch(currentPlaying, (currentPlaying) => {
    if (currentPlaying !== targetId.value) {
      mediaControls.playing.value = false
    }
  })

  // Segment support
  const hasSegments = computed(() => segments.value && segments.value.length > 0)

  const currentSegmentIndex = ref(0)

  watch(segments, () => {
    updateCurrentSegment()
  })

  const findCurrentSegmentIndex = (time: number) => {
    const _segments = toValue(options?.segments)
    if (!_segments) return
    for (let i = 0; i < _segments.length; i++) {
      const segment = _segments[i]
      if (time * 1000 < segment.endMs) {
        return i
      }
    }
  }

  const updateCurrentSegment = () => {
    if (!video.value || !hasSegments.value) return
    const currentSegment = segments.value[currentSegmentIndex.value]
    if (!currentSegment) {
      currentSegmentIndex.value = findCurrentSegmentIndex(mediaControls.currentTime.value) ?? 0
      return
    }
    const currentTime = video.value.currentTime
    if (currentSegment && currentTime > currentSegment.endMs / 1000) {
      const nextSegmentIndex = (currentSegmentIndex.value + 1) % segments.value.length
      const nextSegment = segments.value[nextSegmentIndex]

      if (Math.abs(nextSegment.startMs - currentSegment.endMs) > 100) {
        mediaControls.currentTime.value = nextSegment.startMs / 1000
      }

      currentSegmentIndex.value = nextSegmentIndex
    }

    if (currentSegment && currentTime < currentSegment.startMs / 1000) {
      const nextSegmentIndex = segments.value.findIndex(
        (segment) =>
          (segment.endMs > currentTime * 1000 + 100 && segment.startMs < currentTime * 1000 + 100) ||
          segment.startMs > currentTime * 1000 + 100
      )
      const nextSegment = segments.value[nextSegmentIndex]
      if (!nextSegment) return
      const isWithinSegment =
        currentTime * 1000 + 100 > nextSegment.startMs && currentTime * 1000 + 100 < nextSegment.endMs
      if (!isWithinSegment) mediaControls.currentTime.value = nextSegment.startMs / 1000
      currentSegmentIndex.value = nextSegmentIndex
    }
  }

  useRafFn(() => {
    if (mediaControls.playing.value) {
      updateCurrentSegment()
    }
  })

  // Corrected duration based on segments
  const duration = computed(() => {
    const _segments = toValue(options?.segments)
    if (hasSegments.value && _segments) {
      return _segments.reduce((acc, segment) => {
        return acc + (segment.endMs - segment.startMs)
      }, 0)
    }
    return _duration.value * 1000
  })

  // corrected currentTime based on segments
  const emptyDurationBeforeCurrentSegment = computed(() => {
    let emptyDuration = 0
    let previousEnd = 0
    if (!hasSegments.value) return emptyDuration
    const _segments = toValue(options?.segments)
    if (_segments && currentSegmentIndex.value !== undefined) {
      for (let i = 0; i <= currentSegmentIndex.value; i++) {
        const segment = _segments[i]
        if (!segment) break
        emptyDuration += segment.startMs - previousEnd
        previousEnd = segment.endMs
      }
    }
    return emptyDuration
  })

  const currentTime = computed({
    get() {
      return mediaControls.currentTime.value * 1000 - emptyDurationBeforeCurrentSegment.value
    },
    set(value) {
      const percentage = value / duration.value
      const correctedTime = percentage * _duration.value
      let emptyDuration = 0
      let previousEnd = 0
      const _segments = toValue(options?.segments)
      if (_segments) {
        for (let i = 0; i < _segments.length; i++) {
          const segment = _segments[i]
          if (correctedTime > segment.startMs) break
          emptyDuration += segment.startMs - previousEnd
          previousEnd = segment.endMs
        }
      }
      mediaControls.currentTime.value = value / 1000 + emptyDuration / 1000
    },
  })

  // Timeline support
  const timeline = ref(
    options.globalTimeline
      ? undefined
      : gsap.timeline({
          repeat: -1,
          repeatDelay: 0,
          paused: true,
        })
  )

  const getTimeLine = () => {
    if (options.globalTimeline) {
      return options.globalTimeline
    } else {
      return timeline.value
    }
  }

  const seekTimeline = (time: number) => {
    if (options.globalTimeline) {
      globaltimeline = options.globalTimeline
      globaltimeline.seek(time)
    } else {
      timeline.value?.seek(time)
    }
  }

  const initTimeline = (duration: number) => {
    if (options.globalTimeline) {
      globaltimeline = options.globalTimeline
      globaltimeline.set({}, {}, duration)
    } else {
      timeline.value?.set({}, {}, duration)
    }
  }

  watch(mediaControls.playing, (playing) => {
    if (playing) {
      getTimeLine()?.play()
    } else {
      getTimeLine()?.pause()
    }
  })
  useEventListener(target, 'timeupdate', () => {
    const _target = toValue(target)
    if (!_target) return

    // if time is out of sync, seek to the correct time
    if (Math.abs(mediaControls.currentTime.value - (getTimeLine()?.time?.() ?? 0)) > 0.1) {
      seekTimeline(mediaControls.currentTime.value)
    }
  })
  useEventListener(target, 'seeked', () => {
    const _target = toValue(target)
    if (!_target) return
    // if time is out of sync, seek to the correct time
    seekTimeline(mediaControls.currentTime.value)
  })

  watch(_duration, async () => {
    initTimeline(_duration.value)
  })

  const preciseCurrentTime = ref(0)
  const { pause: pauseRaf, resume: resumeRaf } = useRafFn(() => {
    const video = toValue(target)
    if (video) {
      preciseCurrentTime.value = video.currentTime
    }
  })

  const currentTimeBindings = (() => {

    const min = ref(0)
    const max = _duration
    const value = computed({
      get: () => preciseCurrentTime.value,
      set: (value) => {
        preciseCurrentTime.value = value
        mediaControls.currentTime.value = value
      },
    })

    return {
      min: min,
      max: max,
      step: 0.001,
      model: value,
      progress: computed(() => Math.round((value.value - min.value) / (max.value - min.value) * 1000) / 1000 * 100),
      onMouseDown: () => pauseRaf(),
      onMouseUp: () => setTimeout(resumeRaf, 0),
    }
  })()


  return {
    ...mediaControls,
    _duration: _duration,
    _currentTime: mediaControls.currentTime,
    preciseCurrentTime,
    currentTimeMs: computed(() => preciseCurrentTime.value * 1000),
    duration,
    currentTime,
    timeline,
    currentSegmentIndex,
    currentTimeBindings,
  }
}
