<script setup lang="ts">
import { useEffectsStore } from '@/areas/editor/store/useEffectsStore'
import { useRafFn, useElementSize, useStorage } from '@vueuse/core'
import { ref, computed } from 'vue'
import { useVideoStore } from '@/areas/editor/store/useVideoStore'
import { Slider } from '@/components/ui/slider'
import { Button } from '@/components/ui/button'
import { clamp } from 'lodash-es'
import { useSegmentsStore } from '@/areas/editor/store/useSegmentsStore'
import { useCropsStore } from '@/areas/editor/store/useCropsStore'
import { useHistoryStore } from '@/areas/editor/store/useHistoryStore'
import { useToast } from '@/Hooks/useToast'
import toastEvents from '@/events/toastEvents'
import { useConfirmDialog } from '@/components/Dialog/Confirm/useConfirmDialog'

const props = defineProps<{ id: string }>()
const emit = defineEmits<{
  (event: 'move-start'): void
  (event: 'move'): void
  (event: 'move-end'): void
}>()

const videoStore = useVideoStore()

const effectsStore = useEffectsStore()
const zoom = effectsStore.selectById(props.id)

const segmentsStore = useSegmentsStore()
const segment = segmentsStore.selectById(zoom.segmentId)

const cropsStore = useCropsStore()
const crop = cropsStore.selectByLayoutId(segment.layoutId)

const canvas = ref<HTMLCanvasElement | null>(null)
const container = ref<HTMLDivElement | null>(null)

useRafFn(() => {
  const sourceCanvas = videoStore.canvas
  if (canvas.value instanceof HTMLCanvasElement && sourceCanvas) {
    canvas.value.height = Math.min(sourceCanvas.height, container.value?.clientHeight ?? 0)
    canvas.value.width = (canvas.value.height / sourceCanvas.height) * sourceCanvas.width
    const ctx = canvas.value.getContext('2d')
    ctx?.drawImage(sourceCanvas, 0, 0, canvas.value.width, canvas.value.height)
  }
})

const zoomLevel = computed({
  get() {
    return 1 - crop.height
  },
  set(value: number) {

    const zoomLevel = value

    const centerOriginX = crop.x + 0.5 * crop.width
    const centerOriginY = crop.y + 0.5 * crop.height

    const absoluteHeight = (1 - zoomLevel) * canvasHeight.value
    const absoluteWidth = absoluteHeight * (9 / 16)

    const newWidth = absoluteWidth / canvasWidth.value
    const newHeight = absoluteHeight / canvasHeight.value

    crop.x = clamp(centerOriginX - 0.5 * newWidth, 0, 1 - newWidth)
    crop.y = clamp(centerOriginY - 0.5 * newHeight, 0, 1 - newHeight)
    crop.width = newWidth
    crop.height = newHeight

    startEditing()
  },
})

const { width: canvasWidth, height: canvasHeight } = useElementSize(canvas)

const range = computed<number[]>({
  get() {
    return [zoomLevel.value]
  },
  set(value: number[]) {
    zoomLevel.value = value[0]
    startEditing()
  },
})

const wasPlaying = ref(videoStore.playing)
const isEditing = ref(false)
function startEditing() {

  if (isEditing.value) return
  isEditing.value = true

  wasPlaying.value = videoStore.playing
  videoStore.playing = false

  const start = segment.startMs / 1000
  const end = segment.endMs / 1000
  const halfwayPoint = start + 0.5 * (end - start)

  if (videoStore._currentTime !== halfwayPoint) {
    videoStore._currentTime = halfwayPoint
  }
}

const historyStore = useHistoryStore()
function stopEditing() {
  videoStore.playing = wasPlaying.value
  historyStore.push()
  isEditing.value = false
}

function onMouseDown(event: MouseEvent | TouchEvent) {

  emit('move-start')

  onMouseMove(event)
  startEditing()

  document.body.classList.add('[&_*]:!cursor-grabbing', '[&_*]:!select-none')

  window.addEventListener('mousemove', onMouseMove)
  window.addEventListener('touchmove', onMouseMove)

  window.addEventListener('mouseup', onMouseUp)
  window.addEventListener('touchend', onMouseUp)
}

function onMouseMove(event: MouseEvent | TouchEvent) {

  emit('move')

  if (!(canvas.value instanceof HTMLCanvasElement)) {
    onMouseUp()
    return;
  }

  const rect = canvas.value.getBoundingClientRect()
  const { pageX, pageY } = 'pageX' in event ? event : event.touches[0]

  const x = (pageX - rect.left) / rect.width
  const y = (pageY - rect.top) / rect.height

  setPositionOfZoomAndCrop(x, y)
}

const setPositionOfZoomAndCrop = (x: number, y: number) => {

  crop.x = clamp(x - 0.5 * crop.width, 0, 1 - crop.width)
  crop.y = clamp(y - 0.5 * crop.height, 0, 1 - crop.height)

  zoom.x = clamp(((x - crop.x) * canvasWidth.value) / (crop.width * canvasWidth.value), 0, 1)
  zoom.y = clamp(((y - crop.y) * canvasHeight.value) / (crop.height * canvasHeight.value), 0, 1)
}

function onMouseUp() {

  emit('move-end')

  document.body.classList.remove('[&_*]:!cursor-grabbing', '[&_*]:!select-none')

  window.removeEventListener('mousemove', onMouseMove)
  window.removeEventListener('touchmove', onMouseMove)

  window.removeEventListener('mouseup', onMouseUp)
  window.removeEventListener('touchend', onMouseUp)

  stopEditing()
}

function commitZoomLevel(range: number[]) {
  defaultZoomLevel.value = range[0]
  stopEditing()
}

const { showToast } = useToast()

const defaultZoomLevel = useStorage('default-zoom-level', 0.5)
const zooms = effectsStore.idsWhereTypeIs('zoom')

const confirmDialog = useConfirmDialog()

async function applyToAll() {

  const confirmed = await confirmDialog.reveal({
    title: 'Apply the current zoom settings to all zooms',
    message: 'Are you sure you want to apply this zoom level and position to all zooms?',
  });

  if (!confirmed) {
    return;
  }

  for (const id of zooms.value) {
    effectsStore.state[id].x = zoom.x
    effectsStore.state[id].y = zoom.y
  }

  const allZoomCrops = zooms.value.map((id) => {
    const zoom = effectsStore.state[id]
    const segment = segmentsStore.selectById(zoom.segmentId)
    return cropsStore.selectByLayoutId(segment.layoutId)
  })

  for (const otherCrop of allZoomCrops) {
    otherCrop.x = crop.x
    otherCrop.y = crop.y
    otherCrop.width = crop.width
    otherCrop.height = crop.height
  }

  showToast({
    type: toastEvents.TOAST_SUCCESS,
    title: 'Applied to all zooms',
    subtitle: 'The zoom level and position has been applied to all zoom effects in this clip.',
    timeout: 4000,
  })

  defaultZoomLevel.value = 1 - crop.height
}

const cropX = computed(() => crop.x * canvasWidth.value)
const cropY = computed(() => crop.y * canvasHeight.value)
const cropWidth = computed(() => crop.width * canvasWidth.value)
const cropHeight = computed(() => crop.height * canvasHeight.value)

const amountOfZoomEffects = computed(() => zooms.value.length)
</script>

<template>
  <div class="flex flex-col gap-2 md:gap-4 h-full min-h-0 overflow-y-auto">

    <header>
      <h2 class="text-xl hidden md:block font-semibold leading-snug">Choose where to zoom</h2>
      <p class="font-light hidden md:block">Drag the box below around to choose where you want to zoom in your video.</p>
    </header>

    <div
      ref="container"
      class="grid place-items-center mb-4"
      :class="{ 'h-[250px]': canvasWidth > canvasHeight, 'h-[300px]': canvasWidth <= canvasHeight }"
    >
      <div class="rounded-xl border-8 border-black hover:border-gray-600 transition-[border-color] group">
        <div class="relative" @mousedown="onMouseDown" @touchstart.passive="onMouseDown">
          <canvas ref="canvas" class="w-full h-full cursor-pointer overflow-hidden" />
          <div
            class="absolute outline-brand-state-active-border before:absolute before:rounded-[inherit] before:inset-0 before:opacity-50 before:bg-brand-state-active-bg outline cursor-grab"
            :style="{
              width: cropWidth + 'px',
              height: cropHeight + 'px',
              left: cropX + 'px',
              top: cropY + 'px',
              borderRadius: 0.05 * Math.max(cropWidth, cropHeight) + 'px',
            }">
            <div
              class="absolute z-10 h-9 w-9 -translate-x-1/2 -translate-y-1/2 cursor-grab"
              :style="{ left: zoom.x * 100 + '%', top: zoom.y * 100 + '%' }"
            >
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="h-px w-full rounded-full bg-surface-panel-border hidden md:block" />

    <header>
      <h2 class="text-base md:text-xl font-semibold leading-snug">Zoom level</h2>
      <p class="font-light hidden md:block">How close to zoom during the zoom effect.</p>
    </header>

    <div class="flex flex-row gap-3 items-center mb-2">
      <Button variant="outline" size="sm" class="text-xl w-8 h-8" @click="zoomLevel > 0.10 ? zoomLevel -= 0.10 : zoomLevel = 0.01"> - </Button>
      <Slider v-model="range" :min="0.01" :max="0.80" :step="0.01" @value-commit="commitZoomLevel" class="bg-company-primary rounded cursor-pointer" />
      <Button variant="outline" size="sm" class="text-xl w-8 h-8" @click="zoomLevel < 0.70 ? zoomLevel += 0.10 : zoomLevel = 0.80"> + </Button>
    </div>

    <template v-if="amountOfZoomEffects > 1">
      <div class="h-px w-full rounded-full bg-surface-panel-border hidden md:block" />
      <Button variant="outline" size="lg" class="mt-4 shrink-0 hidden md:block" @click="applyToAll"> Apply to all zooms </Button>
    </template>
  </div>
</template>

<style scoped lang="scss"></style>
