import { useEventListener, useRafFn } from '@vueuse/core'
import { ref, onMounted, watch, onBeforeUnmount, type Ref, onUnmounted, computed } from 'vue'
import { WorkerRenderer } from '@/modules/SLVideoplayer/canvas/WorkerRenderer'
import { useVideoStore } from '@/areas/editor/store/useVideoStore'
import { debounce } from 'lodash-es'
import { useRendererData } from '@/areas/editor/store/useRendererData'

const renderer = ref<WorkerRenderer | null>(null)
const canvas = computed(() => renderer.value?.view ?? null)

export function useCreateRenderer(
  container: Ref<HTMLElement | null>,
  containerWidth: Ref<number>, containerHeight: Ref<number>
) {

  const videoStore = useVideoStore()

  const resizeToVideo = debounce(() => {
    if (renderer.value && container.value) {
      renderer.value.resizeTo(container.value)
    }
  }, 100)

  const { rendererCrops } = useRendererData()
  onMounted(() => {

    if (!container.value) {
      throw new Error('Video container not found')
    }
    
    renderer.value = new WorkerRenderer(containerWidth.value, containerHeight.value)
    renderer.value.setBackground(videoStore.canvas!)
    renderer.value.setCrops(rendererCrops, videoStore.videoElement!, videoStore.canvas!)
    renderer.value.setAudio(videoStore.audioElement!)
    renderer.value.mixAudio()
    resizeToVideo()
    container.value.appendChild(renderer.value.view)
  })

  useRafFn(() => {
    if (renderer.value && videoStore.videoElement) {
      renderer.value.syncAudioToVideo(videoStore.videoElement);
    }
  });

  watch([containerWidth, containerHeight], resizeToVideo)

  onBeforeUnmount(() => {
    if (renderer.value) {
      renderer.value.destroy()
    }
  })

  useEventListener(videoStore.videoElement, 'canplay', () => {
    if (renderer.value) {
      renderer.value.ticker.update()
    }
  })

  useEventListener(videoStore.videoElement, 'loadeddata', resizeToVideo)

  useEventListener(videoStore.videoElement, 'play', () => {
    if (renderer.value) {
      renderer.value.ticker.start()
    }
  })

  useEventListener(videoStore.videoElement, 'pause', () => {
    if (renderer.value) {
      renderer.value.ticker.pause()
    }
  })
  
  onUnmounted(() => {
    if (renderer.value) {
      renderer.value.destroy()
      renderer.value = null
    }
  })
}

export function usePreviewCanvas() {
  return { canvas, renderer }
}
