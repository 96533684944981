/* eslint-disable no-undef */
import { MP4Demuxer } from './demuxer-mp4';
import * as Mp4Muxer from 'mp4-muxer';
import { CaptionBase } from './captions/caption-base';
import { drawBlurShader } from './blur-shader';
import { noop } from 'ts-essentials';
import { chunk, clamp, sortBy } from 'lodash-es';
import WebpRenderer from '../webp-renderer';
import GifRenderer from '../gif-renderer';
import { AudioEncoderConfig, getEncoderConfig } from './get-encoder-config';
import { getBlackAreas } from './getBlackAreas';
import { resampleAudio } from './audio-utils';
import { audioBufferToWav } from '@/lib/float32AudioToWav'


const isInWorker = typeof WorkerGlobalScope !== 'undefined' && self instanceof WorkerGlobalScope;

const decodeImagePromises = {};

function getChromeVersion() {     
    const raw = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);

    return raw ? parseInt(raw[2], 10) : false;
}

const sampleRate = 44100;

export const VideoRenderer = () => {

    let ctx = null;
    let canvas = null;
    let startTime = null;
    let encodeStartTime = null;
    let myAudioTimeStamp = 0;
    let encodecFrameCount = 0;
    let decodedFrameCount = 0;
    let encoder, videoDecoder, fileWritableStream, muxer, demuxer;
    let demuxFinished = false;
    let totalDecodeChunks = 0;
    let totalEncodedChunks = 0;
    let options = {};
    let videoTrackId, audioTrackId;
    const eventHandlers = {};
    let previewContext = null;

    let audioEncoder;

    let outputFrameDuration = 0;
    let fps = 0;

    let videoDone = false;
    let audioDone = false;

    let videoDecoderConfig = null;

    let videoDecoderConfigureResolve = noop;
    const videoDecoderConfiguredPromise = new Promise((resolve) => {
        videoDecoderConfigureResolve = resolve;
    })

    let outputVideoDuration = 0;
    let blurAreas = [];

    let waitForDecodePromise = null;
    let waitForDecodeResolve = null;
    let configureResolve = noop;
    const configuredPromise = new Promise((resolve) => {
        configureResolve = resolve;
    })

    let transcodeAudioResolve = noop;
    const transcodeAudioPromise = new Promise(resolve => {
        transcodeAudioResolve = resolve;
    })

    const decodeAudioPromises = new Map();

    const WATER_MARK_URL = '/images/watermark.png';
    let watermarkPromiseResolve = noop;
    let watermarkPromise = null;
    let watermarkBitmap = null;
    const watermarkPositions = [
        { x: .05, y: .05 },
        { x: .35, y: .05 },
        { x: .05, y: .85 },
        { x: .35, y: .85 },
    ];

    const giphyRenderers = {};
    const outputChunks = [];

    let errorState = false;
    let closed = false;

    const postMessage = isInWorker ? self.postMessage : (data) => { eventHandlers.message?.({ data }) }


    let encoderConfig = null;

    const isSafariBasedBrowser = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    const isAppleDevice = navigator.userAgent.includes("Mac");

    const TRANSCODE_AUDIO = !!(globalThis.AudioEncoder)

    const MAX_QUEUE_SIZE = 1;
    const MAX_DECODE_QUEUE_SIZE = 20;
    // const isFireFox = navigator.userAgent.search("Firefox");

    // need to use h265, because of a bug in safari that has not released yet https://bugs.webkit.org/show_bug.cgi?id=264893
    const USE_HEVC = isSafariBasedBrowser;


    let captionRenderer = null;

    const audioCache = {};


    function setStatus(status, str) {
        console.log('status', status, str);
    }

    function onError(error) {
        postMessage({ type: 'error', log: error });

        errorState = true;
        demuxer.stopFeeding = true;
    }

    async function loadFonts(fonts) {

        for (const font of fonts) {
            const myFont = new FontFace(
                font.label,
                font.url
            );
            const floadedFont = await myFont.load();
            (self.fonts ?? document.fonts).add(floadedFont);
        }


        if (!isAppleDevice) {
            try {
                const myEmojiFont = new FontFace(
                    "AppleColorEmoji",
                    "url('https://cdn-static.clipgoat.com/emojis/AppleColorEmoji-v17-4.ttf')"
                )

                const emojiFont = await myEmojiFont.load();
                (self.fonts ?? document.fonts).add(emojiFont);
            } catch(e) {
                console.warn(e);
            }
        }
    }

    function clearCanvas() {
        ctx.fillStyle = "black";
        ctx.fillRect(0, 0, canvas.width, canvas.height);
    }

    function renderVideoFrame(frame, imageLike, timestamp, frameWidth, frameHeight) {

        if (frame.type === 'background' && !blurAreas.length) {
            return;
        }

        ctx.textAlign = "center";

        let drawImage = imageLike;

        if (frame.width) {
            // debugger;
        }

        let blurredWidth = frameWidth;
        let blurredHeight = frameHeight;

        if (frame.type === 'background') {
            if (!isSafariBasedBrowser) {
                ctx.filter = `blur(${Math.round(6 * (canvas.height / 1080))}px)`;
            } else {
                drawImage = drawBlurShader(imageLike, frameWidth, frameHeight);
                blurredHeight = drawImage.height;
                blurredWidth = drawImage.width;
            }
        }

        const cropData = frame.cropData ?? { x: 0, y: 0, width: 0, height: 0 };
        const feedData = frame.feedData ?? { x: 0, y: 0, width: 0, height: 0 };
        const zoom = frame.feedData?.effects?.find(eff => eff.type === 'zoom');
        const rounded = frame.feedData?.effects?.find(eff => eff.type === 'rounded');

        if (frame.type === 'background') {
            if (!isSafariBasedBrowser) {
                for (const blurArea of blurAreas) {

                    const scale = Math.min(blurredWidth / canvas.width, blurredHeight / canvas.height);

                    const width = canvas.width * scale;
                    const height = canvas.height * scale;
                    const x = 0.5 * blurredWidth - 0.5 * width;
                    const y = 0.5 * blurredHeight - 0.5 * height;

                    const sourceX = x + blurArea.x * width;
                    const sourceY = y + blurArea.y * height;

                    const sourceWidth = blurArea.width * width;
                    const sourceHeight = blurArea.height * height;

                    const destinationX = blurArea.x * canvas.width;
                    const destinationY = blurArea.y * canvas.height;
                    const destinationWidth = blurArea.width * canvas.width;
                    const destinationHeight = blurArea.height * canvas.height;

                    ctx.drawImage(drawImage, 
                        sourceX, sourceY, sourceWidth, sourceHeight,
                        destinationX, destinationY, destinationWidth, destinationHeight);
                }
            }
            else {

                const scale = Math.min(blurredWidth / canvas.width, blurredHeight / canvas.height);
                
                const width = canvas.width * scale;
                const height = canvas.height * scale;
                const x = 0.5 * blurredWidth - 0.5 * width;
                const y = 0.5 * blurredHeight - 0.5 * height;
                
                ctx.drawImage(drawImage, x, y, width, height, 0, 0, canvas.width, canvas.height);
            }
        }
        else if (zoom) {

            const cropX = cropData.x * frameWidth;
            const cropY = cropData.y * frameHeight;
            const cropWidth = cropData.width * frameWidth;
            const cropHeight = cropData.height * frameHeight;

            const zoomX = zoom.x * cropWidth;
            const zoomY = zoom.y * cropHeight;
            const zoomWidth = zoom.width * cropWidth;
            const zoomHeight = zoom.height * cropHeight;

            const sx = cropX + zoomX;
            const sy = cropY + zoomY;
            const sWidth = zoomWidth;
            const sHeight = zoomHeight;

            const dx = canvas.width * feedData.x;
            const dy = canvas.height * feedData.y;
            const dWidth = canvas.width * feedData.width;
            const dHeight = canvas.height * feedData.height;

            if (rounded) {
                ctx.save()
                ctx.beginPath()
                ctx.arc(dx + dWidth / 2, dy + dWidth / 2, dWidth / 2, 0, Math.PI * 2, false)

                ctx.clip();
            }

            ctx.drawImage(drawImage, sx, sy, sWidth, sHeight, dx, dy, dWidth, dHeight);
        }
        else {

            const sx = cropData.x * frameWidth;
            const sy = cropData.y * frameHeight;
            const sWidth = frameWidth * cropData.width;
            const sHeight = frameHeight * cropData.height;
            const dx = canvas.width * feedData.x;
            const dy = canvas.height * feedData.y;
            const dWidth = canvas.width * feedData.width;
            const dHeight = canvas.height * feedData.height;

            if (frame.feedData?.effects?.find(eff => eff.type === 'rounded')) {
                ctx.save()
                ctx.beginPath()
                ctx.arc(dx + dWidth / 2, dy + dWidth / 2, dWidth / 2, 0, Math.PI * 2, false)

                ctx.clip();
            }

            ctx.drawImage(drawImage, sx, sy, sWidth, sHeight, dx, dy, dWidth, dHeight);
        }

        /** @type {VideoEffect[]} */
        const effects = frame?.effect ?? [];

        /** @type {ZoomEffect[]} */
        const zooms = effects.filter(e => e.type === 'zoom');
        for (const zoom of zooms) {
            // if (timestamp / 1e3 > zoom.startMs && timestamp / 1e3 <= zoom.endMs) {
            //
            //     const alpha = Math.min((timestamp - zoom.startMs * 1e3) / 1e6 * 4, 1.1);
            //
            //     let motionBlur = canvas;
            //     if (alpha <= 1.0) {
            //         motionBlur = drawMotionBlur(canvas, canvas.width, canvas.height, Math.min(.02, alpha * .02), [zoom.x + 0.5 * zoom.width, zoom.y + 0.5 * zoom.height]);
            //     }
            //
            //     const x = interpolate(0, zoom.x * motionBlur.width, alpha);
            //     const y = interpolate(0, zoom.y * motionBlur.height, alpha);
            //     const width = interpolate(motionBlur.width, zoom.width * motionBlur.width, alpha);
            //     const height = interpolate(motionBlur.height, zoom.height * motionBlur.height, alpha);
            //
            //     ctx.drawImage(motionBlur, x, y, width, height, 0, 0, canvas.width, canvas.height);
            // }
        }

        ctx.filter = "none";

        ctx.restore();
    }

    function interpolate(start, end, alpha) {
        return start + (alpha * (end-start));
    }


    async function createGiphyRenderers() {
        for (const sticker of sortBy((options.socialStickers ?? []), s => s.ref.z, 'asc')) {
            if (sticker.ref.type === 'giphy') {
                let giphyRenderer = giphyRenderers[sticker.ref.id];
                if (!giphyRenderer) {
                    giphyRenderer = new WebpRenderer(ctx, sticker.ref.imageUrl ?? sticker.ref.webpUrl, options.live);
                    giphyRenderers[sticker.ref.id] = giphyRenderer;
                    await giphyRenderer.initialize();
                }
            }
            if (sticker.ref.type === 'custom') {
                let gifRenderer = giphyRenderers[sticker.ref.id];
                if (!gifRenderer) {
                    gifRenderer = new GifRenderer(ctx, sticker.ref.imageUrl, sticker.imageBitmap, options.live);
                    giphyRenderers[sticker.ref.id] = gifRenderer;
                    await gifRenderer.initialize();
                }
            }
        }
    }

    function renderCaptions(timestamp) {
        // render the stickers
        for (const sticker of sortBy((options.socialStickers ?? []), s => s.ref.z, 'asc')) {

            if (options.live && sticker.ref.editing) {
                continue;
            }

            if (sticker.ref.type === 'giphy') {
                let giphyRenderer = giphyRenderers[sticker.ref.id];
                if (!giphyRenderer) {
                    giphyRenderer = new WebpRenderer(ctx, sticker.ref.imageUrl, options.live);
                    giphyRenderers[sticker.ref.id] = giphyRenderer;
                }

                giphyRenderer?.render(timestamp, sticker.ref.startMs, sticker.ref.endMs, sticker.ref.area);
            }
            else if (sticker.ref.type === 'custom') {
                let gifRenderer = giphyRenderers[sticker.ref.id];
                if (!gifRenderer) {
                    gifRenderer = new GifRenderer(ctx, sticker.ref.imageUrl, sticker.imageBitmap, options.live);
                    giphyRenderers[sticker.ref.id] = gifRenderer;
                }

                gifRenderer?.render(timestamp, sticker.ref.startMs, sticker.ref.endMs, sticker.ref.area);
            }
            else if (sticker.imageBitmap && sticker.ref.startMs <= timestamp / 1e3 && sticker.ref.endMs >= timestamp / 1e3) {

                const padding = 30;
                const scale = sticker.ref.naturalWidth
                    ? sticker.ref.area.width * canvas.width / sticker.ref.naturalWidth
                    : sticker.ref.scale * canvas.width;

                const naturalWidth = sticker.ref.naturalWidth
                  ? sticker.ref.naturalWidth + 2 * padding
                  : (sticker.ref.area.width / scale) * canvas.width + 2 * padding
                const naturalHeight = sticker.ref.naturalHeight
                  ? sticker.ref.naturalHeight + 2 * padding
                  : (sticker.ref.area.height / scale) * canvas.height + 2 * padding

                ctx.save();

                const translateX = canvas.width * (sticker.ref.area.x + sticker.ref.area.width / 2);
                const translateY = canvas.height * (sticker.ref.area.y + sticker.ref.area.height / 2);
                ctx.translate(translateX, translateY);

                // const stickerTimeMs = (timestamp / 1e3 - sticker.ref.startMs) / 1e3;
                // animations('reveal')(ctx, stickerTimeMs * 5.0);

                const width = naturalWidth * scale;
                const height = naturalHeight * scale;

                ctx.drawImage(sticker.imageBitmap,
                  0, 0,
                  sticker.imageBitmap.width, sticker.imageBitmap.height,
                  -0.5 * width, -0.5 * height,
                  width, height);

                ctx.restore();
            }
            // else if ((sticker.ref.type === 'text' || sticker.ref.type === 'brand-kit') && preset) {
            //     const stickerOptions = {
            //         ...options,
            //         captions: [
            //             {
            //                 // ...sticker,
            //                 lines: sticker.ref.textContent.split('\n'),
            //                 words: [],
            //                 start: sticker.ref.startMs,
            //                 end: sticker.ref.endMs,
            //             }
            //         ],
            //         captionStylesSettings: preset,
            //         baseOptions: {
            //             rotate: false,
            //         },
            //         captionSettings: {
            //             ...sticker,
            //             area: {
            //                 ...sticker.ref.area,
            //                 x: sticker.ref.area.x + sticker.ref.area.width / 2,
            //                 y: sticker.ref.area.y + sticker.ref.area.height / 2,
            //             }
            //         },
            //         // isSticker: true,
            //     };
            //     captionRenderer.render(stickerOptions, timestamp);
            // }
        }


        // render the captions
        captionRenderer.render(options, timestamp);


        if (options.renderWatermark && watermarkBitmap) {
            const watermarkWidth = 0.6 * canvas.width;
            const watermarkHeight =  watermarkWidth * (watermarkBitmap.height / watermarkBitmap.width);
            
            const watermarkPosition = watermarkPositions[Math.round(timestamp / 10e6) % watermarkPositions.length];

            ctx.globalAlpha = '0.69';
            ctx.drawImage(watermarkBitmap, 0, 0, watermarkBitmap.width, watermarkBitmap.height, watermarkPosition.x * canvas.width, watermarkPosition.y * canvas.height, watermarkWidth, watermarkHeight);
            ctx.globalAlpha = '1.0';
        }
    }

    function encodeCanvas(timestamp) {

        const frame = new VideoFrame(canvas, { timestamp, duration: outputFrameDuration });
        const keyFrame = encodecFrameCount % 120 === 0;

        encoder.encode(frame, { keyFrame });
        frame.close();

        if (encodecFrameCount % 100 === 0) {
            postMessage({ type: 'log', log: 'Decoded video chunks: ' + encodecFrameCount });
            //console.log('Decoded video chunks: ' + encodecFrameCount, encoder.encodeQueueSize);
        }
    }

    function updateOptions(newOptions) {

        if (!watermarkPromise) {
            watermarkPromise = new Promise(resolve => {
                watermarkPromiseResolve = resolve;
            });
            postMessage({ type: 'decode-image', url: WATER_MARK_URL });
        }

        options = { ...options, ...newOptions };
    }

    async function initialize(currentOptions) {

        // console.log('Init worker video renderer', currentOptions);
        options = currentOptions;

        canvas = currentOptions.canvas;
        ctx = canvas.getContext('2d');

        if (!options.fonts || options.fonts.length === 0) {
            options.fonts = [{
                label: "Sovereign",
                lineHeightFactor: 1.25,
                url : "url('/fonts/Sovereign/Sovereign.ttf')"
            }];
        }

        if (options.renderWatermark) {
            watermarkPromise = new Promise(resolve => {
                watermarkPromiseResolve = resolve;
            });
            postMessage({ type: 'decode-image', url: WATER_MARK_URL });
        }

        if (options.live || isSafariBasedBrowser) {
            blurAreas = [{  x: 0, y: 0, width: 1, height: 1 }];
        }

        if (!options.live) {
            await loadFonts(options.fonts);
        }
        else {
            loadFonts(options.fonts);
        }
        await createGiphyRenderers();

        captionRenderer = new CaptionBase(canvas, ctx);


        if (options.renderWatermark) {
            await watermarkPromise;
        }

        postMessage({ type: 'initialized' });
    }


    function seekAndPlay(time) {
        console.log('SEEK ', time)
        demuxer.seek(time);
    }

    async function waitForVideoDecoder() {

        let diff = totalDecodeChunks - decodedFrameCount;
        if (videoDecoder.decodeQueueSize > MAX_DECODE_QUEUE_SIZE || diff > 60) {

            waitForDecodePromise = new Promise(resolve => {
                waitForDecodeResolve = resolve;
            })

            const waitForDiffPromise = async () => {
                while(diff > 60) {
                    await new Promise(r => setTimeout(r, 150));
                    diff = totalDecodeChunks - decodedFrameCount;
                }
            };

            await Promise.race([waitForDecodePromise, waitForDiffPromise()]);
            diff = totalDecodeChunks - decodedFrameCount;
        }
    }

    async function flushIfneeded() {
        if (videoDecoder.decodeQueueSize > MAX_DECODE_QUEUE_SIZE || encoder.encodeQueueSize > MAX_QUEUE_SIZE) {
            await encoder.flush();
        }
    }

    let frameRelativeTimeStamp = 0;


    let rotateFrameCanvas = null;
    let rotateFrameCtx = null;

    function handleDecodedFrame(frame) {
        let drawFrame = frame;
        // let displayWidth = frame.displayWidth;
        // let displayHeight = frame.displayHeight;


        if (rotateFrameCanvas == null) {

            if (demuxer?.orientation && demuxer?.orientation !== 180) {
                rotateFrameCanvas = new OffscreenCanvas(frame.displayHeight, frame.displayWidth);
            }
            else {
                rotateFrameCanvas = new OffscreenCanvas(frame.displayWidth, frame.displayHeight);
            }
            rotateFrameCtx = rotateFrameCanvas.getContext('2d');
        }


        let chunkIsInClip = false;
        let clip = null;

        let startOffset = (options.startTimeOffset ?? 0);

        if (demuxer.media_time) {
            startOffset -= demuxer.media_time * 1e3;
        }

        const frameTimeStamp = frame.timestamp + startOffset * 1e3;

        for (const videoFrame of options.frames) {
            if (videoFrame.startMs != null && videoFrame.endMs != null) {
                if (frameTimeStamp >= videoFrame.startMs * 1e3 && frameTimeStamp <= videoFrame.endMs * 1e3) {
                    chunkIsInClip = true;
                    clip = videoFrame;
                    break;
                }
            }
        }

        if (chunkIsInClip) {
            const displayWidth = rotateFrameCanvas.width;
            const displayHeight = rotateFrameCanvas.height;

            if (demuxer?.orientation) {
                rotateFrameCtx.save();
                if (demuxer?.orientation === 270) {
                    rotateFrameCtx.translate(0, rotateFrameCanvas.height);
                } else {
                    if (demuxer?.orientation === 180) {
                        rotateFrameCtx.translate(0, rotateFrameCanvas.height);
                    }

                    rotateFrameCtx.translate(rotateFrameCanvas.width, 0);
                }

                rotateFrameCtx.rotate(demuxer.orientation * Math.PI / 180);

                if (demuxer?.orientation === 270 || demuxer?.orientation === 90) {
                    rotateFrameCtx.drawImage(frame, 0, 0, rotateFrameCanvas.height, rotateFrameCanvas.width);
                }
                else {
                    rotateFrameCtx.drawImage(frame, 0, 0, rotateFrameCanvas.width, rotateFrameCanvas.height);
                }
                rotateFrameCtx.restore();
            }
            else {
                rotateFrameCtx.drawImage(frame, 0, 0, rotateFrameCanvas.width, rotateFrameCanvas.height);
            }

            drawFrame = rotateFrameCanvas;

            let myTimeStamp = encodecFrameCount * outputFrameDuration;
            // const frameRelativeTimeStamp = (frameTimeStamp - startMs * 1e3 + prevFrameEnd * 1e3);
            // frameRelativeTimeStamp += frame.duration;

            if (clip.prevFrameTimeStamp != null) {
                frameRelativeTimeStamp += Math.max(0, frame.timestamp - clip.prevFrameTimeStamp);
            }
            else {
                frameRelativeTimeStamp += frame.duration;
            }
            clip.prevFrameTimeStamp = frame.timestamp;

            const timeOverflow = frameRelativeTimeStamp - myTimeStamp;


            const framesNeeded = Math.max(0, Math.ceil(timeOverflow / outputFrameDuration));
            for (let frameIx = 0; frameIx < framesNeeded; frameIx++) {
                clearCanvas();
                for (const sourceFrame of options.frames) {
                    if (sourceFrame.startMs == null || (frameTimeStamp >= sourceFrame.startMs * 1e3 && frameTimeStamp <= sourceFrame.endMs * 1e3)) {
                        renderVideoFrame(sourceFrame, drawFrame, frameTimeStamp, displayWidth, displayHeight);
                    }
                }

                renderCaptions(frameTimeStamp);

                myTimeStamp = Math.round(encodecFrameCount * outputFrameDuration);
                encodeCanvas(myTimeStamp);

                if (clip.minOutTimeStamp == null) clip.minOutTimeStamp = myTimeStamp;
                if (clip.maxOutTimeStamp == null) clip.maxOutTimeStamp = myTimeStamp;

                if (clip.minTimeStamp == null) clip.minTimeStamp = frameTimeStamp;
                if (clip.maxTimeStamp == null) clip.maxTimeStamp = frameTimeStamp;


                clip.minOutTimeStamp = Math.min(myTimeStamp, clip.minOutTimeStamp);
                clip.maxOutTimeStamp = Math.max(myTimeStamp, clip.maxOutTimeStamp);

                clip.minTimeStamp = Math.min(frameTimeStamp, clip.minTimeStamp);
                clip.maxTimeStamp = Math.max(frameTimeStamp, clip.maxTimeStamp);

                encodecFrameCount++;
            }

        }

        frame.close();

        if (options.previewCanvas) {
            if (!previewContext) previewContext = options.previewCanvas.getContext('2d');
            previewContext.drawImage(canvas, 0, 0, canvas.width, canvas.height, 0, 0, options.previewCanvas.width, options.previewCanvas.height);
        }
    }


    function handleEncodedAudioChunk(chunk, timestamp) {

        let startOffset = (options.startTimeOffset ?? 0);

        if (demuxer.audio_media_time) {
            startOffset -= demuxer.audio_media_time * 1e3;
        }


        let chunkIsInClip = false;
        let clip = null;

        for (const videoFrame of options.frames) {
            if (videoFrame.startMs != null && videoFrame.endMs != null) {
                if (timestamp >= (videoFrame.startMs - startOffset) * 1e3 && timestamp <= (videoFrame.endMs - startOffset) * 1e3) {
                    chunkIsInClip = true;
                    clip = videoFrame;
                    break;
                }
            }
        }

        if (!chunkIsInClip) return;

        if (!clip.firstAudioProcessed) {
            clip.firstAudioProcessed = true;
            if (clip.minOutTimeStamp != null) {
                if (myAudioTimeStamp > clip.minOutTimeStamp) {
                    console.warn('Audio overflow')
                }
                else {
                    myAudioTimeStamp = clip.minOutTimeStamp;
                }
                console.log(clip, { myAudioTimeStamp });
            }
        }

        muxer.addAudioChunk(chunk, null, myAudioTimeStamp);
        myAudioTimeStamp += chunk.duration;
    }

    async function start(currentOptions, webcodecTestResults) {

        console.log('Start called in worker')
        options = currentOptions;
        await initialize(options);
        startTime = performance.now();

        console.log('Start from worker ', options);
        postMessage({ type: 'log', log: 'Start from worker' });

        if (options.fileHandle) {
            fileWritableStream = await options.fileHandle.createWritable();
        }

        const mergedIntervals = [];
        let currentInterval = { ...options.frames[1] };
        let startMs = Infinity;
        let endMs = 0;

        for (let i = 2; i < options.frames.length; i++) {

            startMs = Math.min(startMs, options.frames[i].startMs);
            endMs = Math.max(endMs, options.frames[i].endMs);

            if (options.frames[i].startMs <= currentInterval.endMs) {
                // Overlapping intervals, merge them
                currentInterval.endMs = Math.max(currentInterval.endMs, options.frames[i].endMs);
            } else {
                // No overlap, push the current interval and update the current interval
                mergedIntervals.push(currentInterval);
                currentInterval = { ...options.frames[i] };
            }
        }

        mergedIntervals.push(currentInterval);

        clearCanvas();

        ctx.fillStyle = 'red';
        for (const frame of options.frames) {
            // the start and end time check is a patch for videos that have zoom.
            // maybe it better to disable blur on that parts in the video
            if (frame.type !== 'background' && frame.startMs === startMs && frame.endMs === endMs && !(frame.cropData?.feedData?.effects?.find(effect => effect.type === 'rounded'))) {
                ctx.fillRect(canvas.width * frame.feedData.x, canvas.height * frame.feedData.y, canvas.width * frame.feedData.width, canvas.height * frame.feedData.height);
            }
        }

        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);

        if (!isSafariBasedBrowser) {
            console.time('blurAreas')
            blurAreas = getBlackAreas(imageData);
            console.timeEnd('blurAreas')
        }

        outputVideoDuration = 0;
        for (const interval of mergedIntervals) {
            outputVideoDuration += interval.endMs - interval.startMs;
        }

        encoderConfig = getEncoderConfig(canvas.width, canvas.height);

        if ( webcodecTestResults && !webcodecTestResults.hardwareAccelerationWorking) {
            encoderConfig.hardwareAcceleration = 'prefer-software';
        }

        const inputAudio = await decodeAudioInMainThread(options.videoInfo.videoUrl);
        // we have downloaded the video locally, no need to fetch it again
        if (inputAudio.blobUrl && !options.isFallbackRender) {
            options.videoInfo.videoUrl = inputAudio.blobUrl;
        }

        // const backgroundMusic = await decodeAudioInMainThread('/audio-effects/songs/Cyberpunk Gaming Rave by Infraction [No Copyright Music]  Black Ice.mp3')
        // const effect = await decodeAudioInMainThread('/audio-effects/memesounds/hawk-tuah-short.mp3')
        // const audioToMix = [
        //     // {
        //     //     data: backgroundMusic,

        //     //     targetStart: 0,
                
        //     //     sourceStart: 0,
        //     //     sourceEnd: -1,

        //     //     loop: true,
        //     //     volume: .1,
        //     // }, {
        //     //     data: effect,
        //     //     targetStart: 2,

        //     //     sourceStart: 0,
        //     //     sourceEnd: -1,

        //     //     loop: true,
        //     //     volume: .7
        //     // }
        // ];

        const audioToMix = []

        if (options.effects?.length) {
            for (const effect of options.effects) {
                if (effect.type === 'sound') {
                    audioToMix.push(await getAudioMixObjectFromEffect(effect));
                }
            }
        }

        transcodeAudio(inputAudio, audioToMix);

        videoDecoder = new VideoDecoder({
            output(currentFrame) {
                if (errorState) return;
                decodedFrameCount++;
                outputChunks.push(currentFrame);

                if (outputChunks.length > 100 || !isAppleDevice) {
                    outputChunks.sort((a,b) => b.timestamp - a.timestamp);
                    const frame = outputChunks.pop();
                    handleDecodedFrame(frame);
                }

                if (currentFrame.timestamp === demuxer.endSampleVideo.timestamp) {
                    outputChunks.sort((a,b) => b.timestamp - a.timestamp);

                    while(outputChunks.length > 0) {
                        const frame = outputChunks.pop();
                        handleDecodedFrame(frame);
                    }
                }

            },
            error(e) {
                onError('Video decoder error ' + e.message );
                setStatus("decode", e);
            }
        });

        videoDecoder.addEventListener('dequeue', () => {
            if (waitForDecodeResolve) waitForDecodeResolve();
        });

        let waitForKeyFrame = true;

        // patch for old proxy
        if (options.videoInfo.videoUrl.includes('streamladder-twitchclipimporter.fly.dev')) {
            const videoUrl = atob(options.videoInfo.videoUrl.split('?url=')[1]);
            options.videoInfo.videoUrl = videoUrl.replace('production.assets.clips.twitchcdn.net', `twitch-clips-v2.b-cdn.net`)
        }

        demuxer = new MP4Demuxer(options.videoInfo.videoUrl, {
            clipTimeStamps: options.frames.map(f => ({ start: f.startMs * 1e3, end: f.endMs * 1e3 })),
            startTime: options.clipInfo.start / 1e3 - (options.startTimeOffset ?? 0) / 1e3,
            endTime: options.clipInfo.end / 1e3 - (options.startTimeOffset ?? 0) / 1e3,
            onError: onError,
            onConfig(config) {

                videoTrackId = config.videoConfig.id;

                setStatus("decode", `${config.videoConfig.codec} @ ${config.videoConfig.codedWidth}x${config.videoConfig.codedHeight}`);

                fps = options.outputFPS ?? 60.0;

                const hasStickers = !!options?.socialStickers?.length;
                const hasCaptions = !!options?.captions?.length;
                if (!hasCaptions && !hasStickers && demuxer.fps && demuxer.fps < fps) {
                    console.log(`No captions and no stickers, fps of ${demuxer.fps} used instead of ${fps}`)
                    fps = demuxer.fps;
                }

                outputFrameDuration = 1e6 / fps;


                // use hardware acceleration for decoder if possible.
                config.videoConfig.hardwareAcceleration = encoderConfig.hardwareAcceleration;

                videoDecoderConfig = {
                    ...config.videoConfig,
                    optimizeForLatency: true
                };

                const configureDecoder = async () => {
                    const decoderSupport = await VideoDecoder.isConfigSupported(videoDecoderConfig);
                    if (!decoderSupport.supported) {
                        videoDecoderConfig.hardwareAcceleration = 'prefer-software';
                    }
                    // fix the dull colors on macos only for chrome 130 where a bug with mising dropped frames is fixed
                    if (isAppleDevice && getChromeVersion() >= 130) {
                        videoDecoderConfig.hardwareAcceleration = 'prefer-software';
                    }
                    console.log(videoDecoderConfig, decoderSupport);
                    videoDecoder.configure(videoDecoderConfig);
                    videoDecoderConfigureResolve();
                }
                configureDecoder();

                if (config.audioConfig) {
                    // audio decoder..
                    audioTrackId = config.audioConfig.id;

                    if (TRANSCODE_AUDIO) {
                        // audioDecoder.configure(config.audioConfig);
                    }
                    else {
                        const supportedAAC = ['mp4a.40.2', 'mp4a.40.5']
                        if (!supportedAAC.includes(config.audioConfig.codec)) {
                            const message = `Audio codec ${config.audioConfig.codec} not supported yet`;
                            onError(message)
                            throw new Error(message);
                        }
                    }
                }

                let target = null;

                if (fileWritableStream) {
                    target = new Mp4Muxer.FileSystemWritableFileStreamTarget(fileWritableStream);
                } else {
                    target = new Mp4Muxer.ArrayBufferTarget();
                }

                if (!options.live) {
                    const audioChannels = Number(config.audioConfig?.numberOfChannels);
                    muxer = new Mp4Muxer.Muxer({
                        target,
                        video: {
                            codec: USE_HEVC ? 'hevc' : 'avc',
                            width: canvas.width,
                            height: canvas.height,
                        },
                        audio: {
                            codec: AudioEncoderConfig.codec === 'opus' ? 'opus' : 'aac',
                            numberOfChannels: 2,
                            sampleRate: TRANSCODE_AUDIO ? 44100 : config.audioConfig?.sampleRate,
                        },
                        firstTimestampBehavior: 'offset',
                        fastStart: 'in-memory',
                    });

                    demuxer.start();
                }
            },
            async onChunk(sample, timestamp, duration, trackId) {
                const type = sample.is_sync ? "key" : "delta";

                if (errorState) return;

                let startOffset = (options.startTimeOffset ?? 0);

                if (trackId === videoTrackId && demuxer.media_time) {
                    startOffset -= demuxer.media_time * 1e3;
                }

                if (trackId === audioTrackId && demuxer.audio_media_time) {
                    startOffset -= demuxer.audio_media_time * 1e3;
                }

                await configuredPromise;
                try {

                    // disabled because we need more frames to buffer the timestamps that are not in order
                    // fixes blurry frames at the end
                    const chunkIsAfterClip =  false; //timestamp > (options.clipInfo.end - startOffset) * 1e3 + 3e3 + startOffset * 1e3;
                    let chunkIsInClip = false;
                    let clip = null;

                    for (const videoFrame of options.frames) {
                        if (videoFrame.startMs != null && videoFrame.endMs != null) {
                            if (timestamp >= (videoFrame.startMs - startOffset) * 1e3 && timestamp <= (videoFrame.endMs - startOffset) * 1e3) {
                                chunkIsInClip = true;
                                clip = videoFrame;
                                break;
                            }
                        }
                    }

                    if (chunkIsAfterClip) {
                        if (trackId === videoTrackId) {
                            videoDone = true;
                        } else {
                            audioDone = true;
                        }

                        if (videoDone && audioDone) {
                            demuxer.stopFeeding = true;
                        }
                        return;
                    }


                    await videoDecoderConfiguredPromise;
                    await waitForVideoDecoder();

                    await flushIfneeded();

                    if (trackId === videoTrackId) {
                        if (!chunkIsInClip) {

                            const preBufferSeconds = 12.0;

                            if (type === 'key') {
                                totalDecodeChunks++;

                                const chunk = new EncodedVideoChunk({
                                    type: type,
                                    timestamp: timestamp,
                                    duration: duration,
                                    data: sample.data
                                })

                                videoDecoder.decode(chunk);
                            }

                            if (timestamp >= ((options.clipInfo.start - startOffset) - preBufferSeconds * 1e3) * 1e3) {
                                if (type === 'key') {
                                    waitForKeyFrame = false;
                                }
                                else if (!waitForKeyFrame){
                                    totalDecodeChunks++;
                                    const chunk = new EncodedVideoChunk({
                                        type: type,
                                        timestamp: timestamp,
                                        duration: duration,
                                        data: sample.data
                                    })
                                    videoDecoder.decode(chunk);
                                }
                            }


                        } else {

                            if (totalDecodeChunks % 100 === 0) {
                                // console.log('Added chunks to decoder: ' + totalDecodeChunks, videoDecoder.decodeQueueSize);
                                postMessage({ type: 'log', log: 'Added chunks to decoder: ' + totalDecodeChunks });
                            }

                            if (!waitForKeyFrame || type === 'key') {
                                totalDecodeChunks++;
                                const chunk = new EncodedVideoChunk({
                                    type: type,
                                    timestamp: timestamp,
                                    duration: duration,
                                    data: sample.data
                                })

                                videoDecoder.decode(chunk);
                            }

                            if (type === 'key') {
                                waitForKeyFrame = false;
                            }
                        }
                    }


                    if (trackId === audioTrackId && chunkIsInClip) {


                        if (!TRANSCODE_AUDIO) {
                            const ChunkClass = EncodedAudioChunk ?? EncodedVideoChunk;
                            const chunk = new ChunkClass({
                                type: type,
                                timestamp: myAudioTimeStamp,
                                duration: duration,
                                data: sample.data
                            })

                            handleEncodedAudioChunk(chunk, timestamp)
                        }

                        muxer.addAudioChunk(chunk, null, myAudioTimeStamp);
                        myAudioTimeStamp += chunk.duration;
                        return;
                    }
                }
                catch(e) {
                    console.error(e);
                    onError('Video decoder error ' + e.message );
                }
            },
            setStatus,
            onFinish: () => {
                console.log('Demux finished!')
                postMessage({ type: 'log', log: 'Demux finished!' });
                demuxFinished = true;
            },
            onFinishProgressing: () => {
                if (demuxFinished) {
                    postMessage({ type: 'log', log: 'On finalize called' });
                    const clipDuration = (options.clipInfo.end - options.clipInfo.start) / 1000;
                    console.log({ totalEncodedChunks, totalDecodeChunks });
                    finalize();
                    console.log('----------- Done ------------')
                    const elapsed = (performance.now() - startTime) / 1000;
                    console.log('time: ' + elapsed.toFixed(2) + 's')
                    console.log('speed: ' + (clipDuration / elapsed).toFixed(2) + 'x');
                }
            }
        })

        demuxer.transcodeAudio = TRANSCODE_AUDIO;
        demuxer.isFallbackRender = options.isFallbackRender;


        if (TRANSCODE_AUDIO) {
            audioEncoder = new AudioEncoder({
                output: (chunk, config) => {
                    if (errorState) return;
                    // muxer.addAudioChunk(chunk, config);
                    handleEncodedAudioChunk(chunk, chunk.timestamp)
                },
                error: (e) => {
                    onError('Audio encoder error ' + e.message );
                    console.error(e.message);
                }
            })
        }

        encoder = new VideoEncoder({
            output: (chunk, config) => {
                if (errorState) return;
                muxer.addVideoChunk(chunk, config);
                totalEncodedChunks++;
                if (encodeStartTime == null) encodeStartTime = performance.now();
                if (totalEncodedChunks % 100 === 0) {
                    const elapsed = (performance.now() - encodeStartTime) / 1000;
                    const fps = totalEncodedChunks / elapsed;
                    console.log('encode fps: ' + fps)
                }
                if (totalEncodedChunks % 30 === 0) {
                    const progress = 1.0 - (outputVideoDuration - chunk.timestamp / 1e3) / outputVideoDuration;

                    postMessage({ type: 'progress', progress, status: '(2/2) Exporting..' });
                }
            },
            error: (e) => {
                onError('Video encoder error ' + e.message );
                console.error(e.message);
            }
        });

        const support = await VideoEncoder.isConfigSupported(encoderConfig);
        let errorConfiguring = false;

        try {
            encoder.configure(encoderConfig);
        }
        catch (e){
            console.warn(e);
            errorConfiguring = true;
        }

        if (!support.supported || errorConfiguring) {
            const message = 'VideoEncoder config not supported. Trying without hardware acceleration';
            postMessage({ type: 'log', log: message});
            console.warn(message);
            encoderConfig.hardwareAcceleration = 'prefer-software';
            encoder.reset();
            encoder.configure(encoderConfig);
        }

        configureResolve();
    }

    function handleMessage(message) {
        if (message.data.type === 'start') {
            start(message.data.options, message.data.webcodecTestResults);
        }
        if (message.data.type === 'initialize') {
            initialize(message.data.options);
        }
        if (message.data.type === 'update-options') {
            updateOptions(message.data.options);
        }
        if (message.data.type === 'seek') {
            seekAndPlay(message.data.time);
        }
        if (message.data.type === 'finalize') {
            finalize();
        }
        if (message.data.type === 'render-frame') {
            clearCanvas();

            let frames = Array.from(message.data.frames.values());
            frames.sort((a, b) => a.zIndex - b.zIndex);

            frames = frames.map(frame => ({
                ...frame,
                type: frame.key || 'background',
                feedData: {
                    ...frame.feedData,
                    width: frame.feedData.w,
                    height: frame.feedData.h,
                    effects: frame.effect,
                },
                cropData: {
                    ...frame.cropData,
                    width: frame.cropData.w,
                    height: frame.cropData.h,
                }
            }))

            const render = () => {
                for (const frame of frames) {
                    try {
                        if (frame.source.width) {
                            renderVideoFrame(frame, frame.source, message.data.timestamp, message.data.width, message.data.height);
                        }
                    } catch (e) {
                        captureOnce(e)
                    }
                }
                renderCaptions(message.data.timestamp);
            }
            render();
        }
        if (message.data.type === 'resize') {
            canvas.width = message.data.width * Math.min(2, window.devicePixelRatio);
            canvas.height = message.data.height * Math.min(2, window.devicePixelRatio);
        }
        if (message.data.type === 'decode-image') {
            decodeImagePromises[message.data.url]?.resolve(message.data.bitmap);
            
            if (message.data.url === WATER_MARK_URL) {
                watermarkBitmap = message.data.bitmap;
                watermarkPromiseResolve(message.data.bitmap);
            }
        }
        if (message.data.type === 'terminate') {
            if (demuxer) demuxer.stopFeeding = true;
            if (closed) return;
            closed = true;

            encoder?.close();
            videoDecoder?.close();

            // console.log('Terminated worker');

            setTimeout(() => {
                if (isInWorker) {close();}
            }, 1000);

        }
        if (message.data.type === 'decoded-audio') {
            const promise = decodeAudioPromises.get(message.data.url);
            if (!promise) console.error('Decode audio promise not found: ', message.data);
            else {
                promise.resolvePromise(message.data.data);
            }
        }
        if (message.data.type === 'create-montage') {
            createMontage(message.data.videoUrls, decodeAudioInMainThread, message.data.useHardwareAcceleration)
        }
        if (message.data.type === 'mix-audio') {
            mixAudioEffects(message.data.data);
        }
    }


    async function getAudioMixObjectFromEffect(effect) {
        const audioData = await decodeAudioOrGetFromCache(effect.url);
        audioData.rawData = audioData.rawData.map(flArray => flArray.slice(0));
        return {
            data: audioData,
            sourceStart: 0,
            sourceEnd: (effect.endMs - effect.startMs) / 1e3,
            targetStart: effect.startMs / 1e3,
            targetEnd: effect.endMs / 1e3,
            volume: effect.volume === undefined ? 0.4 : effect.volume,
            // loop: true,
        }
    }


    async function mixAudioEffects({ videoDuration, audioEffects }) {
        // const mainVideoAudio = await decodeAudioOrGetFromCache(videoUrl);

        // copy clean source audio
        // mainVideoAudio.rawData = mainVideoAudio.rawData.map(flArray => flArray.slice(0));


        const targetLength = Math.ceil(videoDuration * sampleRate);

        const mainVideoAudio = { sampleRate, rawData: [new Float32Array(targetLength), new Float32Array(targetLength)] };
        const audioToMix = [];
        for (const effect of audioEffects) {
            audioToMix.push(await getAudioMixObjectFromEffect(effect))
        }


        mixAudio(mainVideoAudio, audioToMix);

        const wavData = audioBufferToWav(sampleRate, mainVideoAudio.rawData);
        // Step 4: Create a Blob URL and an audio element
        const blob = new Blob([wavData], { type: 'audio/wav' });
        const audioUrl = URL.createObjectURL(blob);

        postMessage({ type: 'mixed-audio', audioData: audioUrl })

    }


    async function decodeAudioOrGetFromCache(url) {
        if (audioCache[url]) return { ...audioCache[url] };

        const audio = await decodeAudioInMainThread(url);
        audioCache[url] = audio;

        return {...audio};
    }

    function mixAudio(data, audioToMix) {
        // fix for more than 2 channels:
        if (data.rawData.length > 2) {
            data.rawData = data.rawData.slice(0, 2);
        }

        const totalSamples = data.rawData[0].length;

        if (data.sampleRate !== sampleRate) {
            console.warn(`Resample audio from ${data.sampleRate} to ${sampleRate}`);
            for (let channelNr = 0; channelNr < data.rawData.length; channelNr++) {
                data.rawData[channelNr] = resampleAudio(data.rawData[channelNr], data.sampleRate, sampleRate);
            }
        }


        console.time('mixAudio')
        // temp fix for mono audio
        if (!data.rawData[1]) data.rawData[1] = data.rawData[0];

        for (const audio of audioToMix) {
            if (audio.data.rawData.length > 2) audio.data.rawData = audio.data.rawData.slice(0, 2);

            if (audio.data.sampleRate !== sampleRate) {
                console.warn(`Resample audio from ${audio.data.sampleRate} to ${sampleRate}`);
                for (let channelNr = 0; channelNr < audio.data.rawData.length; channelNr++) {
                    audio.data.rawData[channelNr] = resampleAudio(audio.data.rawData[channelNr], audio.data.sampleRate, sampleRate);
                }
            }
            if (!audio.data.rawData[1]) audio.data.rawData[1] = audio.data.rawData[0];

            const targetStartSample = Math.round(audio.targetStart * sampleRate);

            const dbMultiplier = Math.pow(10, ((audio.volume ?? 1.0) - 1) * 20 / 20);

            for (let channelNr = 0; channelNr < audio.data.rawData.length; channelNr++) {

                const sourceStartSampleNr = Math.round(audio.sourceStart * audio.data.sampleRate);
                const sourceEndSampleNr = audio.sourceEnd >= 0 ? Math.round(audio.sourceEnd * audio.data.sampleRate) : audio.data.rawData[channelNr].length;

                let sourceSampleNr = sourceStartSampleNr;
                let totalSourceSampleNr = sourceStartSampleNr;

                for (let sampleNr = targetStartSample; sampleNr < totalSamples; sampleNr++) {
                    const sourceSample = audio.data.rawData[channelNr][sourceSampleNr];
                    data.rawData[channelNr][sampleNr] += sourceSample * dbMultiplier;

                    sourceSampleNr++;
                    totalSourceSampleNr++;

                    if (totalSourceSampleNr >= sourceEndSampleNr) {
                        break;
                    }
                    else if (audio.data.rawData[channelNr][sourceSampleNr] == null) {
                        if (audio.loop) {
                            sourceSampleNr = sourceStartSampleNr;
                        } else {
                            break;
                        }
                    }
                }
            }

        }


        console.timeEnd('mixAudio')
    }

    async function transcodeAudio(data, audioToMix) {
        await videoDecoderConfiguredPromise;

        // no audio channels
        if (data.rawData.length === 0) {

            if (demuxer.soundHandler || demuxer.audioTrack) {
                onError('Audio codec not supported. No audio decoded but audio track found.')
                return;
            }

            transcodeAudioResolve();
            return;
        }

        mixAudio(data, audioToMix);
        audioEncoder.configure(AudioEncoderConfig);

        const blockSize = 960; // Opus typically encodes in 20ms frames (960 samples at 48kHz)
        const totalFrames = data.rawData[0].length;
        let frameStart = 0;

        let joinedSamples = new Float32Array(blockSize * data.rawData.length);

        while (frameStart < totalFrames) {
            const frameEnd = Math.min(frameStart + blockSize, totalFrames);

            const joinedSamplesLength = (frameEnd - frameStart) * data.rawData.length;

            if (joinedSamples.length !== joinedSamplesLength) {
                joinedSamples = new Float32Array(joinedSamplesLength);
            }

            let offset = 0;
            for (let channelNr = 0; channelNr < data.rawData.length; channelNr++) {
                joinedSamples.set(data.rawData[channelNr].subarray(frameStart, frameEnd), offset);
                offset += (frameEnd - frameStart);
            }

            // Create an AudioData object for each frame
            // I am not sure why, but 2 * outputFrameDuration correction on the audio makes it almost perfect sync like on the input video. (the difference is not really noticeable)
            const audioData = new AudioData({
                timestamp: (frameStart / sampleRate) * 1e6 - outputFrameDuration * 2,
                data: joinedSamples.buffer, // Interleaved audio data
                format: 'f32-planar',
                sampleRate: sampleRate,
                numberOfFrames: joinedSamples.length / data.rawData.length,
                numberOfChannels: data.rawData.length,
            });

            // Encode the AudioData object
            audioEncoder.encode(audioData);

            audioData.close();

            frameStart = frameEnd;
        }
        await audioEncoder.flush();

        transcodeAudioResolve();
    }


    async function decodeAudioInMainThread(url) {
        let resolvePromise = noop;
        const promise = new Promise(resolve => {
            resolvePromise = resolve;
        })

        decodeAudioPromises.set(url, { promise, resolvePromise });

        postMessage({ type: 'decode-audio', url });


        return await promise;
    }

    async function finalize () {


        if (outputChunks.length > 0) {
            outputChunks.sort((a,b) => b.timestamp - a.timestamp);

            while(outputChunks.length > 0) {
                const frame = outputChunks.pop();
                handleDecodedFrame(frame);
            }
        }

        await new Promise(r => setTimeout(r, 1000));

        await demuxer.flush();


        if (TRANSCODE_AUDIO) {
            if (transcodeAudioPromise) await transcodeAudioPromise;

            // try {
            //     await audioDecoder.flush();
            //     await audioEncoder.flush();
            // }
            // catch(e) {
            //     console.warn(e);
            // }
        }
        try {
            await videoDecoder.flush();
        } catch(e) {
            console.warn(e);
        }
        try {
            await encoder.flush();
        } catch(e) {
            console.warn(e);
        }
        await muxer.finalize();

        demuxer.releaseUsedSamples();

        console.log({ totalEncodedChunks, totalDecodeChunks });

        if (fileWritableStream) await fileWritableStream.close();

        postMessage({ type: 'progress', progress: 1.0, status: '(2/2) Exporting..' });

        const clipDuration = (options.clipInfo.end - options.clipInfo.start) / 1000;
        const elapsed = (performance.now() - encodeStartTime) / 1000;
        const fps = Math.round(totalEncodedChunks / elapsed);
        const speed = (clipDuration / elapsed).toFixed(2) + 'x';

        if (errorState) {
            console.warn('Renderer has error state, not downloading file')
            return;
        }

        if (fileWritableStream) {
            postMessage({ type: 'finished', fps, speed });
        } else {
            postMessage({ type: 'finished', buffer: muxer.target.buffer, fps, speed }, [muxer.target.buffer]);
        }
    }

    // Listen for the start request.
    self.addEventListener('message', message => handleMessage(message));

    return {
        postMessage: (data) => handleMessage({ data }),
        terminate: noop,
        addEventListener: (type, callback) => eventHandlers[type] = callback,
        onerror: noop,
    };
}

import * as Sentry from '@sentry/vue';
import { createMontage } from './create-montage';
const captureOnce = (() => {
    const capturedExceptions = []
    return (e) =>  {
        if (!capturedExceptions.includes(e.message)) {
            capturedExceptions.push(e.message)
            Sentry.captureException(e)
            console.error(e)
        }
    }
})()
