import { useEditorClipInfoStore } from '@/store/editor/editorClipInfo'
import type { StartupConfig } from '@/areas/editor/startup/StartupConfig'
import { handleStartupError } from '@/areas/editor/startup/handleStartupError'
import { throwIfBrowserUnsupported } from '@/areas/editor/startup/UnsupportedBrowserError'
import { publicAxios } from '@/services/axios'
import { prepareStores } from '@/areas/editor/startup/generalStartupMethods'

const kickCxUrl = 'https://clips-api.kick.cx/api/clips/details';

type KickCxClipDto = {
  data: {
    uid: string
    stream_title: string
    thumbnail_link: string
    direct_link_mp4: string
    viewer_count: number
    streamer_username: string
  }
};

export async function startupFromKickCxClipByClipId(clipId: string, config: StartupConfig) {

  const editorClipInfoStore = useEditorClipInfoStore();

  try {
    throwIfBrowserUnsupported()
    editorClipInfoStore.loadingState = {
      state: 'loading',
      description: 'Fetching clip info...'
    }

    const response = await publicAxios.get(`${kickCxUrl}/${clipId}`) as KickCxClipDto;
    config.signal.value?.throwIfAborted();

    editorClipInfoStore.id = response.data.uid;
    editorClipInfoStore.title = response.data.stream_title;
    editorClipInfoStore.viewCount = response.data.viewer_count;
    editorClipInfoStore.mp4Url = response.data.direct_link_mp4;
    editorClipInfoStore.thumbnailUrl = response.data.thumbnail_link;
    editorClipInfoStore.source = 'kick-cx-clip';
    editorClipInfoStore.languageCode = 'en_us';
    editorClipInfoStore.isLocalFile = false;

    return await prepareStores(config);
  } catch (error) {
    return handleStartupError(error)
  }
}