import { ref, computed } from 'vue'
import { retryAsync } from '@/helpers/retry'
import { streamLadderAxiosInstance, publicAxios } from '@/services/axios'
import logging from '@/logging'
import * as Sentry from '@sentry/vue'
import { MD5 } from 'jscrypto/es6/MD5'
import { Word32Array } from 'jscrypto/es6/Word32Array'
import uploadService from '@/services/uploadService'
import Pusher from 'pusher-js'
import type { Sentence, CaptionsDocument } from '@/components/Captions/captionTypes'
import { useEditorCaptionsStore } from '@/store/editor/editorCaptions'
import { useEditorClipInfoStore } from '@/store/editor/editorClipInfo'
import VideoToAudioService from '@/services/videoToAudioService'
import { useSegmentsStore } from '@/areas/editor/store/useSegmentsStore'
import { tryOnMounted } from '@vueuse/core'
import { v4 as uuid } from 'uuid'
import { useVideoStore } from '@/areas/editor/store/useVideoStore'
import { useToast } from '@/Hooks/useToast'
import toastEvents from '@/events/toastEvents'

const isGeneratingCaptions = ref(false);

export function useGenerateCaptions() {
  
  const editorClipInfoStore = useEditorClipInfoStore()
  const editorCaptionsStore = useEditorCaptionsStore()
  const videoStore = useVideoStore()

  const { showToast } = useToast()

  const supportedLocales = [
    { code: 'en_us', label: 'English' }, { code: 'es', label: 'Spanish' }, { code: 'fr', label: 'French' },
    { code: 'de', label: 'German' }, { code: 'it', label: 'Italian' }, { code: 'nl', label: 'Dutch' },
    { code: 'pt', label: 'Portuguese' }, { code: 'hi', label: 'Hindi' }, { code: 'ja', label: 'Japanese' },
    { code: 'zh', label: 'Chinese' }, { code: 'fi', label: 'Finnish' }, { code: 'ko', label: 'Korean' },
    { code: 'pl', label: 'Polish' }, { code: 'ru', label: 'Russian' }, { code: 'tr', label: 'Turkish' },
    { code: 'uk', label: 'Ukrainian' }, { code: 'vi', label: 'Vietnamese' }]

  const captionsLanguageCode = ref<(typeof supportedLocales)[number]['code']>('en_us')
  
  const isOverDurationLimit = computed(() => durationMs.value > 3 * 60 * 1000)

  // Overly lengthy videos and videos without a remotely accessible URL should be trimmed and stored before sending
  // them to the generation API.
  const shouldTrimBeforeGenerating = computed(() => {
    const isLocalFile = editorClipInfoStore.mp4Url.startsWith('blob:')
    return videoStore.durationMs > 3 * 60 * 1000 || isLocalFile
  })

  async function selectFileUrl() {
    if (shouldTrimBeforeGenerating.value) {
      const response = await fetch(editorClipInfoStore.mp4Url)
      if (response.ok) {
        const blob = await response.blob()
        return await extractAndUploadAudioFile(blob)
      } else { 
        console.log(response)
        throw new Error('Failed to fetch video file')
      }
      
    } else {
      return editorClipInfoStore.mp4Url
    }
  }
  
  const segmentsStore = useSegmentsStore()
  const segments = segmentsStore.whereIsNotZoom()

  const startMs = computed(() => Math.min(...segments.value.map(s => s.startMs)))
  const endMs = computed(() => Math.max(...segments.value.map(s => s.endMs)))
  const durationMs = computed(() => endMs.value - startMs.value)

  function generateCaptionsAsync() {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<void>(async (resolve, reject) => {
      try {

        isGeneratingCaptions.value = true

        const fileUrl = await selectFileUrl()

        editorCaptionsStore.selectedLanguage = captionsLanguageCode.value
        editorClipInfoStore.languageCode = captionsLanguageCode.value
        editorCaptionsStore.resetPositionScale()

        localStorage.setItem('caption-language', captionsLanguageCode.value);

        const result = await retryAsync(async () => {
          return await streamLadderAxiosInstance<{ fileName: string; fullFilePath?: string; taskId: string }>(
            {
              url: `/api/Captions/GenerateCaptions`,
              method: 'post',
              headers: { 'Content-Type': 'application/json' },
              data: { fileUrl, languageCode: captionsLanguageCode.value },
            },
          );
        });

        if (!result) {
          isGeneratingCaptions.value = false;
          return;
        }

        logging.trackEvent('Editor Captions Added', {
          language: captionsLanguageCode.value,
          localFile: editorClipInfoStore.isLocalFile,
          style: editorCaptionsStore.captionStyle,
          tags: editorCaptionsStore.captionStyleSettings.tags,
        })

        if (result.fullFilePath) {
          await emitCaptionsFileContent(result.fullFilePath)
        } else {
          await getCaptionsFileByPusherTask(result.taskId, fileUrl)
        }

        isGeneratingCaptions.value = false;
        resolve()
      } catch (e) {
        isGeneratingCaptions.value = false;
        Sentry.captureException(e)
        reject(e)
      }
    })
  }

  const uploadFilePercentage = ref(0)

  async function extractAndUploadAudioFile(file: Blob) {
    try {
      const audioFile = await VideoToAudioService(file, 'mp3', startMs.value / 1000, endMs.value / 1000)
        .catch((e: ProgressEvent) => {
          const target = e.target as FileReader
          if (target?.error?.message) {
            throw new Error(target.error.message)
          } else {
            throw e
          }
        })

      const buff = await new Response(audioFile).arrayBuffer()
      const md5Hash = MD5.hash(new Word32Array(buff)).toString()

      const result = await uploadService.getClipAudioUploadSignedUrl(md5Hash)

      // Check if we need to upload the file. Otherwise, the file already exists
      if (result.signedUrl) {
        await uploadService.uploadFileS3(
          result.signedUrl,
          audioFile,
          (p) => (uploadFilePercentage.value = p),
          'audio/mp3'
        )
      }

      return result.resultUrl
    } catch (e) {
      Sentry.captureException(e)
      throw e
    }
  }

  async function getCaptionsFileByPusherTask(taskId: string, fileUrl: string) {
    const pusherClient = new Pusher('ef0a10b651ed4adf46eb', {
      cluster: 'us3',
    })

    const channelName = `task-status-${taskId}`
    const channel = pusherClient.subscribe(channelName)

    return new Promise<void>((resolve, reject) => {
      channel.bind('finished', async (data: { FullFilePath: string }) => {
        await emitCaptionsFileContent(data.FullFilePath)
        pusherClient.disconnect()
        resolve()
      })

      channel.bind('error', (data: unknown) => {
        console.error(data)
        pusherClient.disconnect()
        console.log(fileUrl)
        reject(new Error(`Generating captions failed: ${data}`))
      })
    })
  }

  async function emitCaptionsFileContent(url: string) {

    const result = await publicAxios.get(url)
    const document = result.data as CaptionsDocument

    // If it was a local-file, and these can be trimmed before we send them to AssemblyAI, we need to add an extra offset to all data before we return it
    const offset = shouldTrimBeforeGenerating.value ? startMs.value : 0

    editorCaptionsStore.setCaptionsDocument({

      ...document,

      sentences: document.sentences.map((sentence: Sentence) => ({
        ...sentence,
        start: sentence.start + offset,
        end: sentence.end + offset,
        words: sentence.words.map((word) => ({
          ...word,
          id: word.id ?? uuid(),
          start: word.start + offset,
          end: word.end + offset,
        }))
      })),

      words: document.words.map((word) => ({
        ...word,
        id: word.id ?? uuid(),
        start: word.start + offset,
        end: word.end + offset,
      }))
    })

    showToast({
      type: toastEvents.TOAST_SUCCESS,
      title: 'Captions Created!',
      subtitle: 'Captions have been generated and added to your project 🚀.',
      timeout: 5000,
    });
  }

  function guessLanguage() {
    // Attempt to get the language from the ClipInfo store
    const clipLanguage = editorClipInfoStore.languageCode?.toLowerCase() ?? ''
    const lang: (typeof supportedLocales)[number]['code'] = clipLanguage === 'en' ? 'en_us' : clipLanguage

    if (supportedLocales.find((l) => l.code === lang)) {
      return lang
    } else {
      // If not supported, try browser-language. Use english as fallback
      const lang = navigator.language.toLowerCase().split('-')[0]
      return supportedLocales.find((l) => l.code === lang) ? lang : editorCaptionsStore.selectedLanguage
    }
  }

  tryOnMounted(() => {
    const previouslyChosenLanguage = localStorage.getItem('caption-language');
    captionsLanguageCode.value = previouslyChosenLanguage ?? guessLanguage()
    editorCaptionsStore.selectedLanguage = previouslyChosenLanguage ?? guessLanguage()
  });
  
  return {
    captionsLanguageCode,
    supportedLocales,
    generateCaptionsAsync,
    uploadFilePercentage,
    shouldTrimBeforeGenerating,
    isOverDurationLimit,
    isGeneratingCaptions,
  }
}
