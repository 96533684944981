<script setup lang="ts">
import { useToast } from '@/Hooks/useToast'
import { canGuardWithPopup } from '@/Hooks/useGuard'
import { posthog } from 'posthog-js'
import { useFileUploads } from '@/components/Dialog/MultiUploadDialog/file-uploads/useFileUploads'
import { useEditorClipInfoStore } from '@/store/editor/editorClipInfo'
import EventBus from '@/eventBus'
import mainEvents from '@/events/mainEvents'
import * as Sentry from '@sentry/vue'
import { Button, type ButtonVariants } from '@/components/ui/button'
import IconSaxGalleryExport from '@/components/Icons/iconsax/IconSaxGalleryExport.vue'
import { useStickersStore } from '@/areas/editor/store/useStickersStore'
import { useEditorCaptionsStore } from '@/store/editor/editorCaptions'
import { useRouter } from 'vue-router'
import { onMounted, onUnmounted, ref, watch } from 'vue'
import logging from '@/logging'
import { useStreamLadderRender } from '@/areas/editor/hooks/useStreamLadderRender'
import { runWebcodecTest } from '@/webcodec-renderer/worker/webcodec-test'
import { useUserInfoStore } from '@/store/user/userInfo'
import { tiers } from '@/enums/tiers'
import { useIsMobile } from '@/Hooks/useIsMobile'
import RadialProgress from '@/components/Dialog/RadialProgress.vue'
import LottieAnimation from '@/components/LottieAnimation.vue'
import { Dialog, DialogContent } from '@/components/ui/dialog'
import type { Upload } from '@/components/Dialog/MultiUploadDialog/file-uploads/Upload'
import Spinner from '@/components/Icons/Spinner.vue'
import toastEvents from '@/events/toastEvents'
import type { CreateRenderDto, RenderDto } from '@/apis/streamladder-api/model'
import { orderBy } from 'lodash-es'
import { upgradeDialog } from '@/helpers/upgradeDialog'
import { useFeatureFlagVariantEnabled } from '@/Hooks/useFeatureFlagEnabled'
import { useEffectsStore } from '@/areas/editor/store/useEffectsStore'
import { requestUserSignInAsync } from '@/authentication/supabase'
import { dashboardRouteNames } from '@/areas/dashboard/routeNames'
import { usePreviewCanvas } from '@/areas/editor/workspaces/preview/usePreviewCanvas'
import { useRafFn } from '@vueuse/core'
import { useVideoStore } from '@/areas/editor/store/useVideoStore'
import { useGenerateCaptions } from '@/areas/editor/pages/captions/useGenerateCaptions'
import IconSaxFlash from '@/components/Icons/iconsax/IconSaxFlash.vue'
import IconSaxExport from '@/components/Icons/iconsax/IconSaxExport.vue'
import { useSegmentsStore } from '@/areas/editor/store/useSegmentsStore'
import { getApiRendersPremiumRenderCount } from '@/apis/streamladder-api/renders/renders'
import GoldPlanButton from '@/components/Account/Upgrade/GoldPlanButton.vue'
import SilverPlanButton from '@/components/Account/Upgrade/SilverPlanButton.vue'

const { getVideoRenderData, requestVideoRender, requestWebcodecRender, getAllRenders } = useStreamLadderRender()

const { dismissPremiumToasts } = useToast()
const isMobile = useIsMobile()

const router = useRouter()
const stickersStore = useStickersStore()
const clipInfoStore = useEditorClipInfoStore()
const userInfoStore = useUserInfoStore()
const effectsStore = useEffectsStore()
const captionsStore = useEditorCaptionsStore()

const segmentsStore = useSegmentsStore()
const segments = segmentsStore.whereIsNotZoom()

const premiumRenderCount = ref(0);

const clipUpload = ref<Upload | null>(null)
const progressDialogIsOpen = ref(false)

const premiumRenderDialogIsOpen = ref(false)
const watermarkOrUpgradeDialogIsOpen = ref(false)

const { showToast } = useToast();

const hasStickers = ref(false)
const hasCaptions = ref(false)
const hasEffects = ref(false)
const hasGiphyStickers = ref(false)
const hasTwitchEmotes = ref(false)
const hasPremiumOutputQuality = ref(false)
const hasZoomEffects = ref(false)
const hasSoundEffects = ref(false)
const hasMultipleSegments = ref(false)

const renderOnServer = ref(false);
const renderDataWithWatermark = ref<CreateRenderDto>();

const renderDataWithPremium = ref<CreateRenderDto>();
const canPremiumExport = useFeatureFlagVariantEnabled('max-5-premium-exports', 'premium');
const canWatermarkExport = useFeatureFlagVariantEnabled('watermark-export-for-silver', 'show-watermark');

async function render() {

  dismissPremiumToasts()

  const isLoggedIn = await requestUserSignInAsync('Please sign in to export your video');

  if (!isLoggedIn) {
    showToast({
      type: toastEvents.TOAST_ERROR,
      title: 'You need to be signed in to export 🧐',
      subtitle: 'If you are signed in, but you see this message, please refresh the page or contact support!',
      view: () => {
        router.push({
          name: dashboardRouteNames.support,
        })
      },
      viewTitle: 'Support',
    });
    return;
  }

  hasMultipleSegments.value = segments.value.length > 1;
  // if (hasMultipleSegments.value && !canGuardWithPopup('video-segmentation')) {
  //   return
  // }

  hasStickers.value = stickersStore.ids.length > 0
  // if (hasStickers.value && !canGuardWithPopup('stickers')) {
  //   return
  // }

  hasCaptions.value = captionsStore.hasCaptions
  // if (hasCaptions.value && !canGuardWithPopup('captions')) {
  //   return
  // }

  hasEffects.value = effectsStore.ids.length > 0;

  hasZoomEffects.value = effectsStore.entities.some(entities => entities.type === 'zoom');

  hasSoundEffects.value = effectsStore.entities.some(entities => entities.type === 'sound');
  if (hasSoundEffects.value && !canWatermarkExport.value && userInfoStore.tier === tiers.SILVER && !canGuardWithPopup('sounds')) {
    return
  }

  hasTwitchEmotes.value = stickersStore.entities.some((sticker) => sticker.imageUrl?.includes("static-cdn.jtvnw.net/emoticons"));
  if (hasTwitchEmotes.value && !canWatermarkExport.value && userInfoStore.tier === tiers.SILVER && !canGuardWithPopup('twitch-emotes')) {
    return
  }

  const giphyStickers = stickersStore.entities.filter((sticker) => sticker.type === 'giphy')
  hasGiphyStickers.value = giphyStickers.length > 0
  if (hasGiphyStickers.value) {

    if (!canWatermarkExport.value && userInfoStore.tier === tiers.SILVER && !canGuardWithPopup('giphy-stickers')) {
      return;
    }

    posthog.capture('Giphy Stickers: user clicked export with Giphy stickers', {
      selectedStickerUrls: giphyStickers.map(s => s.imageUrl),
    });
  }

  const fileUploads = useFileUploads()
  clipUpload.value = fileUploads.selectById(clipInfoStore.id).value

  if (clipUpload.value?.suspense) {
    progressDialogIsOpen.value = true
    console.log('Wait for file upload to finish..')
    await clipUpload.value.suspense()
    if (userInfoStore.tier === tiers.FREE) {
      progressDialogIsOpen.value = false
    }
  }

  if (clipUpload.value) {
    if (clipUpload.value.urls?.videoSignedUploadRequest?.resultUrl) {
      clipInfoStore.mp4Url = `${clipUpload.value.urls.videoSignedUploadRequest.resultUrl}?cache=${Date.now()}`;
    } else {
      console.error('Error uploading video')
    }
  }

  if (clipInfoStore.mp4Url.startsWith('blob:')) {
    console.log('At upload', JSON.stringify(clipUpload))
    Sentry.captureException(new Error('Invalid mp4 URL: ' + clipInfoStore.mp4Url))
  }

  renderOnServer.value = isMobile.value || userInfoStore.tier === tiers.GOLD;

  if (!isMobile.value && userInfoStore.tier !== tiers.GOLD) {
    const webcodecTestResults = await runWebcodecTest();
    if (webcodecTestResults.hardwareAccelerationWorking || webcodecTestResults.softwareEncoderWorking) {
      renderOnServer.value = false;
    } else {
      logging.trackEvent('WebCodec Renderer Client not supported', {
        webcodecTestResults
      })
    }
  }

  const isFreeUserWithPremiumFeaturesAdded = userInfoStore.tier === tiers.FREE && (hasStickers.value || hasCaptions.value || hasEffects.value || hasSoundEffects.value || hasMultipleSegments.value);
  const isSilverUserWithGoldFeaturesAdded = userInfoStore.tier === tiers.SILVER && (hasGiphyStickers.value || hasEffects.value || hasSoundEffects.value || hasTwitchEmotes.value);

  // We're running an AB test for max 5 exports with premium features for free users.
  // For this a flag `IsPremium` is set to true for the user which is used to count the amount of premium renders are made.
  // We can update this to false if the render fails from within the renderer.

  if (isFreeUserWithPremiumFeaturesAdded && !!canPremiumExport.value) {
    const response = await getApiRendersPremiumRenderCount();
    if (response) {
      premiumRenderCount.value = response.value as number;
    }
  }

  const isPremiumExport = isFreeUserWithPremiumFeaturesAdded
    && !!canPremiumExport.value
    && premiumRenderCount.value < 5;

  const renderData = getVideoRenderData(isPremiumExport);

  hasPremiumOutputQuality.value = renderData.outputResolution === 1080;

  console.log({
    hasTwitchEmotes: hasTwitchEmotes.value,
    hasStickers: hasStickers.value,
    hasCaptions: hasCaptions.value,
    hasEffects: hasEffects.value,
    hasGiphyStickers: hasGiphyStickers.value,
    hasPremiumOutputQuality: hasPremiumOutputQuality.value,
    hasSoundEffects: hasSoundEffects.value,
    amountOfSegments: segments.value.length,
  });

  // So two AB tests are happening here at the moment:
  // - Premium renders for Free users, we don't show the watermark popup if the user is in premium trial mode.
  // - Watermark export for Silver users
  if ((isFreeUserWithPremiumFeaturesAdded && !isPremiumExport) || (canWatermarkExport.value && isSilverUserWithGoldFeaturesAdded)) {

    watermarkOrUpgradeDialogIsOpen.value = true;

    logging.trackEvent('Watermark Dialog Opened', {
      source: clipInfoStore.source,
      isLocalFile: clipInfoStore.isLocalFile,
      hasTwitchEmotes: hasTwitchEmotes.value,
      hasStickers: hasStickers.value,
      hasCaptions: hasCaptions.value,
      hasEffects: hasEffects.value,
      hasGiphyStickers: hasGiphyStickers.value,
      hasPremiumOutputQuality: hasPremiumOutputQuality.value,
      hasSoundEffects: hasSoundEffects.value,
      amountOfSegments: segments.value.length,
    });

    renderDataWithWatermark.value = {
      ...renderData,
      overlay: {
        ...renderData.overlay,
        watermark: true
      }
    };

    return;
  }

  if (isPremiumExport) {
    premiumRenderDialogIsOpen.value = true;
    renderDataWithPremium.value = renderData;
    return;
  }

  await submitRender(renderOnServer.value, renderData);
}

const submitRender = async (renderOnServer, renderData) => {

  const renderId = await requestWebcodecRender(renderOnServer, renderData);

  logging.trackEvent('Clip Created', {
    source: clipInfoStore.source,
    isLocalFile: clipInfoStore.isLocalFile,
    hasStickers: hasStickers.value,
    hasCaptions: hasCaptions.value,
    hasEffects: hasEffects.value,
    hasGiphyStickers: hasGiphyStickers.value,
    hasPremiumOutputQuality: hasPremiumOutputQuality.value,
    hasSoundEffects: hasSoundEffects.value,
    amountOfSegments: segments.value.length,
    isPremiumExport: hasStickers.value || hasCaptions.value || hasEffects.value || hasSoundEffects.value || hasMultipleSegments.value || hasGiphyStickers.value,
    buttonPosition: props.buttonPosition,
  });

  if (renderId) {
    if (renderOnServer) {
      await router.push({
        name: 'ServerSideRenderStatusPage',
        query: {
          task: renderId,
        },
      });
    } else {
      await router.push({
        name: 'ClientSideRenderPage',
        query: {
          renderId: renderId,
        },
      });
    }
  } else {
    const result = await requestVideoRender()

    if (!result) {
      EventBus.$emit(mainEvents.ERROR, `Something went wrong with rendering.<br/><br/>Please open a Ticket on Discord.`)
    } else if (result.type === 'error' && result.message === 'Video is already rendering') {
      showToast({
        type: toastEvents.TOAST_ERROR,
        title: "There's already a video rendering with this clip",
        subtitle: 'Please wait until the other render is finished 🙊',
        timeout: 10000,
      });
    } else if (result.type === 'error' && result.message !== 'User is not authenticated') {
      EventBus.$emit(mainEvents.ERROR, `Something went wrong with rendering <strong>${result.message}</strong>.${'taskId' in result && result.taskId ? 'Please open a Ticket on Discord with TaskId:<br/> ' + result.taskId : ''}`)
    } else if (result.type === 'server' && 'task' in result && result.task) {
      await router.push({
        name: 'ServerSideRenderStatusPage',
        query: {
          task: result.task.id,
        },
      })
    }
  }
}

const captions = useGenerateCaptions();

const isDisabled = ref(false);
const isRendering = ref(false);

async function startRender() {
  isRendering.value = true
  await render()
  isRendering.value = false
}

function countsAsSameRender(a: RenderDto, b: RenderDto) {
  return a.source === b.source 
    && a.title === b.title 
    && Math.abs(new Date(a.createdAt!).getTime() - new Date(b.createdAt!).getTime()) < 2 * 60 * 1000;
}

async function fetchUniqueRenders() {

  const renders = orderBy(await getAllRenders(), r => new Date(r.updatedAt!), 'desc')
  const uniqueRenders = [] as RenderDto[]
  
  for (const render of renders) {
    if (!uniqueRenders.some(r => countsAsSameRender(r, render))) {
      uniqueRenders.push(render)
    }
  }
  
  return uniqueRenders
}

function taskIsActive(render: RenderDto) {
  const timeSinceCreationMs = new Date().getTime() - new Date(render.createdAt!).getTime()
  return render.status === 'rendering' && timeSinceCreationMs <= 10 * 60 * 1000;
}

async function canUserRenderAsync() {

  const renders = (await fetchUniqueRenders()).filter(taskIsActive)
  
  if (userInfoStore.tier === tiers.GOLD) {
    return renders.length < 5;
  } else {
    return renders.length < 2;
  }
}

onMounted(async () => {

  if (!userInfoStore.isLoggedIn) {
    return;
  }

  isDisabled.value = true

  const canRender = await canUserRenderAsync()
  if (canRender) {
    isDisabled.value = false;
  } else {
    startPollingForActiveRenders()
  }
});

const interval = ref();
const startPollingForActiveRenders = () => {
  interval.value = setInterval(async () => {
    const canRender = await canUserRenderAsync()
    isDisabled.value = !canRender
  }, 5000);
};

onUnmounted(() => {
  clearInterval(interval.value);
});

const props = withDefaults(
  defineProps<{
    buttonPosition?: 'ExportPage' | 'BelowTimeline'
    variant?: ButtonVariants['variant']
    size?: ButtonVariants['size']
  }>(),
  {
    variant: 'primary',
    size: 'lg',
  }
);

const upgradeNow = () => {
  watermarkOrUpgradeDialogIsOpen.value = false;
  upgradeDialog.open('export-without-watermark');
};

const renderWithWatermark = () => {
  watermarkOrUpgradeDialogIsOpen.value = false;
  submitRender(renderOnServer.value, renderDataWithWatermark.value);
};

const upgradeNowPremium = () => {
  premiumRenderDialogIsOpen.value = false;
  upgradeDialog.open('export-without-watermark');
};

const renderWithPremium = () => {
  premiumRenderDialogIsOpen.value = false;
  submitRender(renderOnServer.value, renderDataWithPremium.value, true);
};

const videoPreview = ref<HTMLCanvasElement | null>(null)
const { canvas: previewCanvas, renderer } = usePreviewCanvas()

const videoStore = useVideoStore()

watch(watermarkOrUpgradeDialogIsOpen,() => {
  if (watermarkOrUpgradeDialogIsOpen.value) {
    videoStore.playing = true;
    videoStore.preservedPaused = false;
    const worker = renderer.value?.worker?.worker;
    if (worker) {
      worker.postMessage({
        type: 'update-options',
        options: {
          renderWatermark: true
        }
      });
    }
  } else {
    const worker = renderer.value?.worker?.worker;
    if (worker) {
      worker.postMessage({
        type: 'update-options',
        options: {
          renderWatermark: false
        }
      });
    }
  }
}, { immediate: true });

useRafFn(() => {
  if (videoPreview.value && previewCanvas.value) {
    videoPreview.value.width = previewCanvas.value.width
    videoPreview.value.height = previewCanvas.value.height
    const ctx = videoPreview.value.getContext('2d')
    if (ctx) {
      ctx.drawImage(previewCanvas.value, 0, 0)
    }
  }
})
</script>

<template>
  <Button :variant="variant" :size="size" @click="startRender" :disabled="isDisabled || isRendering || captions.isGeneratingCaptions.value">
    <template v-if="captions.isGeneratingCaptions.value">
      Waiting for captions..
      <Spinner class="h-4 w-4 animate-spin mr-2.5" />
    </template>
    <template v-else-if="!isRendering && !isDisabled">
      <slot>
        <IconSaxGalleryExport class="w-5 h-5" />
        Export
      </slot>
    </template>
    <template v-else-if="isDisabled">
      Export
      <Spinner class="h-4 w-4 animate-spin mr-2.5" />
    </template>
    <template v-else>
      <div class="w-5 h-5 border-2 border-current border-t-transparent rounded-full animate-spin" />
      Starting export...
    </template>
  </Button>

  <Dialog v-model:open="progressDialogIsOpen" v-if="clipUpload">
    <DialogContent class="flex flex-col items-center gap-4 py-4">
      <template v-if="clipUpload.status === 'in-progress'">
        <LottieAnimation url="/lottie/rocketLaunch.json" class="w-20" />
        <h2 class="text-center text-2xl font-bold dark:text-white">Hold tight! ✨</h2>
        <p class="text-center text-lg font-normal text-gray-600 dark:text-white">
          We're zipping your video over to our ultra-fast rendering buddy.
        </p>
        <radial-progress :progress="clipUpload.progress" />
      </template>
      <template v-else-if="clipUpload.status === 'finished'">
        <LottieAnimation url="/lottie/rocketLaunch.json" class="w-20" />
        <h2 class="text-center text-2xl font-bold dark:text-white">Success! 🚀</h2>
        <p class="text-center text-lg font-normal text-gray-600 dark:text-white">
          Your video is ready to be rendered. 🚀
        </p>
      </template>
      <template v-else-if="clipUpload.status === 'error'">
        <LottieAnimation url="/lottie/error.json" class="w-20" />
        <h2 class="text-center text-2xl font-bold dark:text-white">Oops!</h2>
        <p class="text-center text-lg font-normal text-gray-600 dark:text-white">An error occurred while uploading your video</p>
      </template>
      <template v-else-if="clipUpload.status === 'invalid'">
        <LottieAnimation url="/lottie/error.json" class="w-20" />
        <h2 class="text-center text-2xl font-bold dark:text-white">Oops!</h2>
        <span v-if="clipUpload.validationResult?.error" class="flex flex-col">
          <span class="text-center text-lg font-normal text-gray-600 dark:text-white">{{ clipUpload.validationResult.error.title }}</span>
          <span class="text-center text-sm font-light text-gray-600 dark:text-white opacity-75">{{ clipUpload.validationResult.error.message }}</span>
        </span>

        <template v-else-if="clipUpload.validationResult?.guard">
          <span class="text-center text-lg font-normal text-gray-600 dark:text-white">
            You need to upgrade to be able export this video.
          </span>
          
          <Button @click="upgradeDialog.open(clipUpload.validationResult?.guard)">
            Upgrade
          </Button>
        </template>
      </template>
      <template v-else-if="clipUpload.status === 'pending'">
        <LottieAnimation url="/lottie/rocketLaunch.json" class="w-20" />
        <h2 class="text-center text-2xl font-bold dark:text-white">Hold tight! ✨</h2>
        <p class="text-center text-lg font-normal text-gray-600 dark:text-white">
          We're zipping your video over to our ultra-fast rendering buddy.
        </p>
      </template>
    </DialogContent>
  </Dialog>

  <Dialog v-model:open="premiumRenderDialogIsOpen">
    <DialogContent class="flex flex-col items-center lg:gap-2 lg:px-6 lg:py-12 max-w-3xl">

      <LottieAnimation :loop="false" url="/lottie/present.json" class="w-[100px] pb-2 md:w-[300px] md:pb-3" />

      <h2 class="text-center text-3xl font-bold dark:text-white">
        You have <span class="text-company-primary-200 font-extrabold">{{ 5 - premiumRenderCount }}</span> Free Premium Exports left!
      </h2>

      <div class="flex flex-col gap-2">
        <p class="text-center text-sm lg:text-base font-light text-gray-600 dark:text-white">
          Try all Awesome Features free until your Free Premium Exports run out.
        </p>
      </div>

      <div class="flex flex-col gap-2 mt-4">
        <Button @click="upgradeNowPremium" size="lg" class="text-black bg-yellow-300 hover:bg-yellow-200">
          <IconSaxFlash class="h-5 w-5" />
          Upgrade Now to Premium
        </Button>

        <Button @click="renderWithPremium" variant="primary" size="lg">
          <IconSaxExport class="h-5 w-5" />
          Export Clip
        </Button>
      </div>

      <div class="flex flex-col gap-4 mt-8 items-center max-w-full border rounded-lg p-4 bg-slate-100 dark:bg-surface-panel">
        <p class="font-semibold">Premium Features Used</p>
        <div class="flex flex-wrap items-center justify-center w-full gap-2 font-light text-sm">
          <div v-if="hasStickers" class="bg-white dark:bg-surface-panel flex gap-2 border rounded px-2 py-1 cursor-default text-nowrap">
            Texts or Social Stickers
            <SilverPlanButton class="pointer-events-none h-4 w-4 mt-0.5" small />
          </div>
          <div v-if="hasCaptions" class="bg-white dark:bg-surface-panel flex gap-2 border rounded px-2 py-1 cursor-default text-nowrap">
            AI-Powered Captions
            <SilverPlanButton class="pointer-events-none h-4 w-4 mt-0.5" small />
          </div>
          <div v-if="hasMultipleSegments" class="bg-white dark:bg-surface-panel flex gap-2 border rounded px-2 py-1 cursor-default text-nowrap">
            Multiple Segments
            <SilverPlanButton class="pointer-events-none h-4 w-4 mt-0.5" small />
          </div>
          <div v-if="hasZoomEffects" class="bg-white dark:bg-surface-panel flex gap-2 border rounded px-2 py-1 cursor-default text-nowrap">
            Zoom Effects
            <GoldPlanButton class="pointer-events-none h-4 w-4 mt-0.5" small />
          </div>
          <div v-if="hasSoundEffects" class="bg-white dark:bg-surface-panel flex gap-2 border rounded px-2 py-1 cursor-default text-nowrap">
            Sound Effects
            <GoldPlanButton class="pointer-events-none h-4 w-4 mt-0.5" small />
          </div>
          <div v-if="hasGiphyStickers" class="bg-white dark:bg-surface-panel flex gap-2 border rounded px-2 py-1 cursor-default text-nowrap">
            Animated Giphy Stickers
            <GoldPlanButton class="pointer-events-none h-4 w-4 mt-0.5" small />
          </div>
        </div>
      </div>

    </DialogContent>
  </Dialog>

  <Dialog v-model:open="watermarkOrUpgradeDialogIsOpen">
    <DialogContent class="flex flex-col items-center lg:gap-6 lg:px-6 lg:py-12 max-w-3xl">

      <div class="flex flex-col lg:gap-2">
        <template v-if="userInfoStore.tier === tiers.SILVER">
          <h2 class="hidden lg:block text-center text-3xl font-bold dark:text-white">⚡️ You've added Gold Features ⚡️</h2>
          <h2 class="lg:hidden text-center text-lg font-bold dark:text-white">You've added Gold Features</h2>
          <p class="text-center text-sm lg:text-base font-light text-gray-600 dark:text-white">
            You can export this video with watermark or upgrade to Gold to export without watermark.
          </p>
        </template>
        <template v-else>
          <h2 class="hidden lg:block text-center text-3xl font-bold dark:text-white">⚡️ You've added Premium Features ⚡️</h2>
          <h2 class="lg:hidden text-center text-lg font-bold dark:text-white">You've added Premium Features</h2>
          <p class="text-center text-sm lg:text-base font-light text-gray-600 dark:text-white">
            You can export this video for free with watermark or upgrade to export without watermark.
          </p>
        </template>
      </div>

      <div ref="container" class="h-0 relative min-h-[300px] lg:min-h-[400px] aspect-[9/16] border-4 border-black rounded-2xl bg-black">
        <canvas ref="videoPreview" class="col-start-1 row-start-1 h-full w-full rounded-lg overflow-hidden pointer-events-none" />
      </div>

      <div class="flex flex-col gap-2 mt-2">
        <Button @click="upgradeNow" size="lg" class="text-black bg-yellow-300 hover:bg-yellow-200">
          <IconSaxFlash class="h-5 w-5" />
          Remove Watermark
        </Button>

        <Button @click="renderWithWatermark" variant="primary" size="lg">
          <IconSaxExport class="h-5 w-5" />
          Export Clip
        </Button>
      </div>

      <div class="flex flex-col gap-4 items-center max-w-full border rounded-lg p-4 bg-slate-100 dark:bg-surface-panel">
        <p class="font-semibold">Premium Features Used</p>
        <div class="flex flex-wrap items-center justify-center w-full gap-2 font-light text-sm">
          <div v-if="hasStickers" class="bg-white dark:bg-surface-panel flex gap-2 border rounded px-2 py-1 cursor-default text-nowrap">
            Texts or Social Stickers
            <SilverPlanButton class="pointer-events-none h-4 w-4 mt-0.5" small />
          </div>
          <div v-if="hasCaptions" class="bg-white dark:bg-surface-panel flex gap-2 border rounded px-2 py-1 cursor-default text-nowrap">
            AI-Powered Captions
            <SilverPlanButton class="pointer-events-none h-4 w-4 mt-0.5" small />
          </div>
          <div v-if="hasMultipleSegments" class="bg-white dark:bg-surface-panel flex gap-2 border rounded px-2 py-1 cursor-default text-nowrap">
            Multiple Segments
            <SilverPlanButton class="pointer-events-none h-4 w-4 mt-0.5" small />
          </div>
          <div v-if="hasZoomEffects" class="bg-white dark:bg-surface-panel flex gap-2 border rounded px-2 py-1 cursor-default text-nowrap">
            Zoom Effects
            <GoldPlanButton class="pointer-events-none h-4 w-4 mt-0.5" small />
          </div>
          <div v-if="hasSoundEffects" class="bg-white dark:bg-surface-panel flex gap-2 border rounded px-2 py-1 cursor-default text-nowrap">
            Sound Effects
            <GoldPlanButton class="pointer-events-none h-4 w-4 mt-0.5" small />
          </div>
          <div v-if="hasGiphyStickers" class="bg-white dark:bg-surface-panel flex gap-2 border rounded px-2 py-1 cursor-default text-nowrap">
            Animated Giphy Stickers
            <GoldPlanButton class="pointer-events-none h-4 w-4 mt-0.5" small />
          </div>
        </div>
      </div>
    </DialogContent>
  </Dialog>
</template>

<style scoped lang="scss">

</style>
