<script lang="ts" setup>
import templateService from '@/services/templateService.js'
import ErrorIcon from '@/components/Dialog/Icons/ErrorIcon.vue'
import { useEditorMainStore } from '@/store/editor/editorMain'
import { Button } from '@/components/ui/button'
import { reactive, ref, onMounted, computed } from 'vue'
import { Switch } from '@/components/ui/switch'
import { posthog } from 'posthog-js'
import Spinner from '@/components/Icons/Spinner.vue'
import { isGiphySticker, useStickersStore } from '@/areas/editor/store/useStickersStore'
import { DialogClose, DialogTitle, DialogDescription } from '@/components/ui/dialog'
import { Input } from '@/components/ui/input'
import LayoutPreview from '@/areas/editor/components/LayoutPreview.vue'
import { useSegmentsStore } from '@/areas/editor/store/useSegmentsStore'
import { useCropsStore } from '@/areas/editor/store/useCropsStore'
import { type LayoutPreset, usePresets } from '@/areas/editor/@data/layouts'
import { isTextSticker } from '@/areas/editor/store/useStickersStore'
import { v4 as uuid } from 'uuid'
import { useLayoutsStore } from '@/areas/editor/store/useLayoutsStore'
import { copyRef } from '@/store/entity-system/_copyRef'
import type { Sticker } from '@/areas/editor/@type/Project'
import toastEvents from '@/events/toastEvents'
import { useToast } from '@/Hooks/useToast'
import * as Sentry from '@sentry/vue'
import { canGuardWithPopup } from '@/Hooks/useGuard'
import IconSaxWarning2 from '@/components/Icons/iconsax/IconSaxWarning2.vue'
import { useEffectsStore } from '@/areas/editor/store/useEffectsStore'

const askUpdateExistingTemplate = ref<boolean>()
const templateName = ref<string>('')
const showForm = ref<boolean>(true)
const loading = ref<boolean>(false)
const hasError = ref<boolean>(false)
const validationMessage = ref<string>('')

const editorMainStore = useEditorMainStore()

const storedFeatures = reactive({
  text: false,
  layout: true,
  stickers: true,
  gifs: true,
  captions: true,
  sounds: true,
})

const emit = defineEmits<{ (event: 'close'): void }>()

onMounted(() => {
  askUpdateExistingTemplate.value = preset.value?.origin === 'saved-template'
  templateName.value = preset.value?.name ?? ''
  showForm.value = true
  hasError.value = false
  loading.value = false
})

const createNew = () => {
  askUpdateExistingTemplate.value = false
}

const submit = async () => {

  const canSaveTemplates = canGuardWithPopup('save-template');

  if (canSaveTemplates) {
    const templateNameTrimmed = templateName.value.trim()
    if (templateNameTrimmed.length < 4 || templateNameTrimmed.length > 24) {
      validationMessage.value = 'Template name must be between 4 and 24 characters'
      return
    } else {
      await saveTemplate()
    }
  }
}

const { showToast } = useToast()

const saveTemplate = async (updateTemplate = false) => {

  loading.value = true

  const stickers = stickersStore.selectStickersForStorage(storedFeatures);
  const sounds = storedFeatures.sounds ? effectsStore.entities.filter(e => e.type === 'sound') : [];

  const template = copyRef({
    id: updateTemplate ? presetId.value : null,
    templateName: templateName.value.trim(),
    layoutName: layoutsStore.selectById(layoutId.value).name,
    croppers: cropsStore.selectTemplateCropDataByLayoutId(layoutId),
    feeds: cropsStore.selectTemplateFeedDataByLayoutId(layoutId),
    stickers: stickers,
    sounds: sounds
  })

  if (!template?.croppers || !template?.feeds || template?.croppers?.length === 0) {
    hasError.value = true
    loading.value = false
    emit('close')
    Sentry.captureException(new Error('Template error: No croppers or feeds'))
    showToast({
      type: toastEvents.TOAST_ERROR,
      title: 'Template not saved',
      subtitle: 'Something went wrong saving your template 🤕',
    })
    return
  }

  try {

    const result = await templateService.saveTemplate(template, updateTemplate)
    editorMainStore.templateId = result.id

    posthog.capture('Saved Template', {
      template: template,
      hasUpdatedTemplate: updateTemplate,
    })

    showToast({
      type: toastEvents.TOAST_SUCCESS,
      title: 'Template Saved',
      subtitle: 'Your template has been successfully saved.',
    })

    askUpdateExistingTemplate.value = undefined
    showForm.value = false
    loading.value = false
    emit('close')
  } catch (e) {
    hasError.value = true
    console.error(e)
    loading.value = false
  }
}

const segmentsStore = useSegmentsStore()
const segments = segmentsStore.whereIsNotZoom()
const layoutId = computed(() => segments.value[0].layoutId)

const { layouts } = usePresets()
const layoutsStore = useLayoutsStore()

const layout = computed(() =>layoutsStore.selectById(layoutId.value))
const presetId = computed(() => layout.value?.presetId)
const preset = computed(() => layouts.value.find(layout => layout.id === presetId.value))

const cropsStore = useCropsStore()
const stickersStore = useStickersStore()
const effectsStore = useEffectsStore()

const filterByFeatures = (s: Sticker) => {
  if (isGiphySticker(s)) return storedFeatures.gifs
  if (isTextSticker(s)) return storedFeatures.text
  return storedFeatures.stickers
}

const presetPreview = computed<LayoutPreset>(() => ({
  id: uuid(),
  name: templateName.value,
  presetId: null,
  origin: 'saved-template',
  crops: cropsStore.orderByZ(layoutId.value).value,
  stickers: stickersStore.entities
    .filter(filterByFeatures)
    .map(s => ({
      ...s,
      scale: (s.area.width * 1080 / s.naturalWidth) / 1080,
    })),
  sounds: [],
}))
</script>

<template>
  <form class="flex flex-col justify-between gap-6 flex-1" @submit.prevent="submit">
    <template v-if="showForm">
      <header v-if="askUpdateExistingTemplate">
        <DialogTitle as="h2" class="text-xl font-semibold leading-snug">Update template</DialogTitle>
        <DialogDescription class="font-light">Update this template to easily apply it to your next clip.</DialogDescription>
      </header>
      <header v-else>
        <DialogTitle as="h2" class="text-xl font-semibold leading-snug">Save template</DialogTitle>
        <DialogDescription class="font-light">Save this template to easily apply it to your next clip.</DialogDescription>
      </header>

      <div class="flex flex-col gap-1">
        <h3 class="text-lg font-semibold leading-snug">Template name</h3>
        <Input v-model="templateName" :maxlength="10" type="text" />
        <p v-if="validationMessage" class="validation-message">
          {{ validationMessage }}
        </p>
      </div>
      <div class="flex flex-col gap-2">
        <h3 class="text-lg font-semibold leading-snug">Save template with</h3>
        <div class="flex gap-2">
          <Switch id="layout-template" v-model:checked="storedFeatures.layout" disabled />
          <label class="" for="layout-template">Layout</label>
        </div>
        <div class="flex gap-2">
          <Switch id="text-template" v-model:checked="storedFeatures.text" />
          <label class="" for="text-template">Text</label>
        </div>
        <div class="flex gap-2">
          <Switch id="gifs-template" v-model:checked="storedFeatures.gifs" />
          <label class="" for="gifs-template">Gifs</label>
        </div>
        <div class="flex gap-2">
          <Switch id="stickers-template" v-model:checked="storedFeatures.stickers" />
          <label class="" for="stickers-template">Stickers</label>
        </div>
        <div class="flex gap-2">
          <Switch id="sounds-template" v-model:checked="storedFeatures.sounds" />
          <label for="sounds-template" class="">Sound Effects</label>
        </div>
      </div>

      <div class="pt-2 mt-auto flex flex-col gap-2">
        <template v-if="hasError">
          <div class="rounded-lg border-red-600 bg-red-200 text-red-600 px-3 py-2 flex items-center gap-2">
            <IconSaxWarning2 />
            <p class="text-sm">Could not save template. Please try again.</p>
          </div>
        </template>
  
        <div class="flex justify-between gap-2" v-if="askUpdateExistingTemplate">
          <Button :disabled="loading" type="button" variant="outline" @click="createNew()">
            New
          </Button>
          <Button :disabled="loading" type="button" variant="primary" @click="saveTemplate(true)">
            <Spinner v-if="loading" class="h-3 w-3 animate-spin" />
            Update
          </Button>
        </div>
  
        <div class="flex justify-between gap-2" v-else>
          <DialogClose :disabled="loading">
            <Button :disabled="loading" type="button" variant="depressed">
              Close
            </Button>
          </DialogClose>
          <Button :disabled="loading" type="submit" variant="primary">
            <Spinner v-if="loading" class="h-3 w-3 animate-spin" />
            Save
          </Button>
        </div>
      </div>
    </template>
  </form>
  <div
    v-if="showForm"
    class="overflow-hidden w-[270px] h-[480px] content-box border-[6px] border-black rounded-xl hide md:block"
  >
    <div class="relative w-full h-full">
      <LayoutPreview :preset="presetPreview" />
    </div>
  </div>
</template>

<style lang="scss" scoped>

</style>
