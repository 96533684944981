import { useEditorClipInfoStore } from '@/store/editor/editorClipInfo'
import { getFileFromStorage } from '@/components/Dialog/MultiUploadDialog/file-uploads/_storage'
import { useFileUploads } from '@/components/Dialog/MultiUploadDialog/file-uploads/useFileUploads'
import { onUserInfoReadyAsync, onLoggedIn } from '@/store/user/userInfo'
import { getUploadedVideosAsync } from '@/components/Dialog/MultiUploadDialog/file-uploads/useUploadedVideos'
import { prepareStores } from '@/areas/editor/startup/generalStartupMethods'
import type { StartupConfig } from '@/areas/editor/startup/StartupConfig'
import { handleStartupError } from '@/areas/editor/startup/handleStartupError'
import { throwIfBrowserUnsupported } from '@/areas/editor/startup/UnsupportedBrowserError'
import { metadataService } from '@/services/metadataService'

export async function startupFromLocalFileByClipId(clipId: string, config: StartupConfig) {
  
  try {
    throwIfBrowserUnsupported()

    const editorClipInfoStore = useEditorClipInfoStore()
    editorClipInfoStore.isLoadingClip = true
    editorClipInfoStore.id = clipId

    editorClipInfoStore.loadingState = {
      state: 'loading',
      description: 'Finding video file...'
    }

    const upload = await fetchPreviousUpload(clipId, config)
    config.signal.value?.throwIfAborted()
    if (upload) {
      return await startupFromLocalFile({
        id: upload.id,
        title: upload.title,
        mp4Url: upload.mp4Url,
      }, config)
    }

    const localFile = await getFileFromStorage(clipId)
    config.signal.value?.throwIfAborted()
    if (localFile) {

      const fileUploads = useFileUploads()
      config.signal.value?.throwIfAborted()

      fileUploads.resume(clipId)
      const mp4Url = URL.createObjectURL(localFile)
      const title = localFile.name.split('.').slice(0, -1).join('.').trim() || 'New Clip'
      return await startupFromLocalFile({ id: clipId, title: title, mp4Url: mp4Url }, config)
    } 
    
    editorClipInfoStore.loadingState = {
      state: 'error',
      title: 'Video missing 🧐',
      description: 'The video could not be found in your browser or in cloud storage. Please re-upload to continue.'
    }

    return { error: 'The video could not be found in your browser or in cloud storage. Please re-upload to continue.' }
  } catch (e) {
    return handleStartupError(e)
  }
}

export async function startupFromLocalFile(clip: { id: string, title: string, mp4Url: string }, config: StartupConfig) {

  const editorClipInfoStore = useEditorClipInfoStore()
  editorClipInfoStore.id = clip.id
  editorClipInfoStore.title = clip.title
  editorClipInfoStore.mp4Url = clip.mp4Url
  editorClipInfoStore.source = 'local-file'
  editorClipInfoStore.languageCode = 'en_us'
  editorClipInfoStore.isLocalFile = true

  return await prepareStores(config)
}

async function fetchPreviousUpload(clipId: string, config: StartupConfig) {
  
  const userInfo = await onUserInfoReadyAsync()
  if (!userInfo.isAuthenticated) return null
  config.signal.value?.throwIfAborted()

  const uploadedVideos = await getUploadedVideosAsync()
  config.signal.value?.throwIfAborted()

  const previousUpload = uploadedVideos.find((upload) => upload.id === clipId)
  if (!previousUpload) return null
  
  const mp4Url = `${previousUpload.videoUrl}?cache=${Date.now()}`
  if (!await metadataService.canPlayUrl(mp4Url)) return null

  return {
    id: previousUpload.id,
    title: previousUpload.name,
    mp4Url: mp4Url,
    slug: previousUpload.name,
    isLocalFile: true,
    source: 'local-file',
  }
}
