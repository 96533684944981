import { defineStore } from 'pinia'
import type { Effect } from '@/areas/editor/@type/Project'
import { useEntityStore } from '@/areas/editor/store/useEntityStore'
import { computed } from 'vue'
import { useHistoryStore } from '@/areas/editor/store/useHistoryStore'

export const useEffectsStore = defineStore('effects', () => {

  const historyStore = useHistoryStore()
  const { state, ids, entities, operations } = useEntityStore<Effect>()
  
  return {
    state,
    ids,
    entities,
    ...operations,
    
    idsWhereTypeIs(type: Effect['type']) {
      return operations.idsWhere(e => e.type === type)
    },

    selectBySegmentId(segmentId: string) {
      return computed(() => entities.value.find(e => e.segmentId === segmentId))
    },

    updateVolumeById(id: string, volume: number) {
      const effect = state[id];
      if (effect.type === 'sound') {
        effect.volume = volume;
        historyStore.push();
      }
    },

    updateTimingsById(id: string, startMs: number, endMs: number) {
      const effect = state[id];
      if (effect.type === 'sound') {
        effect.startMs = startMs;
        effect.endMs = endMs;
        historyStore.push();
      }
    },
  }
})
